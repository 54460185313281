import { ClientStorage } from 'ClientStorage';
import { LOCAL_STORAGE } from 'Constants';
import { SHOPIFY_LOGIN_URL } from 'config';
import { useAppQuery } from './useAppQuery';
const ottURL = `${SHOPIFY_LOGIN_URL}api/continue-with-rush`;

function useOtt({ enabled }) {
  const sessionToken = ClientStorage.get(LOCAL_STORAGE.RUSH_SESSION_TOKEN);
  const shop = ClientStorage.get(LOCAL_STORAGE.RUSH_SHOP);

  const response = useAppQuery({
    url: `${ottURL}/?shop=${shop}&session_token=${sessionToken}`,
    reactQueryOptions: {
      refetchOnReconnect: false,
      enabled,
    },
  });

  return response;
}

export default useOtt;
