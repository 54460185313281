import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './styles.scss';
import {
  Card,
  Layout,
  Loading,
  Page,
  SkeletonBodyText,
  SkeletonPage,
  TextField,
} from '@shopify/polaris';
import { baseActions } from 'redux/store/baseSlice';
import {
  useGetOS2CodeQuery,
  useGetOS2PagesQuery,
  useSaveOS2CodeMutation,
} from '../newTrackingPageApis';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { STORE_URLS } from 'config/urls';
import RushFooter from 'components/custom/RushFooter';
import TranslateLink from 'components/TranslateLink';

function OS2EditCode() {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const [t] = useTranslation();
  const { uuid, pageUuid } = params;

  const [code, setCode] = useState();
  const [saveCode, { isLoading: isSavingCode, isSuccess: isCodeSaved }] =
    useSaveOS2CodeMutation();
  const os2PagesRes = useGetOS2PagesQuery();
  const { data: os2PagesList = [], isFetching: isOS2PagesLoading } =
    os2PagesRes;

  const {
    data: codeResponse = {},
    isFetching: isLoadingCode,
    isSuccess: isCodeLoaded,
  } = useGetOS2CodeQuery(pageUuid);

  useEffect(() => {
    if (isCodeLoaded && !isLoadingCode) {
      const value = codeResponse.data?.value;
      const formatedValue = JSON.parse(JSON.stringify(value || '', null, 2));
      setCode(formatedValue);
    }
  }, [codeResponse, isLoadingCode, isCodeLoaded]);

  useEffect(() => {
    if (!isSavingCode && isCodeSaved) {
      dispatch(
        baseActions.setToastMessage({
          message: 'Code saved successfully',
          type: 'success',
        })
      );
    }
  }, [isCodeSaved, isSavingCode]);

  const pageInfo = useMemo(() => {
    return os2PagesList.find((page) => page.uuid === pageUuid);
  }, [os2PagesList, pageUuid]);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(code);
    dispatch(
      baseActions.setToastMessage({
        message: 'Copied to clipboard',
        type: 'success',
      })
    );
  };

  function isValidJSON(str) {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
  }

  const handleSave = () => {
    if (isValidJSON(code)) {
      saveCode({ pageUuid, data: { value: code } });
    } else {
      dispatch(
        baseActions.setToastMessage({
          message: 'Code JSON is not valid to save!',
          type: 'error',
        })
      );
    }
  };
  const handleView = () => {
    window.open(pageInfo.link);
  };
  const handleEditInEditor = () => {
    window.open(pageInfo.editor_url);
  };

  const dumpCard = () => {
    return (
      <Card>
        <TextField
          value={code}
          onChange={(value) => setCode(value)}
          multiline
        />
      </Card>
    );
  };
  return (
    <>
      {isSavingCode && <Loading />}
      {isOS2PagesLoading || isLoadingCode ? (
        <SkeletonPage primaryAction secondaryActions>
          <Card roundedAbove="sm">
            <SkeletonBodyText lines={10} />
          </Card>
        </SkeletonPage>
      ) : (
        <Page
          uuid={uuid}
          backAction={{
            onAction: () =>
              navigate(`/s/${params.uuid}${STORE_URLS.TRACKING_PAGES}`),
          }}
          title={`${pageInfo.title} edit code`}
          secondaryActions={[
            {
              content: t('common.save'),
              loading: isSavingCode,
              plain: true,
              onAction: handleSave,
            },
            {
              content: t('common.copy'),
              plain: true,
              onAction: copyToClipboard,
            },
            { content: t('View'), plain: true, onAction: handleView },
            {
              content: t('Edit in theme editor'),
              plain: true,
              onAction: handleEditInEditor,
            },
            {
              content: t('Get support'),
              plain: true,
              url: 'https://support.rush.app',
              external: true,
            },
          ]}
        >
          {dumpCard()}
          <Layout.Section>
            <RushFooter>
              <TranslateLink text={t('tracking_page_new.footer')} />
            </RushFooter>
          </Layout.Section>
        </Page>
      )}
    </>
  );
}

export default OS2EditCode;
