import React, { useState } from 'react';
import {
  Card,
  Checkbox,
  Button,
  Text,
  Icon,
  ButtonGroup,
  VerticalStack,
  Box,
  HorizontalStack,
} from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import {
  DeleteMinor,
  EditMinor,
  ArrowDownMinor,
  ArrowUpMinor,
} from '@shopify/polaris-icons';
import TopMenuLinkModal from './TopMenuLinkModal';
import './styles.scss';

function RelatedLinksCard(props) {
  const {
    top_menu_links: links = [],
    onChange,
    metaLinks = [],
    is_top_menu_links,
  } = props;
  const [t] = useTranslation();

  const title = t('tracking_page_new.related_links.card_title');
  const description = t('tracking_page_new.related_links.card_description');
  const enableLinksLabel = t(
    'tracking_page_new.related_links.enable_links_label'
  );
  const [currentLinkIndex, setCurrentLinkIndex] = useState(null);

  const [showModal, setShowModal] = useState(false);

  const handleCheckboxChange = () => {
    onChange('is_top_menu_links', !is_top_menu_links);
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const handleAddLinkClick = () => {
    setCurrentLinkIndex(null);
    toggleModal();
  };

  const reorderArray = (arr, old_index, new_index) => {
    if (new_index >= arr.length || new_index < 0) {
      return arr;
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);

    return arr;
  };
  const handleMoveUp = (index) => {
    let updated = reorderArray([...links], index, index - 1);
    syncUpdates(updated);
  };

  const handleMoveDown = (index) => {
    let updated = reorderArray([...links], index, index + 1);
    syncUpdates(updated);
  };

  const syncUpdates = (updated) => {
    onChange('top_menu_links', updated);
  };

  const editTopMenuLink = (index) => {
    setCurrentLinkIndex(index);
    toggleModal();
  };

  const deleteTopMenuLink = (index) => {
    let tempTopMenuLink = [...links.slice(0, index), ...links.slice(index + 1)];
    onChange('top_menu_links', tempTopMenuLink);
  };

  const handleAddLink = (link) => {
    let newLinks = [...links];
    if (currentLinkIndex !== null) {
      newLinks[currentLinkIndex] = link;
    } else {
      newLinks.push(link);
    }
    onChange('top_menu_links', newLinks);
  };

  return (
    <>
      <Card roundedAbove="sm">
        <VerticalStack gap={'5'}>
          <VerticalStack gap="2">
            <Text as="h4" variant="headingMd">
              {title}
            </Text>
            <Text as="span" color="subdued">
              {description}
            </Text>
          </VerticalStack>
          <Checkbox
            label={enableLinksLabel}
            checked={is_top_menu_links}
            onChange={handleCheckboxChange}
          />
          {is_top_menu_links && (
            <VerticalStack gap={'2'}>
              <HorizontalStack gap={'5'} align="space-between">
                <Text variant="headingMd" as="h2">
                  {t('settings.tracking_page.header_links.card.title')}
                </Text>
                <Button plain onClick={handleAddLinkClick}>
                  {t(
                    'settings.tracking_page.header_links.content.add_btn_label'
                  )}
                </Button>
              </HorizontalStack>

              {Array.isArray(links) && links.length > 0 && (
                <Box
                  padding="0"
                  borderColor="border-subdued"
                  borderWidth="1"
                  borderRadius="2"
                >
                  {links.map((item, index) => {
                    let labelText =
                      item.label ||
                      metaLinks.find((l) => l.link === item.icon)?.name;

                    return (
                      <div className="SearchReplace-List" key={labelText}>
                        <VerticalStack gap={'1'}>
                          <Text breakWord variant="bodyMd" as="span">
                            {labelText}
                          </Text>
                          <Text
                            breakWord
                            variant="bodyMd"
                            as="span"
                            color="subdued"
                          >
                            {item.link}
                          </Text>
                        </VerticalStack>

                        <div className="reorder-buttons-grp">
                          <ButtonGroup segmented>
                            <Button
                              size="slim"
                              onClick={() => handleMoveUp(index)}
                              icon={<Icon source={ArrowUpMinor} />}
                            />
                            <Button
                              size="slim"
                              onClick={() => handleMoveDown(index)}
                              icon={<Icon source={ArrowDownMinor} />}
                            />
                            <Button
                              size="slim"
                              onClick={() => editTopMenuLink(index)}
                              icon={<Icon source={EditMinor} />}
                            />
                            <Button
                              size="slim"
                              onClick={() => deleteTopMenuLink(index)}
                              icon={<Icon source={DeleteMinor} />}
                            />
                          </ButtonGroup>
                        </div>
                      </div>
                    );
                  })}
                </Box>
              )}
              {links.length === 0 && (
                <div className="CustomEmptyState">
                  <Text variant="bodyMd" as="span" color="subdued">
                    {t(
                      'settings.tracking_page.header_links.card.content.no_row_message'
                    )}
                  </Text>
                </div>
              )}
            </VerticalStack>
          )}
        </VerticalStack>
      </Card>
      {showModal ? (
        <TopMenuLinkModal
          onClose={toggleModal}
          onAddLink={handleAddLink}
          item={currentLinkIndex !== null ? links[currentLinkIndex] : undefined}
          metaLinks={metaLinks}
        />
      ) : (
        ''
      )}
    </>
  );
}

export default RelatedLinksCard;
