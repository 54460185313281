import React, { useMemo } from 'react';
import moment from 'moment';
import { Box, ChoiceList, VerticalStack } from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import { dropDownOptionsWithCustom } from './helpers';
import { getDatesFromValue } from '../../pages/Shipments3/components/helpers';
import { DATE_RANGE_VALUE_CUSTOM, DB_DATE_FORMAT_STRING } from 'Constants';
import DatePickerField from './DatePickerField';
export default function DateRangeList({
  selectedOption = [],
  start,
  end,
  onChange = () => {},
}) {
  const [t] = useTranslation();

  const handleOptionChange = ([selectedOption]) => {
    const { start, end } = getDatesFromValue(selectedOption);
    onChange(selectedOption, start, end);
  };

  const handleStartChange = (value) => {
    onChange(selectedOption, moment(value).format(DB_DATE_FORMAT_STRING), end);
  };
  const handleEndChange = (value) => {
    onChange(
      selectedOption,
      start,
      moment(value).format(DB_DATE_FORMAT_STRING)
    );
  };

  const datePickerMarkup = useMemo(() => {
    return (
      <VerticalStack gap={'2'}>
        <Box width="100%">
          <DatePickerField
            value={start}
            onChange={handleStartChange}
            label={t('shipments.filter.date_starting')}
            alignment={'center'}
          />
        </Box>
        <DatePickerField
          value={end}
          onChange={handleEndChange}
          label={t('shipments.filter.date_ending')}
          alignment={'center'}
        />
      </VerticalStack>
    );
  }, [start, end]);

  const options = useMemo(() => {
    return dropDownOptionsWithCustom(t);
  }, [t]);

  return (
    <Box minHeight="250px" width="100%" paddingBlockEnd={'2'}>
      <VerticalStack gap={'2'}>
        <ChoiceList
          choices={options}
          selected={selectedOption}
          onChange={handleOptionChange}
        />
        {selectedOption === DATE_RANGE_VALUE_CUSTOM ? datePickerMarkup : ''}
      </VerticalStack>
    </Box>
  );
}
