import { useAppBridge } from '@shopify/app-bridge-react';
import { getSessionToken } from '@shopify/app-bridge-utils';
import { ClientStorage } from 'ClientStorage';
import { LOCAL_STORAGE } from 'Constants';
import LoadingPage from 'components/LoadingPage/LoadingPage';
import { SHOPIFY_LOGIN_URL } from 'config';
import React, { useEffect } from 'react';

function ShopifyLoginAuth() {
  const app = useAppBridge();

  const getToken = async () => {
    try {
      const token = await getSessionToken(app);
      console.log({ SessionToken: token });
      ClientStorage.set(LOCAL_STORAGE.RUSH_SESSION_TOKEN, token);
    } catch (e) {}
  };
  useEffect(() => {
    /**
     * Remove token and store uuid if any from localstorage
     *
     */
    ClientStorage.remove(LOCAL_STORAGE.LAST_STORE_UUID);
    ClientStorage.remove(LOCAL_STORAGE.RUSH_SHOP);
    ClientStorage.remove(LOCAL_STORAGE.ACCESS_TOKEN);
    ClientStorage.remove(LOCAL_STORAGE.RUSH_SESSION_TOKEN);
    window.__SHOPIFY_DEV_IS_VERIFIED_APP = false;
    window.__SHOPIFY_DEV_HOST = undefined;

    getToken();
    window.location.href = `${SHOPIFY_LOGIN_URL}${window.location.search}`;
  }, []);

  return <LoadingPage />;
}

export default ShopifyLoginAuth;
