import React, { useMemo } from 'react';
import { Box, Icon, SkeletonBodyText } from '@shopify/polaris';
import { SearchMinor } from '@shopify/polaris-icons';
import AutoCompleteWithTags from 'components/AutoCompleteWithTags';
import { useTranslation } from 'react-i18next';
import { useGetCountriesQuery } from 'redux/store/commonStoreApis';
import sortBy from 'lodash/sortBy';

function CountriesAutoComplete({
  value = [],
  onChange,
  label,
  placeholder,
  allowMultiple = false,
  ...props
}) {
  const [t] = useTranslation();

  const locationsResponse = useGetCountriesQuery();
  const { data = [], isLoading: isLocationsLoading } = locationsResponse;
  const locationsData = data.filter((geo = {}) => geo.type === 'country') || [];

  const countriesList = useMemo(() => {
    return sortBy([...locationsData], 'label').map((item) => {
      return {
        label: item.label,
        value: item.code_iso3,
      };
    });
  }, [locationsData]);

  return isLocationsLoading ? (
    <Box>
      <SkeletonBodyText />
    </Box>
  ) : (
    <AutoCompleteWithTags
      error={''}
      errorMessage={''}
      {...props}
      allowMultiple={allowMultiple}
      optionList={countriesList}
      selected={value}
      onChange={onChange}
      emptyState={t('shipments.settings_modal.location_default_text')}
      label={label}
      placeholder={placeholder}
      id="input-region"
      prefix={<Icon source={SearchMinor} color="inkLighter" />}
      limitItems={false}
    />
  );
}

export default CountriesAutoComplete;
