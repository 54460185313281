import React, { useState } from 'react';
import {
  Card,
  Text,
  Box,
  HorizontalStack,
  Button,
  Icon,
} from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import DeleteYotpoConfigModal from './DeleteYotpoConfigModal';
import { ExternalSmallMinor } from '@shopify/polaris-icons';
import ExternalLink from 'components/ExternalLink';
function YotpoAccount({ onRemove, isLoading }) {
  const [t] = useTranslation();
  const [isModalOpen, setModalOpen] = useState(false);

  const handleRemoveClick = () => {
    setModalOpen(true);
  };

  const handleConfirmRemove = () => {
    onRemove();
    setModalOpen(false);
  };

  const handleCancelRemove = () => {
    setModalOpen(false);
  };

  return (
    <Card padding={0} roundedAbove="sm">
      <Box padding={'5'} borderBlockEndWidth="1" borderColor="border-subdued">
        <HorizontalStack align="space-between" blockAlign="center">
          <ExternalLink
            key={'yotpo-account'}
            external={true}
            url={t('notifications.yotpo_sms.yotpo_account.url')}
          >
            <HorizontalStack gap="1" blockAlign="center">
              <Text fontWeight="semibold">
                {' '}
                {t('common.integration_account', { integration: 'Yotpo' })}
              </Text>

              <Box>
                <Icon source={ExternalSmallMinor} color="interactive" />
              </Box>
            </HorizontalStack>
          </ExternalLink>
          <Button
            plain
            destructive
            onClick={handleRemoveClick}
            loading={isLoading}
          >
            {t('common.remove')}
          </Button>
        </HorizontalStack>
      </Box>

      <DeleteYotpoConfigModal
        isOpen={isModalOpen}
        onClose={handleCancelRemove}
        onDelete={handleConfirmRemove}
      />
    </Card>
  );
}

export default YotpoAccount;
