import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Navigation } from '@shopify/polaris';
import { LOCAL_STORAGE, CHANGELOG_RELEASE_DATE } from 'Constants';

import SubscriptionBox from 'components/SubscriptionBox';
import { USER_URLS } from 'config/urls';
import { ClientStorage } from 'ClientStorage';
import { useGetPerksQuery } from 'pages/Perks/perksApi';
import moment from 'moment';
import './styles.scss';
import { getFooterNavItems, getNavItems } from './navUtils';

function LeftNavigation({ storeUUID }) {
  const [t] = useTranslation();

  const perksResponse = useGetPerksQuery(undefined, { skip: !storeUUID });
  const {
    data: perksList = [],
    isFetching: isPerksLoading,
    isSuccess: isPerksLoaded,
  } = perksResponse || {};

  const showPerksNew = useMemo(() => {
    if (perksList && isPerksLoaded && !isPerksLoading) {
      return !!perksList.find((p) => p.is_new_badge);
    }
    return false;
  }, [isPerksLoaded, isPerksLoading, perksList]);

  const [changeLogExplored, setChangeLogExplored] = useState(
    ClientStorage.get(LOCAL_STORAGE.CHANGELOG_EXPLORED)
  );
  const showChangeLogBadges =
    moment
      .duration(
        new moment().diff(new moment(CHANGELOG_RELEASE_DATE, 'MM-DD-YYYY'))
      )
      .asMonths() <= 1;

  const location = useLocation();

  const items = getNavItems({
    t,
    storeUUID,
    showChangeLogBadges,
    showPerksNew,
    pathname: location.pathname,
  });

  useEffect(() => {
    if (location.pathname) {
      const selected = (items || []).find((item) => item.selected);
      let pageLabel = selected?.label;
      if (selected?.subNavigationItems) {
        const selectedSubItem = (selected.subNavigationItems || []).find(
          (item) => item.selected
        );
        pageLabel = selectedSubItem?.label || selected?.label;
      }
      document.title = pageLabel ? `${pageLabel} - Rush` : 'Rush';
    }
  }, [items, location.pathname]);

  return (
    <>
      <Navigation location={`/s/${storeUUID}/`}>
        {![
          USER_URLS.STORES_LIST,
          USER_URLS.DETAILS,
          USER_URLS.MEMBERS_AND_PERMISSIONS,
        ].includes(location.pathname) && (
          <>
            <SubscriptionBox />
            <Navigation.Section separator items={[]} />
          </>
        )}
        <Navigation.Section title="" items={items} fill />
        <Navigation.Section
          title=""
          items={getFooterNavItems({
            t,
            changeLogExplored,
            onClick: () => {
              ClientStorage.set(LOCAL_STORAGE.CHANGELOG_EXPLORED, true);
              setChangeLogExplored(true);
              window.open(
                'https://feedback.rush.app/f/changelog/?utm_source=app.rush.app&utm_medium=app.rush.app',
                '_blank'
              );
            },
          })}
        />
      </Navigation>
    </>
  );
}
export default LeftNavigation;
