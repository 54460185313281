import React from 'react';
import {
  Card,
  FormLayout,
  TextField,
  Text,
  VerticalStack,
} from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import TranslateLink from 'components/TranslateLink/TranslateLink';

const CustomCSSCard = ({ value: customCss, onChange }) => {
  const { t } = useTranslation();

  const title = t('tracking_page_new.customCssCard.title');
  const description = t('tracking_page_new.customCssCard.description');

  return (
    <Card roundedAbove="sm">
      <VerticalStack gap="5">
        <VerticalStack gap="2">
          <Text as="h4" variant="headingMd">
            {title}
          </Text>
          <Text as="span" color="subdued">
            <TranslateLink text={description} />
          </Text>
        </VerticalStack>

        <FormLayout>
          <TextField
            autoComplete={'off'}
            label={''}
            value={customCss}
            onChange={(value) => onChange('custom_css', value)}
            multiline={10}
          />
        </FormLayout>
      </VerticalStack>
    </Card>
  );
};

export default CustomCSSCard;
