import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button } from '@shopify/polaris';
import { useGetJobStatusQuery } from 'redux/store/commonBaseApis';
import {
  useSyncTrackingPagesMutation,
  useLazyGetOS2PagesQuery,
  useLazyGetPagesConfigQuery,
} from 'pages/TrackingPageNew/newTrackingPageApis';
import { baseActions } from 'redux/store/baseSlice';
function RefreshPagesButton(props) {
  const [t] = useTranslation();
  const { name } = props;
  const dispatch = useDispatch();
  const [jobId, setJobId] = useState(null);
  const jobStatusResponse = useGetJobStatusQuery(jobId, {
    skip: !jobId,
    pollingInterval: 5000,
  });
  const { data: jobData, isSuccess: jobStatusSuccess } =
    jobStatusResponse || {};
  const [getOs2Pages] = useLazyGetOS2PagesQuery();
  const [getPagesConfig] = useLazyGetPagesConfigQuery();
  const [syncPages, syncPagesResponse] = useSyncTrackingPagesMutation();
  const {
    data: syncPagesData,
    isLoading: isPagesSyncing,
    isSuccess: isPagesSynced,
  } = syncPagesResponse;

  const handleRefresh = () => {
    if (!jobId) {
      /**
       if there is already a job in process don't run sync again
       *  */
      syncPages(name);
    }
  };

  useEffect(() => {
    if (jobStatusSuccess && jobData?.process === 'finished') {
      setJobId(null);
      setTimeout(() => {
        dispatch(
          baseActions.setToastMessage({
            message: t('tracking_page_new_refresh_pages.success'),
            type: 'success',
          })
        );
        getOs2Pages();
        getPagesConfig();
      }, 2000);
    }
  }, [jobStatusSuccess, jobData]);

  useEffect(() => {
    if (!isPagesSyncing && isPagesSynced && syncPagesData) {
      setJobId(syncPagesData?.job_uuid);
      dispatch(
        baseActions.setToastMessage({
          message: t('tracking_page_new_refresh_pages.message'),
          type: 'success',
        })
      );
    }
  }, [syncPagesData, isPagesSynced, isPagesSyncing]);

  return (
    <Button plain onClick={handleRefresh} loading={isPagesSyncing || jobId}>
      {t('tracking_page_new_refresh_pages.label')}
    </Button>
  );
}

export default RefreshPagesButton;
