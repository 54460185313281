import {
  Text,
  Card,
  Icon,
  Link,
  Spinner,
  HorizontalStack,
  Box,
  Button,
} from '@shopify/polaris';
import { CUSTOM_LANGUAGE_CREATE } from 'Constants';
import { STORE_URLS } from 'config/urls';
import React from 'react';
import { useSelector } from 'react-redux';
import { MobileCancelMajor } from '@shopify/polaris-icons';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CUSTOM_LANGUAGE_EDIT } from 'Constants';

function CustomLanguages({
  defaultLanguageSelection = 'en',
  privateLanguages = [],
  deleteCustomLanguage,
  allLanguages = [],
}) {
  const [t] = useTranslation();
  const navigate = useNavigate();

  const loading = false;
  const storeUUID = useSelector((state) => state.shop.storeUUID);

  const handleShowAddLanguageForm = () => {
    navigate(
      `/s/${storeUUID}${STORE_URLS.TRACKING_PAGES}/${defaultLanguageSelection}/${CUSTOM_LANGUAGE_CREATE}`
    );
  };
  return (
    <Card roundedAbove="sm">
      <Box padding="0">
        <HorizontalStack gap={'5'} align="space-between">
          <Text variant="headingMd" as="span">
            {t(`tracking_page_new.localisation_custom_language.your_languages`)}
          </Text>
          <Button plain onClick={handleShowAddLanguageForm}>
            {t(
              `settings.tracking_page.localisation.custom_language.add_language`
            )}
          </Button>
        </HorizontalStack>

        {privateLanguages?.length ? (
          <div className="custom-language-container">
            {privateLanguages.map((object) => {
              return (
                <React.Fragment key={object.uuid}>
                  <div className="language-main-container" key={object.uuid}>
                    <div className="language-container">
                      {loading ? (
                        <div className="delete-icon">
                          <Spinner size={'small'} />
                        </div>
                      ) : (
                        <div
                          onClick={() => deleteCustomLanguage(object.uuid)}
                          className="delete-icon"
                        >
                          <Icon source={MobileCancelMajor} color="base" />
                        </div>
                      )}

                      <img
                        height="20px"
                        width="20px"
                        alt=""
                        src={object.svg_flag_url}
                      />
                      <h2 className="language-links">{object.label_english}</h2>
                    </div>
                    <div>
                      <Link
                        className="add-language-link"
                        to={`/s/${storeUUID}${STORE_URLS.TRACKING_PAGES}/${object.uuid}/${CUSTOM_LANGUAGE_EDIT}`}
                      >
                        <div className="edit-text">edit</div>
                      </Link>
                    </div>
                  </div>
                </React.Fragment>
              );
            })}
          </div>
        ) : (
          <React.Fragment>
            <HorizontalStack align={'center'}>
              <Box padding={'5'}>
                <Text variant="bodyMd" as="span" color="subdued">
                  {t(`tracking_page_new.custom_languages_support_description`, {
                    number: allLanguages.length,
                  })}
                </Text>
              </Box>
            </HorizontalStack>
          </React.Fragment>
        )}
      </Box>
    </Card>
  );
}

export default CustomLanguages;
