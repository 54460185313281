import {
  Box,
  Divider,
  HorizontalStack,
  Text,
  Tooltip,
  VerticalStack,
} from '@shopify/polaris';
import TranslateLink from 'components/TranslateLink';
import { groupBy, orderBy } from 'lodash';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

function Timeline({ orderTimeline = [] }) {
  const navigate = useNavigate();
  const formatDate = (dateTime) => {
    const currentDate = moment.utc(dateTime);

    const formattedDate = currentDate.calendar(null, {
      sameDay: '[Today]',
      lastDay: '[Yesterday]',
      lastWeek: '[Last] dddd',
      sameElse: 'MMMM D',
    });
    return formattedDate;
  };
  const [t] = useTranslation();
  const timeline = orderBy(orderTimeline, 'timestamp', 'desc')?.map((t) => {
    return {
      ...t,
      day: moment.utc(t.timestamp).format('L'),
    };
  });
  const groups = groupBy(timeline, 'day');
  return (
    <Box padding="0" width="100%">
      <VerticalStack gap={'5'}>
        <VerticalStack gap={'1'}>
          <Text as="h2" variant="headingMd">
            {t('notifications_activity.timeline')}:
          </Text>
          <Divider borderColor="border" />
        </VerticalStack>
        <VerticalStack gap={'4'}>
          {Object.keys(groups).map((g) => {
            const group = groups[g];

            return group.length > 0 ? (
              <VerticalStack gap={'4'}>
                <Box paddingBlockStart={'1'}>
                  <Tooltip
                    content={moment.utc(group[0].timestamp).format('ll')}
                  >
                    <Text variant="headingSm" as="span">
                      <div className={'usage'}>
                        {formatDate(group[0].timestamp)}
                      </div>
                    </Text>
                  </Tooltip>
                </Box>

                <VerticalStack gap="4" width="100%">
                  {group?.map((timeline = {}) => {
                    return (
                      <HorizontalStack
                        key={timeline.status_uuid}
                        wrap={false}
                        gap="2"
                        align={'space-between'}
                        blockAlign="start"
                      >
                        <Box maxWidth="90%">
                          <TranslateLink
                            external={false}
                            pathType={'app'}
                            text={timeline.caption}
                            onClick={(event, link) => {
                              event.stopPropagation();
                              event.preventDefault();
                              navigate(link?.href);
                            }}
                          ></TranslateLink>
                        </Box>
                        <Text as="p" color="subdued">
                          {moment.utc(timeline.timestamp).format('LT')}
                        </Text>
                      </HorizontalStack>
                    );
                  })}
                </VerticalStack>
              </VerticalStack>
            ) : (
              ''
            );
          })}
        </VerticalStack>
      </VerticalStack>
    </Box>
  );
}

export default Timeline;
