import React from 'react';
import { Icon, Text, VerticalStack, HorizontalStack } from '@shopify/polaris';
import { ExternalSmallMinor, NoteMajor } from '@shopify/polaris-icons';
import ExternalLink from '../ExternalLink';

export default function Subtitle({ text, actions }) {
  return (
    <VerticalStack gap={'2'}>
      <Text variant="bodyMd" as="span">
        {text}
      </Text>
      <HorizontalStack gap={'2'}>
        {actions.map((action) => (
          <ExternalLink url={action.source} key={action.text} external={true}>
            <HorizontalStack gap={'1'}>
              <Icon source={action.icon || NoteMajor} color={'interactive'} />
              <Text variant="bodyMd" as="span">
                {action.text}
              </Text>
              <Icon source={ExternalSmallMinor} color={'interactive'} />
            </HorizontalStack>
          </ExternalLink>
        ))}
      </HorizontalStack>
    </VerticalStack>
  );
}
