import isEmpty from 'lodash/isEmpty';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { transformParams, formatDateTime } from '../utils';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useLazyGetNotificationsQuery } from '../NotificationsActivityFeedApi';
import {
  ChoiceList,
  Badge,
  HorizontalStack,
  Box,
  Text,
} from '@shopify/polaris';
import { STORE_URLS } from 'config/urls';
import TranslateLink from 'components/TranslateLink';
import Subtitle from 'components/Subtitle';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import TabsTableSkeleton from 'components/Skeletons/WithoutChecksTabsTableSkeleton';
import { sortBy, startCase } from 'lodash';
import { getActivityStatusBadge, getEventBadge } from 'Utils/BadgeUtils';
import {
  useGetChannelsQuery,
  useGetTriggersQuery,
  useGetUserDetailsQuery,
} from 'redux/store/commonBaseApis';
import debounce from 'lodash/debounce';
import FilterTable from 'components/FilterTable';
import SmallTopMessage from 'components/SmallTopMessage';
import ExternalLink from 'components/ExternalLink';
import { RefreshMajor } from '@shopify/polaris-icons';
import DateRangeList from 'components/DatePicker/DateRangeList';
import RushPage from 'components/custom/RushPage';
import useIsEmbededApp from 'hooks/useIsEmbededApp';
import RushFooter from 'components/custom/RushFooter';
import { useSelector } from 'react-redux';
import { StoreUUID } from 'redux/store/shopSlice';
import RushBanner from 'components/custom/RushBanner';
import { USER_URLS } from 'config/urls';

const defaultFilter = {
  search: '',
  sortBy: 'created_at',
  sortOrder: 'desc',
  channel_id: '',
  trigger_id: '',
  date_range: '',
};
const itemsPerPage = '50';
const cols = ['status', 'recipient', 'channel', 'event', 'dateTime'];

function NotificationsActivityFeed() {
  const params = useParams();
  const [t] = useTranslation();
  const { search } = useLocation();
  const navigate = useNavigate();
  const storeUUID = useSelector(StoreUUID);
  const [getNotifications, notificationsResponse] =
    useLazyGetNotificationsQuery();
  const [filter, setFilter] = useState(defaultFilter);
  const [selectedTab, setSelectedTab] = useState(0);
  const isEmbededApp = useIsEmbededApp();

  const { data: user = {} } = useGetUserDetailsQuery();

  const tabs = [
    { id: 'all', content: t('common.all') },
    {
      id: 'aabab2ae-bf75-4d4a-af75-a3247ba18e26',
      content: t('common.queued'),
    },
    {
      id: '938e015b-48e2-452c-8ba5-3017d2661dc3',
      content: t('common.processing'),
    },
    {
      id: 'fb29f9b3-2315-4a9d-a5c2-2626f5cbce35',
      content: t('common.failed'),
    },
    {
      id: 'f67aa3ee-6fdc-4860-8df5-ba6b4d03fcee',
      content: t('common.sent'),
    },
  ];

  useEffect(() => {
    const urlParams = new URLSearchParams(search);

    const fields = urlParams.get('searchByFields') || '';
    const searchByFieldsParam = new URLSearchParams(fields);
    const status = searchByFieldsParam.get('status_id');

    const trigger = searchByFieldsParam.get('trigger_id');
    const channel = searchByFieldsParam.get('channel_id');

    const dateRange = urlParams.get('dateRange') || '';
    const dateRangeParams = new URLSearchParams(dateRange);
    const createdAt = dateRangeParams.get('created_at');

    const selectedRangeOption = urlParams.get('selected-range-option');

    let filterFromParams = {
      search: urlParams.get('search') || '',
      channel_id: channel || defaultFilter.channel_id,
      trigger_id: trigger || defaultFilter.trigger_id,
    };

    if (createdAt) {
      const createdAtArr = createdAt.split('_');
      filterFromParams.date_range =
        createdAtArr.length > 1
          ? { start: createdAtArr[0], end: createdAtArr[1] }
          : defaultFilter.date_range;

      if (selectedRangeOption) {
        filterFromParams.date_range.selectedRangeOption = selectedRangeOption;
      }
    }

    setFilter({ ...filterFromParams });
    if (status) {
      setSelectedTab(tabs.findIndex(({ id }) => id === status));
    } else {
      setSelectedTab(0);
    }

    if (!urlParams.has('page')) {
      urlParams.set('page', '1');
    }
    if (!urlParams.has('perPage')) {
      urlParams.set('perPage', itemsPerPage);
    }

    getNotifications(transformParams(urlParams).toString());
  }, [search, getNotifications, storeUUID]);

  const onPageChange = useCallback(
    (pageNumber) => {
      const urlParams = new URLSearchParams(search);
      urlParams.set('page', `${pageNumber}`);

      navigate(
        `/s/${params.uuid}${
          STORE_URLS.NOTIFICATIONS_ACTIVITY_FEED + '?' + urlParams.toString()
        }`
      );
    },
    [search]
  );

  const gotoFirstPage = useCallback(
    (filter, tab = selectedTab) => {
      const urlParams = new URLSearchParams(search);
      urlParams.set('page', '1');
      urlParams.set('perPage', itemsPerPage);

      urlParams.delete('search');
      urlParams.delete('searchByFields');
      urlParams.delete('searchByRelation');
      urlParams.delete('dateRange');

      filter.search && urlParams.set('search', filter.search);

      const searchByFieldsParam = new URLSearchParams('');

      if (tab > 0) {
        searchByFieldsParam.append('status_id', tabs[tab]?.id);
      }

      filter.trigger_id &&
        searchByFieldsParam.append('trigger_id', filter.trigger_id);
      filter.channel_id &&
        searchByFieldsParam.append('channel_id', filter.channel_id);

      if (searchByFieldsParam.toString()) {
        urlParams.set('searchByFields', searchByFieldsParam.toString());
      }
      const dateRangeParams = new URLSearchParams('');
      filter.date_range &&
        dateRangeParams.set(
          'created_at',
          `${moment(filter.date_range.start).format('YYYY-MM-DD')}_${moment(
            filter.date_range.end
          ).format('YYYY-MM-DD')}`
        );
      if (dateRangeParams.toString()) {
        urlParams.set('dateRange', dateRangeParams.toString());
      }
      if (dateRangeParams.toString()) {
        urlParams.set(
          'selected-range-option',
          filter.date_range?.selectedRangeOption?.toString()
        );
      }

      navigate(
        `/s/${params.uuid}${
          STORE_URLS.NOTIFICATIONS_ACTIVITY_FEED + '?' + urlParams.toString()
        }`
      );
    },
    [search]
  );

  const handleTabChange = useCallback(
    (selectedTabIndex) => {
      setSelectedTab(selectedTabIndex);
      gotoFirstPage(filter, selectedTabIndex);
    },
    [gotoFirstPage, filter]
  );

  const handleFilterChange = useCallback(
    (key, value, filters) => {
      // sending filters from filters component to break prev reference of debounce in useCallback
      const f = filters ? { ...filters } : { ...filter };
      const updatedFilter = { ...f, [key]: value };
      setFilter(updatedFilter);
      gotoFirstPage(updatedFilter);
    },
    [filter, gotoFirstPage, setFilter]
  );

  const handleFiltersClearAll = useCallback(() => {
    setFilter(defaultFilter);
    gotoFirstPage(defaultFilter);
  }, [gotoFirstPage]);

  const {
    data: {
      data: responseData = [],
      meta: paginationMeta = {
        current_page: 0,
        total: 0,
        per_page: 50,
        is_last_page: true,
      },
    } = {
      responseData: [],
      paginationMeta: {},
    },
    isLoading: isNotificationsLoading,
    isFetching: isNotificationsFetching,
    isSuccess: isNotificationsSuccess,
  } = notificationsResponse;

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  const handlePrevPageClick = () => {
    onPageChange(Number(paginationMeta.current_page) - 1);
    scrollToTop();
  };
  const handleNextPageClick = () => {
    onPageChange(Number(paginationMeta.current_page) + 1);
    scrollToTop();
  };
  /**
   *  tableData is to format data for table as table required values of following keys in sequence
   */
  const tableData = useMemo(() => {
    if (
      isNotificationsSuccess &&
      !isNotificationsFetching &&
      !isEmpty(responseData)
    ) {
      return responseData;
    } else {
      return [];
    }
  }, [responseData, isNotificationsSuccess, isNotificationsFetching]);

  const paginationLabel = useMemo(() => {
    if (paginationMeta) {
      const curr = paginationMeta.current_page || 0;
      const totalPages =
        Math.ceil(paginationMeta.total / paginationMeta.per_page) || 0;
      return `${curr} of ${totalPages}`;
    }
    return '1 of 1';
  }, [paginationMeta]);

  /**
   * Filters
   */

  const {
    data: channelData = [],
    isLaoding: isChannelsLoading,
    isSuccess: isChannelsLoaded,
  } = useGetChannelsQuery();

  const channels = useMemo(() => {
    if (!isChannelsLoading && isChannelsLoaded) {
      return channelData.map((c) => ({
        label: c.name,
        value: c.id,
      }));
    } else {
      return [];
    }
  }, [isChannelsLoading, isChannelsLoaded]);

  const {
    data: triggersData = [],
    isLaoding: isTriggersLoading,
    isSuccess: isTriggersLoaded,
  } = useGetTriggersQuery();

  const events = useMemo(() => {
    if (!isTriggersLoading && isTriggersLoaded) {
      return sortBy([...triggersData], 'title').map((c) => ({
        label: c.title,
        value: c.id,
      }));
    } else {
      return [];
    }
  }, [isTriggersLoading, isTriggersLoaded]);

  const [query, setQuery] = useState(filter.search);
  // All this mess is to handle debounce with polaris filter
  const [textChangeCase, setTextChangeCase] = useState(false);

  useEffect(() => {
    if (filter.search) {
      setQuery(filter.search);
      setTextChangeCase(false);
    }
  }, [filter.search]);

  const filters = [
    {
      key: 'channel',
      label: t('notifications_activity.all_channels'),
      filter: (
        <div className="small-gap-list">
          <ChoiceList
            title={t('notifications_activity.all_channels')}
            titleHidden
            choices={channels}
            selected={[filter.channel_id]}
            onChange={([value]) => handleFilterChange('channel_id', value)}
          />
        </div>
      ),
      shortcut: true,
      hideClearButton: false,
    },
    {
      key: 'event',
      label: t('notifications_activity.all_events'),
      filter: (
        <div className="small-gap-list">
          <ChoiceList
            title={t('notifications_activity.all_events')}
            titleHidden
            choices={events}
            selected={[filter.trigger_id]}
            onChange={([value]) => handleFilterChange('trigger_id', value)}
          />
        </div>
      ),
      shortcut: true,
    },
    {
      key: 'dateTime',
      label: t('notifications_activity.all_times'),
      filter: (
        <DateRangeList
          start={filter.date_range?.start}
          end={filter.date_range?.end}
          selectedOption={filter.date_range?.selectedRangeOption}
          onChange={(selectedRangeOption, start, end) => {
            handleFilterChange('date_range', {
              selectedRangeOption,
              start,
              end,
            });
          }}
        />
      ),
    },
  ];

  const appliedFilters = useMemo(() => {
    let filters = [];
    if (!isEmpty(filter.channel_id)) {
      const key = 'channel';
      filters.push({
        key,
        label: `${t('notifications_activity.table_heading.channel')}: ${
          channelData.find((c) => c.id === filter.channel_id)?.name
        }`,
        onRemove: () => handleFilterChange('channel_id', ''),
      });
    }

    if (!isEmpty(filter.trigger_id)) {
      const key = 'event';
      filters.push({
        key,
        label: `${t('notifications_activity.table_heading.event')}: ${
          triggersData.find((c) => c.id === filter.trigger_id)?.title
        }`,
        onRemove: () => handleFilterChange('trigger_id', ''),
      });
    }
    if (!isEmpty(filter.date_range)) {
      const key = 'dateTime';
      filters.push({
        key,
        label: `${t('notifications_activity.table_heading.date_time')}: ${
          filter.date_range?.start
        } - ${filter.date_range?.end}`,
        onRemove: () => handleFilterChange('date_range', ''),
      });
    }
    return filters;
  }, [channelData, filter, triggersData]);

  const debouncesQueryChange = useCallback(
    debounce((value, filter) => {
      handleFilterChange('search', value, filter);
    }, 400),
    []
  );

  useEffect(() => {
    query && textChangeCase && debouncesQueryChange(query, filter);
  }, [filter, textChangeCase, query, debouncesQueryChange]);

  const handleQueryChange = (value) => {
    setTextChangeCase(true);
    setQuery(value);
  };

  const handleQueryClear = () => {
    setQuery('');
    handleFilterChange('search', '');
  };

  const handleClearAll = () => {
    setQuery('');
    handleFiltersClearAll();
  };

  /*
  Filter End
  */

  const rows = useMemo(() => {
    return tableData.map((item) => {
      const {
        latest_status,
        created_at,
        id,
        channel = {},
        subscriber = {},
        trigger = {},
        order_label = '',
        order_external_link = '',
        is_test,
      } = item;
      const statusBadge = getActivityStatusBadge(latest_status);
      const eventBadge = getEventBadge(trigger.slug);
      const email = (subscriber || {}).email;
      return {
        uuid: id,
        status: latest_status && (
          <HorizontalStack gap={2}>
            <Badge status={statusBadge.status} progress={statusBadge.progress}>
              {startCase(latest_status)}
            </Badge>

            {is_test && <Badge>{`Test`}</Badge>}
          </HorizontalStack>
        ),
        order: order_label && (
          <span onClick={(e) => e.stopPropagation()}>
            <ExternalLink url={order_external_link} external={false}>
              {order_label}
            </ExternalLink>
          </span>
        ),

        recipient: email && (
          <a
            style={{ color: 'rgba(36, 99, 188, 1)' }}
            onClick={(event) => {
              event.stopPropagation();
              window.open(`mailto:${email}`, '_blank');
            }}
          >
            {email}
          </a>
        ),
        channel: (channel || {}).name,
        event: (
          <Badge status={eventBadge.status} progress={eventBadge.progress}>
            {startCase((trigger || {}).slug)}
          </Badge>
        ),
        dateTime: created_at && formatDateTime(created_at),
      };
    });
  }, [tableData]);

  const onSort = (colIndex, sortOrder) => {
    handleSort(cols[colIndex], sortOrder === 'ascending' ? 'asc' : 'desc');
  };
  /**
   * Table End
   */

  const handleSort = (sortBy, sortOrder) => {
    const updatedFilter = { ...filter, sortBy, sortOrder };
    setFilter(updatedFilter);
    gotoFirstPage(updatedFilter);
  };

  return (
    <>
      {!user.email_verified && !user.sudo && !isEmbededApp ? (
        <Box padding={{ sm: '0', md: '5' }}>
          <RushBanner
            bannerKey={'activity-feed-email-verification-banner'}
            title={t('settings_page.banner.title')}
            action={{
              content: t('settings_page.banner.action'),
              onAction: () => navigate(USER_URLS.DETAILS),
            }}
            status="warning"
          >
            <Text variant="bodyMd" as="span">
              {t('settings_page.banner.description')}
            </Text>
          </RushBanner>
        </Box>
      ) : (
        <SmallTopMessage />
      )}
      <RushPage
        fullWidth
        title={t('notifications_activity.page-title')}
        backAction={{
          url: `/s/${params.uuid}${STORE_URLS.NOTIFICATIONS}`,
        }}
        subtitle={
          <Subtitle
            text={t('notifications_activity.sub-title')}
            actions={[
              {
                text: t('common.get-started'),
                source: t('notifications_activity.get-started-link'),
              },
            ]}
          />
        }
        secondaryActions={[
          {
            content: t('shipment_detail.actions.refresh'),
            icon: RefreshMajor,
            disabled: isNotificationsLoading,
            onAction: () => {
              const urlParams = new URLSearchParams(search);
              getNotifications(transformParams(urlParams).toString());
            },
          },
        ]}
      >
        {isNotificationsLoading ? (
          <TabsTableSkeleton />
        ) : (
          <div
            className={`notifications-filter-table ${
              !user.email_verified && !user.sudo && !isEmbededApp
                ? 'disabled-feature-page'
                : ''
            }`}
          >
            <FilterTable
              isLoading={isNotificationsFetching}
              filterProps={{
                sortOptions: [],
                sortSelected: '',
                onSort: onSort,
                queryPlaceholder: t(
                  'notifications_activity.query_place_holder'
                ),
                queryValue: query,
                filters: filters,
                appliedFilters: appliedFilters,
                onQueryChange: handleQueryChange,
                onQueryClear: handleQueryClear,
                onClearAll: handleClearAll,
              }}
              tableProps={{
                columns: [
                  {
                    title: t('notifications_activity.table_heading.status'),
                    value: 'status',
                    selected: true,
                  },
                  {
                    title: t('notifications_activity.order'),
                    value: 'order',
                    selected: true,
                  },
                  {
                    title: t('notifications_activity.table_heading.recipient'),
                    value: 'recipient',
                    selected: true,
                  },
                  {
                    title: t('notifications_activity.table_heading.channel'),
                    value: 'channel',
                    selected: true,
                  },
                  {
                    title: t('notifications_activity.table_heading.event'),
                    value: 'event',
                    selected: true,
                  },
                  {
                    title: t('notifications_activity.table_heading.date_time'),
                    value: 'dateTime',
                    selected: true,
                  },
                ],
                onColumnsChange: () => {},
                allowColumnsEdit: false,
                resourceName: {
                  singular: t(
                    'notifications_activity.page-title'
                  ).toLowerCase(),
                  plural: t('notifications_activity.page-title').toLowerCase(),
                },
                data: rows,
                selectable: false,
                selectedResources: [],
                handleSelectionChange: () => {},
                allResourcesSelected: false,
                promotedBulkActions: [],
                onRowClick: (uuid, openInNewTab) => {
                  // if (user.email_verified || user.sudo || isEmbededApp) {
                  openInNewTab
                    ? window.open(
                        `/s/${params.uuid}${
                          STORE_URLS.NOTIFICATIONS_ACTIVITY_FEED + '/' + uuid
                        }`,
                        '_blank'
                      )
                    : navigate(
                        `/s/${params.uuid}${
                          STORE_URLS.NOTIFICATIONS_ACTIVITY_FEED + '/' + uuid
                        }`,
                        {
                          state: { search: search || `?page=1&perPage=50` },
                        }
                      );
                  // }
                },
              }}
              tabProps={{
                tabs: tabs.map((t) => t.content),
                selectedTab: selectedTab,
                onTabChange: handleTabChange,
              }}
              paginationProps={{
                label: paginationLabel,
                hasPrevious: paginationMeta.current_page > 1,
                onPrevious: handlePrevPageClick,
                hasNext: !paginationMeta.is_last_page,
                onNext: handleNextPageClick,
              }}
            />
          </div>
        )}
        <RushFooter>
          <TranslateLink text={t('footer_help.dashboard')} />
        </RushFooter>
      </RushPage>
    </>
  );
}

export default NotificationsActivityFeed;
