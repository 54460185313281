import React, { useState, useCallback, useEffect } from 'react';
import {
  Modal,
  FormLayout,
  TextField,
  Select,
  Text,
  VerticalStack,
} from '@shopify/polaris';

import { useTranslation } from 'react-i18next';
import TranslateLink from 'components/TranslateLink';
import _ from 'lodash';
import stringHelper from 'helpers/string';
import RushModal from 'components/custom/RushModal';
import { baseActions } from 'redux/store/baseSlice';
import { useDispatch } from 'react-redux';

export default function SlackModal({
  showSlackModal,
  handleShowSlackModal,
  getSlack,
  testSlack,
  saveSlack,
  loading,
  slackConfig,
  slackMeta,
  slackError,
  resetSlack,
  slackConfigSaved,
  slackTestingConfirmation,
}) {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const [toasterActive, setToasterActive] = useState(false);
  const [toasterText, setToasterText] = useState(false);
  const [isToasterError, setIsToasterError] = useState(null);
  const toggleToasterActive = useCallback(
    () => setToasterActive((active) => !active),
    []
  );

  useEffect(() => {
    if (toasterActive) {
      dispatch(
        baseActions.setToastMessage({
          message: toasterText,
          type: isToasterError ? 'error' : 'success',
        })
      );
    }
  }, [toasterActive]);

  const [webhookURLError, setWebhookURLError] = useState(null);
  const [webhookURL, setWebhookURL] = useState(null);
  const handleWebhookURLChange = useCallback(
    (value) => setWebhookURL(value),
    []
  );

  const [selectedLanguage, setSelectedLanguage] = useState('-created_at');
  const handleSelectedLanguageChange = useCallback(
    (value) => setSelectedLanguage(value),
    []
  );

  const [selectedTrigger, setSelectedTrigger] = useState('-created_at');
  const handleSelectedTriggerChange = useCallback(
    (value) => setSelectedTrigger(value),
    []
  );

  const submit = () => {
    if (!webhookURL || !stringHelper.validateUrl(webhookURL)) {
      setWebhookURLError(t('slack.modal.webhook_error_text'));
      return;
    }
    setWebhookURLError(null);
    saveSlack({
      webhook_url: webhookURL,
      trigger: selectedTrigger,
      language: selectedLanguage,
    });
    // handleShowSlackModal()
  };

  useEffect(() => {
    if (!_.isNull(slackError)) {
      setToasterText(slackError);
      setIsToasterError(true);
      toggleToasterActive();
      resetSlack();
    }
  }, [slackError, toggleToasterActive, resetSlack]);

  useEffect(() => {
    if (slackTestingConfirmation) {
      setToasterText(t('slack.modal.test_success'));
      setIsToasterError(false);
      toggleToasterActive();
      resetSlack();
    }
  }, [slackTestingConfirmation, toggleToasterActive, t, resetSlack]);

  useEffect(() => {
    if (slackConfigSaved) {
      setToasterText(t('slack.modal.save_success'));
      setIsToasterError(false);
      toggleToasterActive();
      const timer = setTimeout(() => {
        resetSlack();
        handleShowSlackModal();
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [
    slackConfigSaved,
    toggleToasterActive,
    t,
    handleShowSlackModal,
    resetSlack,
  ]);

  useEffect(() => {
    if (!loading && showSlackModal && _.isEmpty(slackConfig)) {
      getSlack();
    }
  }, [getSlack, loading, showSlackModal, slackConfig]);

  useEffect(() => {
    if (!_.isEmpty(slackConfig)) {
      setWebhookURL(slackConfig.webhook_url);
      setSelectedLanguage(slackConfig.language);
      setSelectedTrigger(slackConfig.trigger);
    }
  }, [slackConfig]);

  const testSlackNotification = () => {
    if (!webhookURL || !stringHelper.validateUrl(webhookURL)) {
      setWebhookURLError(t('slack.modal.webhook_error_text'));
      return;
    }
    setWebhookURLError(null);
    testSlack({
      webhook_url: webhookURL,
      trigger: selectedTrigger,
      language: selectedLanguage,
    });
  };

  return (
    <RushModal
      open={showSlackModal}
      onClose={handleShowSlackModal}
      title={t('slack.modal.title')}
      primaryAction={{
        content: t('slack.modal.primary_btn_text'),
        onAction: submit,
        loading: loading,
        disabled: loading || slackConfigSaved,
      }}
      secondaryActions={[
        {
          content: t('slack.modal.secondary_btn_text'),
          onAction: testSlackNotification,
          loading: loading,
          disabled: loading || slackConfigSaved,
        },
      ]}
    >
      <Modal.Section>
        <VerticalStack gap="4">
          <Text variant="bodyMd" as="span" color="subdued">
            <TranslateLink text={t('slack.modal.description')} />
          </Text>
        </VerticalStack>
        <FormLayout>
          <TextField
            autoComplete={'off'}
            label={t('slack.modal.webhook_label')}
            onChange={handleWebhookURLChange}
            value={webhookURL}
            error={webhookURLError}
            disabled={loading || slackConfigSaved}
            id={`input-slack-webhook`}
          />
          <FormLayout.Group>
            <Select
              label={t('slack.modal.language_label')}
              options={slackMeta.languages.map((lang) => ({
                label: lang.label,
                value: lang.slug,
              }))}
              onChange={handleSelectedLanguageChange}
              value={selectedLanguage}
              disabled={loading || slackConfigSaved}
              id={`select-slack-language`}
            />
            <Select
              label={t('slack.modal.trigger_label')}
              options={slackMeta.triggers.map((lang) => ({
                label: lang.label,
                value: lang.slug,
              }))}
              onChange={handleSelectedTriggerChange}
              value={selectedTrigger}
              disabled={loading || slackConfigSaved}
              id={`select-slack-triggers`}
            />
          </FormLayout.Group>
        </FormLayout>
      </Modal.Section>
    </RushModal>
  );
}
