import React from 'react';
import {
  MediaCard,
  SkeletonBodyText,
  SkeletonDisplayText,
  VerticalStack,
  VideoThumbnail,
  Box,
} from '@shopify/polaris';

export default function TaskItemSkeleton() {
  return (
    <Box width="100%">
      <MediaCard
        size={'small'}
        description={
          <VerticalStack gap={'1'}>
            <SkeletonBodyText lines={4} />
            <br />
            <SkeletonDisplayText size={'small'} />
          </VerticalStack>
        }
      >
        <Box>
          <VideoThumbnail onClick={() => {}} thumbnailUrl={null} />
        </Box>
      </MediaCard>
    </Box>
  );
}
