import {
  Card,
  HorizontalStack,
  Text,
  Tooltip,
  VerticalStack,
} from '@shopify/polaris';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { billingActions } from 'pages/Billing/billingSlice';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { useMemo } from 'react';
import ExternalLink from 'components/ExternalLink';
import { STORE_URLS } from 'config/urls';
import { useParams } from 'react-router-dom';
import RushBanner from 'components/custom/RushBanner';
import TranslateLink from 'components/TranslateLink';
function PredictedDelivery({ forecast = {}, isEDDAvailable }) {
  const [t] = useTranslation();
  const params = useParams();
  const dispatch = useDispatch();
  const { carrier_estimated_delivery_date, smart_estimated = {} } = forecast;
  const eddInfo = smart_estimated;

  const eddTooltip = useMemo(() => {
    if (smart_estimated?.debug?.source === 'rules') {
      return t('shipment_details.transit_based_estimation');
    }
    if (smart_estimated?.debug?.source === 'carrier') {
      return t('shipment_details.carrier_based_estimation');
    }
    if (smart_estimated?.debug?.source === 'ai') {
      return t('shipment_details.rush_ai_based_estimation');
    }
  }, [smart_estimated]);

  const renderEnableEDDBanner = () => {
    return !isEDDAvailable ? (
      <RushBanner
        key={'shipment_details.enable_ai_dates'}
        status="info"
        title={t('shipment_details.enable_ai_dates')}
        action={{
          content: t('common.upgrade_subscription'),
          onClick: () => dispatch(billingActions.toggleUpdateModal()),
        }}
      >
        <TranslateLink
          text={t('shipment_details.enable_ai_dates_message')}
        ></TranslateLink>
      </RushBanner>
    ) : (
      ''
    );
  };

  return !isEDDAvailable ? (
    renderEnableEDDBanner()
  ) : (
    <>
      {!eddInfo?.visible && isEmpty(carrier_estimated_delivery_date) ? (
        ''
      ) : (
        <Card>
          <VerticalStack gap={4}>
            <Text variant="headingMd">
              {t('shipment_details.predicted_delivery')}
            </Text>

            <VerticalStack gap={1}>
              {!!carrier_estimated_delivery_date && (
                <HorizontalStack align="space-between">
                  <Text>{t('shipment_details.carrier_eta')} </Text>
                  <Text>
                    {moment(carrier_estimated_delivery_date).format('ll')}
                  </Text>
                </HorizontalStack>
              )}
              {!!eddInfo?.type && (
                <HorizontalStack align="space-between">
                  <ExternalLink
                    url={`/s/${params.uuid}${STORE_URLS.SMART_EDD}`}
                    pathType={'app'}
                    external={false}
                    target={'_self'}
                    removeUnderline
                  >
                    {t('shipment_details.smart_eta')}
                  </ExternalLink>
                  <Tooltip content={eddTooltip}>
                    <div className="usage">
                      {eddInfo?.type === 'single' ? (
                        <Text>{moment(eddInfo.edd).format('ll')}</Text>
                      ) : (
                        <Text>{`${moment(eddInfo?.edd_min).format(
                          'll'
                        )} - ${moment(eddInfo?.edd_max).format('ll')}`}</Text>
                      )}
                    </div>
                  </Tooltip>
                </HorizontalStack>
              )}
            </VerticalStack>
          </VerticalStack>
        </Card>
      )}
    </>
  );
}

export default PredictedDelivery;
