/**
 * A single select dropdown with popover component
 */
import React, { useCallback, useState } from 'react';
import { Button, OptionList, Popover, VerticalStack } from '@shopify/polaris';
import Proptypes from 'prop-types';

function Dropdown(props) {
  const { options = [], onChange, selected, title } = props;
  const [openDD, setOpenDD] = useState(false);
  const toggleOpenDD = useCallback(() => setOpenDD((openDD) => !openDD), []);
  const DDactivator = (
    <Button onClick={toggleOpenDD} disclosure fullWidth textAlign="left">
      {typeof selected !== 'undefined'
        ? (options.find((t) => t.value === selected) || {}).label
        : title}
    </Button>
  );

  return (
    <VerticalStack gap={'1'}>
      <Popover
        fullWidth
        activator={DDactivator}
        active={openDD}
        onClose={toggleOpenDD}
      >
        <OptionList
          allowMultiple={false}
          title={title}
          onChange={([selectedOpt]) => {
            toggleOpenDD();
            onChange(selectedOpt);
          }}
          options={options}
          selected={[selected]}
        />
      </Popover>
    </VerticalStack>
  );
}

Dropdown.proptypes = {
  options: Proptypes.arrayOf(
    Proptypes.shape({ label: Proptypes.string, value: Proptypes.any })
  ).isRequired,
  onchange: Proptypes.func.isRequired,
  title: Proptypes.string.isRequired,
  selected: Proptypes.string | Proptypes.number,
};
export default Dropdown;
