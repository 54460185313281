import React from 'react';

import { Text, Card, ChoiceList, VerticalStack } from '@shopify/polaris';
import TranslateLink from 'components/TranslateLink';
import { useTranslation } from 'react-i18next';
export default function MapLocation({ selected = 'no_location', onChange }) {
  const [t] = useTranslation();

  const modes = [
    {
      slug: 'no_location',
      label: 'No location',
    },
    {
      slug: 'final_destination',
      label: 'Order shipping address',
    },
    {
      slug: 'current_location',
      label: 'Current carrier location',
    },
  ];

  return (
    <Card roundedAbove="sm">
      <VerticalStack gap="5">
        <VerticalStack gap="2">
          <Text as="h4" variant="headingMd">
            {t('settings.tracking_page.map_location.card.title')}
          </Text>
          <Text as="span" color="subdued">
            {t('settings.tracking_page.map_location.description')}
          </Text>
        </VerticalStack>
        <div className="small-gap-list">
          <ChoiceList
            title=""
            choices={modes.map((type) => ({
              label: type.label,
              value: type.slug,
            }))}
            selected={selected === null ? 'no_location' : [selected]}
            onChange={([value]) => {
              onChange('map_location_mode', value);
            }}
          />
        </div>
        <Text as="span" color="subdued">
          <TranslateLink
            text={t('settings.tracking_page.map_location.card.description')}
          />
        </Text>
      </VerticalStack>
    </Card>
  );
}
