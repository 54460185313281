import { connect } from 'react-redux';
import SegmentAnalytics from './SegmentAnalytics';

function mapStateToProps(state) {
  return {
    shop: state.shop,
    base: state.base,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SegmentAnalytics);
