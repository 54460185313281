import {
  Card,
  Layout,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage,
  HorizontalStack,
} from '@shopify/polaris';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Step1 from './Step1';
import Step2 from './Step2';
import RushModal from 'components/custom/RushModal';
import {
  NewModernPageStyle,
  NewModernPageTitle,
  trackingPageActions,
} from 'pages/TrackingPageNew/trackingPageSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useGetProxyConfigQuery } from 'pages/TrackingPageNew/newTrackingPageApis';

function ModernWizard({ onClose }) {
  const title = useSelector(NewModernPageTitle);
  const selectedStyle = useSelector(NewModernPageStyle);
  const dispatch = useDispatch();
  const [t] = useTranslation();

  // next button enabled status for all steps in wizard
  const [nextButtonStatus, setNextButtonStatus] = useState({
    1: false,
    2: false,
  });
  const [currentStep, setCurrentStep] = useState(1);

  const configRes = useGetProxyConfigQuery();
  const { isLoading, data: { public_proxy_link = {} } = {} } = configRes;

  const handleNext = () => {
    setCurrentStep(currentStep + 1);
  };

  const handleBack = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleStyleChange = (style) => {
    dispatch(trackingPageActions.setNewModernPageStyle(style));
    setNextButtonStatus({ ...nextButtonStatus, 1: true });
    handleNext();
  };

  const handleTitleChange = (title) => {
    dispatch(trackingPageActions.setNewModernPageTitle(title));
  };

  const primaryAction = useMemo(() => {
    return {
      content: t('settings.os2_tracking_page.wizard.primary_action'),
      onAction: handleNext,
      disabled: !nextButtonStatus[currentStep],
    };
  }, [currentStep, handleNext]);

  const secondaryAction = useMemo(() => {
    if (currentStep === 1) {
      return [];
    }
    return [
      {
        content: t('settings.os2_tracking_page.wizard.secondary_action'),
        onAction: handleBack,
      },
    ];
  }, [currentStep, handleBack]);

  const renderStepComponent = () => {
    switch (currentStep) {
      case 1:
        return <Step1 onChange={handleStyleChange} selected={selectedStyle} />;
      case 2:
        return (
          <Step2
            onClose={onClose}
            handleBack={handleBack}
            title={title}
            onTitleChange={handleTitleChange}
            pagesPrefix={public_proxy_link}
          />
        );

      default:
        break;
    }
  };
  return (
    <RushModal
      title={t('modern_page.wizard.title')}
      open={true}
      onClose={onClose}
      primaryAction={currentStep !== 2 && primaryAction} // step 3 has its own actions for next and back
      secondaryActions={currentStep !== 2 && secondaryAction}
      large
    >
      {isLoading ? (
        <SkeletonPage>
          <Layout>
            <Layout.Section oneThird>
              <Card roundedAbove="sm">
                <HorizontalStack>
                  <SkeletonDisplayText size="small" />
                  <br />
                  <SkeletonBodyText />
                </HorizontalStack>
              </Card>
            </Layout.Section>
            <Layout.Section oneThird>
              <Card roundedAbove="sm">
                <HorizontalStack>
                  <SkeletonDisplayText size="small" />
                  <br />
                  <SkeletonBodyText />
                </HorizontalStack>
              </Card>
            </Layout.Section>
            <Layout.Section oneThird></Layout.Section>
          </Layout>
        </SkeletonPage>
      ) : (
        renderStepComponent()
      )}
    </RushModal>
  );
}

export default ModernWizard;
