import React, { useMemo } from 'react';
import {
  Box,
  Link,
  SkeletonBodyText,
  Text,
  VerticalStack,
} from '@shopify/polaris';
import { DB_DATE_FORMAT_STRING } from 'Constants';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useGetDestinationsQuery } from 'pages/Dashboard/DashboardApi';
import InfoTable from '../InfoTable';
import { STORE_URLS } from 'config/urls';
import { useParams } from 'react-router-dom';

export default function OrdersPerDestinations({ selectedDates = {} }) {
  const [t] = useTranslation();
  const params = useParams();

  const from = useMemo(() => {
    return moment(selectedDates.start).format(DB_DATE_FORMAT_STRING);
  }, [selectedDates.start]);
  const to = useMemo(() => {
    return moment(selectedDates.end).format(DB_DATE_FORMAT_STRING);
  }, [selectedDates.end]);

  const deliveryTimesResponse = useGetDestinationsQuery(
    { from, to },
    { skip: !(from || to) }
  );
  const {
    data: ordersPerDestination = [],
    isFetching,
    isSuccess,
  } = deliveryTimesResponse || {};

  const ordersPerDestinationData = useMemo(() => {
    return ordersPerDestination.map((destination) => {
      return [
        destination.country,
        <Link
          key={`/s/${params.uuid}${STORE_URLS.SHIPMENTS}?${destination.shipment_get_query}`}
          url={`/s/${params.uuid}${STORE_URLS.SHIPMENTS}?${destination.shipment_get_query}
        `}
        >
          {destination.total}
        </Link>,
        <Text
          key={destination.country}
          variant="bodyMd"
          as="span"
          color="subdued"
        >
          {' '}
          {destination.percentage}
        </Text>,
      ];
    });
  }, [ordersPerDestination]);

  return (
    <Box padding={'5'}>
      <VerticalStack gap="3">
        <Text variant="headingSm" as="span">
          {t('dashboard.analytics_sheet.orders_destination_title')}
        </Text>
        {isFetching || !isSuccess ? (
          <VerticalStack gap="0">
            <SkeletonBodyText />
          </VerticalStack>
        ) : (
          <InfoTable data={ordersPerDestinationData} />
        )}
      </VerticalStack>
      {!isFetching && ordersPerDestination?.length === 0 && (
        <div className="CustomEmptyState">
          <Text variant="bodyMd" as="span" color="subdued">
            {t('dashboard.analytics_sheet.no_row_message')}
          </Text>
        </div>
      )}
    </Box>
  );
}
