import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  Link,
  Spinner,
  TextField,
  Text,
  Box,
  HorizontalStack,
  VerticalStack,
  Button,
} from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import kebabCase from 'lodash/kebabCase';
import { useCreateTrackingPageMutation } from 'pages/TrackingPageNew/newTrackingPageApis';
import '../../styles.scss';
import RushBanner from 'components/custom/RushBanner';

function Step3({ wizardData, onClose, handleBack }) {
  const { pagesPrefix, pageTitle, pageHandle, style, layout } = wizardData;
  const [t] = useTranslation();
  const [title, setTitle] = useState('');
  const [value, setValue] = useState('');
  const [showError, setShowError] = useState(false);
  const [addPage, addPageResponse] = useCreateTrackingPageMutation();
  const { data = {}, isSuccess, isLoading } = addPageResponse;

  useEffect(() => {
    pageTitle && setTitle(pageTitle);
    pageHandle && setValue(pageHandle);
  }, [pageTitle, pageHandle]);

  const handleAddPage = () => {
    if (value !== '' && title !== '') {
      addPage({ handle: value, title, style, layout });
    } else {
      setShowError(true);
    }
  };

  useEffect(() => {
    title && setValue(kebabCase(title));
  }, [title]);

  const handleCustomize = () => {
    if (data && isSuccess) {
      window.open(data?.editor_url);
      onClose();
    }
  };

  const primaryAction = useMemo(() => {
    return isSuccess || isLoading
      ? {
          content: t('settings.os2_tracking_page.wizard.customize'),
          onClick: handleCustomize,
          disabled: isLoading,
        }
      : {
          content: t('settings.os2_tracking_page.wizard.step3.add_page'),
          onClick: handleAddPage,
        };
  }, [handleAddPage, isSuccess, isLoading, handleCustomize]);

  const secondaryAction = useMemo(() => {
    return isSuccess || isLoading
      ? undefined
      : {
          content: t('settings.os2_tracking_page.wizard.secondary_action'),
          onClick: handleBack,
        };
  }, [handleBack, isSuccess, isLoading]);

  return (
    <div className="step3-container">
      <Card roundedAbove="sm" padding={0}>
        {isSuccess ? (
          ''
        ) : (
          <Box padding={'5'}>
            <Text variant="headingMd" as="h2">
              {t('settings.os2_tracking_page.wizard.step3.title')}
            </Text>
          </Box>
        )}
        {!isSuccess && !isLoading && (
          <>
            <Box padding={'5'} paddingBlockStart={'0'}>
              <VerticalStack gap={'5'}>
                <RushBanner bannerKey={'OS2-step3'} status="warning">
                  <p>
                    {t(
                      'settings.os2_tracking_page.wizard.step3.override_message'
                    )}
                  </p>
                </RushBanner>
                <TextField
                  autoComplete={'off'}
                  label={t(
                    'settings.os2_tracking_page.wizard.step3.name_box.title'
                  )}
                  value={title}
                  onChange={setTitle}
                  error={
                    showError &&
                    !title &&
                    t(
                      'settings.os2_tracking_page.wizard.step3.page_title_required'
                    )
                  }
                  autoFocus
                />
                <TextField
                  autoComplete={'off'}
                  label={t(
                    'settings.os2_tracking_page.wizard.step3.handle_box.title'
                  )}
                  value={value}
                  onChange={setValue}
                  prefix={pagesPrefix}
                  error={
                    showError &&
                    !value &&
                    t(
                      'settings.os2_tracking_page.wizard.step3.page_handle_required'
                    )
                  }
                />
              </VerticalStack>
            </Box>
          </>
        )}
        {isSuccess && (
          <Box padding="5">
            <HorizontalStack gap={'1'}>
              <p>{t('settings.os2_tracking_page.wizard.step3.pageUrl')}</p>
              <Link url={data.link} external>
                {data.link}
              </Link>
            </HorizontalStack>
          </Box>
        )}
        {isLoading && (
          <Box padding="5">
            <Text variant="bodyMd" as="span" color={'subdued'}>
              <HorizontalStack>
                <Spinner
                  accessibilityLabel={t(
                    'settings.os2_tracking_page.wizard.step3.installation_inprogress'
                  )}
                  size={'small'}
                ></Spinner>
                <Text variant="bodyMd" as="span" color={'subdued'}>
                  {t(
                    'settings.os2_tracking_page.wizard.step3.installation_inprogress'
                  )}
                </Text>
                ;
              </HorizontalStack>
            </Text>
          </Box>
        )}
        <Box
          padding={'4'}
          borderBlockStartWidth="1"
          borderColor="border-subdued"
        >
          <HorizontalStack align="end" gap={'2'}>
            {secondaryAction ? (
              <Button onClick={secondaryAction.onClick}>
                {secondaryAction.content}
              </Button>
            ) : (
              ''
            )}
            <Button primary onClick={primaryAction.onClick}>
              {primaryAction.content}
            </Button>
          </HorizontalStack>
        </Box>
      </Card>
    </div>
  );
}

Step3.propTypes = {
  onChange: PropTypes.func.isRequired,
  showToast: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  wizardData: PropTypes.object.isRequired,
};

export default Step3;
