import React from 'react';
import {
  SkeletonPage,
  Layout,
  SkeletonBodyText,
  SkeletonDisplayText,
  Text,
  Box,
  Card,
  VerticalStack,
  Divider,
} from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import './style.scss';

function SkeletonDashboardPage() {
  const [t] = useTranslation();
  return (
    <SkeletonPage titleHidden fullWidth={false} narrowWidth={false}>
      <Layout>
        <Layout.Section oneHalf>
          <Layout.Section>
            <Text variant="headingMd" as="h2">
              {t('dashboard.need_action.card.title')}
            </Text>
          </Layout.Section>
          <Layout.Section>
            <Card roundedAbove="sm" padding={0}>
              <Box
                padding={'5'}
                borderBlockEndWidth="1"
                borderColor="border-subdued"
              >
                <SkeletonBodyText lines={2} />
              </Box>
              <Box
                padding={'5'}
                borderBlockEndWidth="1"
                borderColor="border-subdued"
              >
                <SkeletonBodyText lines={2} />
              </Box>
              <Box
                padding={'5'}
                borderBlockEndWidth="1"
                borderColor="border-subdued"
              >
                <SkeletonBodyText lines={2} />
              </Box>
              <Box
                padding={'5'}
                borderBlockEndWidth="1"
                borderColor="border-subdued"
              >
                <SkeletonBodyText lines={2} />
              </Box>
              <Box
                padding={'5'}
                borderBlockEndWidth="1"
                borderColor="border-subdued"
              >
                <SkeletonBodyText lines={2} />
              </Box>
              <Box
                padding={'5'}
                borderBlockEndWidth="1"
                borderColor="border-subdued"
              >
                <SkeletonBodyText lines={2} />
              </Box>
            </Card>
          </Layout.Section>
        </Layout.Section>
        <Layout.Section oneHalf>
          <Layout.Section>
            <Text variant="headingSm" as="span">
              {t('dashboard.analytics_sheet.title')}
            </Text>
          </Layout.Section>
          <Layout.Section>
            <Card roundedAbove="sm" padding={0}>
              <Box padding={'5'}>
                <SkeletonDisplayText />
              </Box>
              <Divider />
              <Box padding={'5'}>
                <VerticalStack gap={'3'}>
                  <Text variant="headingSm" as="span">
                    {t('dashboard.analytics_sheet.revenue_statistics_title')}
                  </Text>
                  <Box>
                    <SkeletonBodyText />
                  </Box>
                </VerticalStack>
              </Box>
              <Divider />

              <Box padding={'5'}>
                <VerticalStack gap={'3'}>
                  <Text variant="headingSm" as="span">
                    {t('dashboard.analytics_sheet.delivery_times_title')}
                  </Text>
                  <Box>
                    <SkeletonBodyText />
                  </Box>
                </VerticalStack>
              </Box>
              <Divider />

              <Box padding={'5'}>
                <VerticalStack gap={'3'}>
                  <Text variant="headingSm" as="span">
                    {t('dashboard.analytics_sheet.shipment_status_title')}
                  </Text>
                  <Box>
                    <SkeletonBodyText />
                  </Box>
                </VerticalStack>
              </Box>
              <Divider />

              <Box padding={'5'}>
                <VerticalStack gap={'3'}>
                  <Text variant="headingSm" as="span">
                    {t('dashboard.analytics_sheet.orders_destination_title')}
                  </Text>
                  <Box>
                    <SkeletonBodyText />
                  </Box>
                </VerticalStack>
              </Box>
              <Divider />

              <Box padding={'5'}>
                <VerticalStack gap={'3'}>
                  <Text variant="headingSm" as="span">
                    {t('dashboard.analytics_sheet.shipments_per_carrier')}
                  </Text>
                  <Box>
                    <SkeletonBodyText />
                  </Box>
                </VerticalStack>
              </Box>
            </Card>
          </Layout.Section>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );
}
export default SkeletonDashboardPage;
