import React, { useEffect, useState } from 'react';
import { Page, Layout, HorizontalStack, Button } from '@shopify/polaris';
import RushLogo from 'images/logo-complete.svg';
import { FRONTEND_URL } from 'config';
import RushLoading from 'components/custom/RushLoader';
import useWindowDimensions from 'hooks/useWindowDimensions';

export default function RushSplash() {
  const { height } = useWindowDimensions();

  const [feedUrl, setFeedUrl] = useState(null);
  const [feedError, setFeedError] = useState(null);

  const [iframeLoaded, setIframeLoaded] = useState(false);

  useEffect(() => {
    fetch('https://assets.rush.app/configs/splash-screen-feed.json')
      .then((data) => data.json())
      .then(({ data }) => {
        setFeedUrl(data[getFeedIndex(data.length)]);
        setFeedError(null);
      })
      .catch((err) => {
        console.log(`Error: ${err}`);
        setFeedError(err);
        setFeedUrl(null);
      });
  }, []);

  const handleContinuePress = () => {
    window.top.location.href = FRONTEND_URL;
  };

  return (
    <Page narrowWidth>
      <Layout>
        <Layout.Section>
          <div style={{ marginLeft: 10, marginRight: 10 }}>
            <HorizontalStack align="space-between" blockAlign="center">
              <img src={RushLogo} alt={'logo'} width={'150'} />

              {feedUrl && (
                <Button primary loading={false} onClick={handleContinuePress}>
                  Continue with Rush
                </Button>
              )}
            </HorizontalStack>
          </div>
        </Layout.Section>

        <Layout.Section>
          {!iframeLoaded && !feedError && <RushLoading />}
          {feedUrl && (
            <iframe
              src={feedUrl}
              width={'100%'}
              height={iframeLoaded ? height - 95 : 0}
              style={{
                borderStyle: 'none',
                display: iframeLoaded ? 'block' : 'none',
              }}
              allow={'fullscreen'}
              onLoad={() => setIframeLoaded(true)}
              onError={(err) => setFeedError(err)}
            />
          )}
        </Layout.Section>
      </Layout>
    </Page>
  );
}

function getFeedIndex(totalFeeds) {
  let index = localStorage.getItem('feed_index');

  if (index) {
    index++;
    if (index >= totalFeeds) {
      index = 0;
    }
  } else {
    index = 0;
  }

  localStorage.setItem('feed_index', index);
  return index;
}
