import React, { useState, useCallback, useEffect, useMemo } from 'react';
import {
  Autocomplete,
  Form,
  FormLayout,
  Icon,
  TextField,
  Modal,
} from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import TranslateLink from 'components/TranslateLink';
import { SearchMinor } from '@shopify/polaris-icons';
import { isUUID } from 'Utils/utils';
import { useAllCarriersQuery } from 'redux/store/commonBaseApis';
import { useUpdateCarrier3Mutation } from '../shipmentsApi';
import { baseActions } from 'redux/store/baseSlice';
import { useDispatch } from 'react-redux';
import RushModal from 'components/custom/RushModal';
export default function UpdateCarrierModal({
  onClose,
  initializer,
  shipmentIds,
  shipmentTrackNumber,
  shipmentCarrier,
}) {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const [selectedCarrierOptions, setSelectedCarrierOptions] = useState([]);
  const [inputCarrierValue, setInputCarrierValue] = useState('');
  const [inputCarrierError, setInputCarrierError] = useState(null);
  const [carrierOptions, setCarrierOptions] = useState([]);
  const [newTrackingNumberText, setNewTrackingNumberText] = useState(undefined);
  const [newTrackingNumberError, setNewTrackingNumberError] = useState(null);

  const [updateCarrier, updateCarrierResponse] = useUpdateCarrier3Mutation();

  const {
    data: updateCarrierData,
    isLoading: isUpdatingCarrier,
    isSuccess: isCarrierUpdated,
  } = updateCarrierResponse;

  const showToast = useCallback((message) => {
    dispatch(
      baseActions.setToastMessage({
        message: message,
        type: 'success',
      })
    );
    onClose({ isUpdated: true });
  }, []);
  useEffect(() => {
    if (!isUpdatingCarrier && isCarrierUpdated) {
      showToast(updateCarrierData.message);
    }
  }, [updateCarrierData, isUpdatingCarrier, isCarrierUpdated]);

  const {
    data: carriers = [],
    isFetching: carrierListLoading,
    isSuccess: carrierListLoaded,
  } = useAllCarriersQuery();

  const carriersList = useMemo(() => {
    if (carriers && !carrierListLoading && carrierListLoaded) {
      return carriers.map((carrier) => ({
        value: carrier.uuid,
        label: carrier.title,
        slug: carrier.slug,
      }));
    }
    return [];
  }, [carriers, carrierListLoading, carrierListLoaded]);

  useEffect(() => {
    if (carrierListLoaded && shipmentCarrier) {
      const selectedCarrier = carriersList.find(
        ({ value }) => value === shipmentCarrier
      )?.label;

      setSelectedCarrierOptions([shipmentCarrier]);
      setInputCarrierValue(selectedCarrier);
    }
    setInputCarrierError(null);
    setNewTrackingNumberText(shipmentTrackNumber);
    setNewTrackingNumberError(null);
  }, [shipmentTrackNumber, carriersList, carrierListLoaded, shipmentCarrier]);

  useEffect(() => {
    if (carrierListLoaded && !carrierListLoading) {
      setCarrierOptions(carriersList);
    }
  }, [carriersList, carrierListLoaded, carrierListLoading]);

  const saveSelectedCarrierOptions = useCallback(
    (selected) => {
      const selectedCarrierLabel = (
        carriersList.find((carrier) => carrier.value === selected[0]) || {}
      ).label;
      setInputCarrierError(null);
      setSelectedCarrierOptions(selected);
      setInputCarrierValue(selectedCarrierLabel);
    },
    [carriersList]
  );

  const updateCarrierText = useCallback(
    (value) => {
      setInputCarrierError(null);
      setInputCarrierValue(value);
      if (value === '') {
        setCarrierOptions(carriersList);
        return;
      }
      const filterRegex = new RegExp(value, 'i');
      const resultOptions = carriersList.filter((option) =>
        option.label.match(filterRegex)
      );
      setCarrierOptions(resultOptions);
    },
    [carriersList]
  );

  const carrierTextField = (
    <Autocomplete.TextField
      autoComplete="off"
      onChange={updateCarrierText}
      onClearButtonClick={() => updateCarrierText()}
      label={t(`${initializer}.change_carrier.carrier_search_input_label`)}
      value={inputCarrierValue}
      prefix={<Icon source={SearchMinor} color="inkLighter" />}
      type="search"
      placeholder={t(
        `${initializer}.change_carrier.carrier_search_input_label`
      )}
      error={inputCarrierError}
      id="input-chang-carrier-modal-carrier"
    />
  );

  const handleNewTrackingNumberTextChange = useCallback((value) => {
    setNewTrackingNumberText(value);
    setNewTrackingNumberError(null);
  }, []);
  const handleNewTrackingNumberTextBlur = useCallback(() => {
    if (!newTrackingNumberText) {
      setNewTrackingNumberError(
        t(`${initializer}.change_carrier.tracking_number_error`)
      );
    } else {
      setNewTrackingNumberError(null);
    }
  }, [newTrackingNumberText, t, initializer]);

  const updateTrackingInformation = (e) => {
    e.preventDefault();

    if (
      _.isUndefined(inputCarrierValue) ||
      _.isEmpty(inputCarrierValue) ||
      _.isUndefined(selectedCarrierOptions[0]) ||
      !isUUID(selectedCarrierOptions[0])
    ) {
      setInputCarrierError(
        t(`${initializer}.change_carrier.selected_carrier_error`)
      );
      return;
    }
    if (!newTrackingNumberText && initializer === 'shipment_detail') {
      setNewTrackingNumberError(
        t(`${initializer}.change_carrier.tracking_number_error`)
      );
      return;
    }
    const toSave = shipmentIds.map((shipment_uuid) => {
      return {
        shipment_uuid,
        carrier_uuid: selectedCarrierOptions[0],
        tracking_number: newTrackingNumberText,
      };
    });

    updateCarrier(toSave);
  };

  return (
    <RushModal
      open={true}
      onClose={onClose}
      title={t(`${initializer}.change_carrier.modal_title`)}
      primaryAction={{
        content: t(`${initializer}.change_carrier.modal_primary_action_label`),
        onClick: updateTrackingInformation,
        disabled: carrierListLoading,
        loading: isUpdatingCarrier,
      }}
    >
      <Modal.Section>
        <Form>
          <FormLayout>
            <div>
              <TranslateLink
                text={t(`${initializer}.change_carrier.description`)}
              />
            </div>
            <Autocomplete
              // allowMultiple
              options={carrierOptions}
              selected={selectedCarrierOptions}
              textField={carrierTextField}
              onSelect={saveSelectedCarrierOptions}
              listTitle={t(
                `${initializer}.change_carrier.carrier_search_input_label`
              )}
              emptyState={''}
              disabled={carrierListLoading}
              loading={carrierListLoading}
            />
            {initializer === 'shipment_detail' && (
              <TextField
                autoComplete="off"
                type="text"
                value={newTrackingNumberText}
                placeholder={t(
                  `${initializer}.change_carrier.tracking_number_placeholder`
                )}
                label={t(`${initializer}.change_carrier.tracking_number_label`)}
                onChange={handleNewTrackingNumberTextChange}
                onBlur={handleNewTrackingNumberTextBlur}
                error={newTrackingNumberError}
                id={`input-change-carrier-modal-tracking-number`}
              />
            )}
          </FormLayout>
        </Form>
      </Modal.Section>
    </RushModal>
  );
}
