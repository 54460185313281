import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { DatePicker, Icon, Popover, TextField } from '@shopify/polaris';

import moment from 'moment';
import PropTypes from 'prop-types';
import { CalendarMajor } from '@shopify/polaris-icons';
import { DB_DATE_FORMAT_STRING } from 'Constants';
function DatePickerField({ value, onChange, label, alignment = 'left' }) {
  const [errorMessage, setErrorMessage] = useState('');
  const [inputValue, setInputValue] = useState('');

  const [{ month, year }, setDate] = useState({ month: 10, year: 2022 });
  const [active, setActive] = useState(false);
  const [selectedDate, setSelectedDate] = useState({
    start: moment(),
    end: moment(),
  });

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  useEffect(() => {
    let dateRange = {};
    dateRange = {
      start: value ? moment(value) : moment(),
      end: value ? moment(value) : moment(),
    };

    setSelectedDate(dateRange);
    setDate({
      month: Number(dateRange.start.format('M')) - 1,
      year: Number(dateRange.start.format('Y')),
    });
  }, [value]);

  const handleChange = useCallback(() => setActive(!active), [active]);

  const handleMonthChange = (month, year) => {
    setDate({ month, year });
    window.event.stopPropagation();
  };
  const isValidDate = (dateString) => {
    var regEx = /^\d{4}-\d{2}-\d{2}$/;
    if (!dateString.match(regEx)) return false; // Invalid format
    var d = new Date(dateString);
    var dNum = d.getTime();
    if (!dNum && dNum !== 0) return false; // NaN value, Invalid date
    return d.toISOString().slice(0, 10) === dateString;
  };
  const handleBlur = () => {
    if (inputValue) {
      if (!isValidDate(inputValue)) {
        setErrorMessage(`Much match ${DB_DATE_FORMAT_STRING} format`);
      } else {
        onChange(inputValue);
        setErrorMessage('');
      }
    }
  };
  const onInputChange = (value) => {
    setInputValue(value);
    setErrorMessage('');
  };
  const activator = useMemo(() => {
    return (
      <div onClick={handleChange} style={{ width: '100%' }}>
        <TextField
          role="combobox"
          label={label}
          prefix={<Icon source={CalendarMajor} />}
          value={inputValue}
          placeholder="YYYY-MM-DD"
          autoComplete="off"
          onBlur={handleBlur}
          onChange={onInputChange}
          error={errorMessage}
        />
      </div>
    );
  }, [selectedDate, handleChange, handleBlur, setInputValue]);

  const onDateChange = ({ start }) => {
    const dateStr = moment(start).format(DB_DATE_FORMAT_STRING);
    onChange(dateStr);
    setInputValue(dateStr);
    setErrorMessage('');
  };

  return (
    <Popover
      key={'date-picker-button-key'}
      id={'date-picker-button-key'}
      iFrameName={'date-picker-button-key'}
      title={label}
      activator={activator}
      active={active}
      onClose={handleChange}
      preferredAlignment={alignment}
    >
      <Popover.Section>
        <DatePicker
          month={month}
          year={year}
          onChange={onDateChange}
          selected={{
            start: new Date(selectedDate.start.format('L')),
            end: new Date(selectedDate.end.format('L')),
          }}
          onMonthChange={handleMonthChange}
          allowRange={false}
        />
      </Popover.Section>
    </Popover>
  );
}

DatePickerField.propTypes = {
  value: PropTypes.string | PropTypes.object,
};
export default DatePickerField;
