import {
  Card,
  VerticalStack,
  Text,
  SkeletonBodyText,
  Box,
} from '@shopify/polaris';
import React from 'react';
import { useTranslation } from 'react-i18next';

function UpdateFrequency(props) {
  const { t } = useTranslation();
  const { frequencyRate } = props;
  return (
    <Card roundedAbove="sm">
      {frequencyRate ? (
        <VerticalStack gap="2">
          <Text as="h4" variant="headingMd">
            {`${t(
              'shipment_settings_new.update_frequency.title'
            )} - ${frequencyRate}h`}
          </Text>

          <Text as="span" variant="bodyMd" color="subdued">
            {t('shipment_settings_new.update_frequency.description')}
          </Text>
        </VerticalStack>
      ) : (
        <Box>
          <SkeletonBodyText />
        </Box>
      )}
    </Card>
  );
}

export default UpdateFrequency;
