import React from 'react';
import {
  SkeletonPage,
  Layout,
  Card,
  SkeletonBodyText,
  VerticalStack,
  SkeletonDisplayText,
  Box,
} from '@shopify/polaris';

function SkeletonMaskingPage() {
  return (
    <SkeletonPage primaryAction>
      <Layout>
        <Layout.Section>
          <Card roundedAbove="sm">
            <VerticalStack gap={'2'}>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText />
            </VerticalStack>
          </Card>
          <br></br>
          <Card roundedAbove="sm">
            <VerticalStack gap={'2'}>
              <VerticalStack gap={'2'}>
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText />
              </VerticalStack>
              <VerticalStack gap={'2'}>
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText />
              </VerticalStack>
              <VerticalStack gap={'2'}>
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText />
              </VerticalStack>
            </VerticalStack>
          </Card>
        </Layout.Section>
        <Layout.Section secondary>
          <Card roundedAbove="sm">
            <Box>
              <VerticalStack gap={'2'}>
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText lines={2} />
              </VerticalStack>
            </Box>
          </Card>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );
}
export default SkeletonMaskingPage;
