import React from 'react';

import { Card, VerticalStack, Button, Text, Box } from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import TranslateLink from 'components/TranslateLink';
import isEmpty from 'lodash/isEmpty';
import trim from 'lodash/trim';

export default function KlaviyoActions({
  sendAllEventsTestKlaviyo,
  publicApiKey,
  setPublicApiKeyError,
  selectedLanguage,
  loading,
}) {
  const [t] = useTranslation();

  const sendTestKlaviyoNotification = () => {
    if (!publicApiKey || isEmpty(trim(publicApiKey))) {
      setPublicApiKeyError(t('klaviyo.modal.public_api_key_error_text'));
      return;
    }
    setPublicApiKeyError(null);
    sendAllEventsTestKlaviyo({
      public_api_key: trim(publicApiKey),
      language: selectedLanguage,
    });
  };
  const text = t('settings.klaviyo.actions.section.description');

  return (
    <Card roundedAbove="sm" className="klaviyoAction">
      <VerticalStack gap="4">
        <Text variant="headingMd" as="h2">
          {t('settings.klaviyo.actions.section.title')}
        </Text>
        <VerticalStack gap="3">
          <TranslateLink text={text} />
          <Box>
            <Button
              onClick={sendTestKlaviyoNotification}
              class="alignRight"
              outline
              loading={loading}
            >
              {t('settings.klaviyo.actions.section.button')}
            </Button>
          </Box>
        </VerticalStack>
      </VerticalStack>
    </Card>
  );
}
