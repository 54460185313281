import {
  HorizontalStack,
  IndexFiltersMode,
  IndexTable,
  Text,
  Box,
  Button,
} from '@shopify/polaris';
import { ViewMinor, HideMinor } from '@shopify/polaris-icons';
import React, { useMemo } from 'react';
import './styles.scss';
function CustomIndexTable({ tableProps = {}, changeVisibility, mode }) {
  const {
    columns = [],
    data = [],
    selectedResources = [],
    allResourcesSelected,
    handleSelectionChange,
    promotedBulkActions = [],
    onRowClick,
    selectable = true,
  } = tableProps;

  const rowMarkup = data.map((row, index) => {
    return (
      <IndexTable.Row
        id={row.uuid}
        key={row.uuid + index}
        selected={selectedResources.includes(row.uuid)}
        position={index}
        onClick={() => {
          onRowClick(row.uuid, event?.ctrlKey || event?.metaKey);
        }}
      >
        {columns.map((column, colIndex) => {
          return (
            <IndexTable.Cell key={row.uuid + row[column.value] + colIndex}>
              {row[column.value] ? (
                row[column.value]
              ) : (
                <div className="empty-cell">
                  <div className="dash"></div>
                </div>
              )}
            </IndexTable.Cell>
          );
        })}
      </IndexTable.Row>
    );
  });

  const renderColumns = useMemo(() => {
    if (mode === IndexFiltersMode.EditingColumns) {
      return columns.map((column) => {
        return {
          title: (
            <Box width="100%">
              <HorizontalStack
                gap={'4'}
                align="space-between"
                wrap={false}
                blockAlign="center"
              >
                <Text variant="bodySm" color="subdued" fontWeight="semibold">
                  {column.title}
                </Text>
                <Button
                  size="small"
                  disabled={column.disabled}
                  plain
                  onClick={() => changeVisibility(column, !column.selected)}
                  icon={!column.selected ? HideMinor : ViewMinor}
                ></Button>
              </HorizontalStack>
            </Box>
          ),
          id: column.title,
        };
      });
    } else {
      return columns;
    }
  }, [columns, mode]);

  return (
    <div className={`custom-index-table ${selectable ? '' : 'un_selectable'}`}>
      <IndexTable
        resourceName={tableProps.resourceName}
        itemCount={data.length}
        selectedItemsCount={
          allResourcesSelected ? 'All' : selectedResources.length
        }
        onSelectionChange={handleSelectionChange}
        headings={renderColumns}
        promotedBulkActions={promotedBulkActions}
        selectable={true}
      >
        {rowMarkup}
      </IndexTable>
    </div>
  );
}

export default CustomIndexTable;
