import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Dropdown from 'components/Dropdown';
import {
  Badge,
  Layout,
  Card,
  Text,
  PageActions,
  VerticalStack,
} from '@shopify/polaris';
import isEqual from 'lodash/isEqual';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import OverrideCarriersCard from './OverrideCarriersCard';
import OverrideSetupCard from './OverrideSetupCard';
import SkeletonMaskingPage from './SkeletonMaskingPage';
import {
  useGetCarrierMaskingQuery,
  useAddCarrierMaskingMutation,
  useUpdateCarrierMaskingMutation,
  useDeleteCarrierMaskingMutation,
} from '../../maskingApi';
import { validateForm } from '../utils';
import TranslateLink from 'components/TranslateLink';
import RushPage from 'components/custom/RushPage';
import { baseActions } from 'redux/store/baseSlice';
import { useDispatch } from 'react-redux';
import RushSaveBar from 'components/custom/RushSaveBar';
import RushFooter from 'components/custom/RushFooter';
function CarrierMaskingPage() {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const params = useParams();
  const [formValidations, steFormValidations] = useState({
    override_carrier: true,
    override_name: true,
    override_logo: true,
    override_website: true,
    override_tracking_link: true,
    override_phone: true,
  });

  const [currCarrierRule, setCurrCarrierRule] = useState({
    index: 0,
    is_active: true,
    target_carriers_all: true,
    target_carriers_uuid: [],
    completely_hide_carrier: true,
    override_name_option: 0,
    override_name_value: null,
    override_logo_option: 0,
    override_logo_value: null,
    override_website_option: 0,
    override_website_value: null,
    override_tracking_link_option: 0,
    override_tracking_link_value: null,
    override_phone_option: 0,
    override_phone_value: null,
  });

  const maskingsResponse = useGetCarrierMaskingQuery();
  const [addCarrierMasking, addResponse] = useAddCarrierMaskingMutation();
  const [updateCarrierMasking, updateResponse] =
    useUpdateCarrierMaskingMutation();
  const [deleteCarrierMasking, deleteResponse] =
    useDeleteCarrierMaskingMutation();

  const {
    isLoading: addingCarrier,
    isSuccess: addSuccess,
    data: addData,
  } = addResponse;
  const { isLoading: updatingCarrier } = updateResponse;
  const { isLoading: deletingCarrier, isSuccess: deleteSuccess } =
    deleteResponse;

  useEffect(() => {
    addSuccess &&
      dispatch(
        baseActions.setToastMessage({
          message: t('carrier-maskings.added-toast'),
          type: 'success',
        })
      );
  }, [addSuccess]);

  const gotoList = useCallback(() => {
    try {
      navigate(location.pathname.split(`/${params.maskingId}`)[0]);
    } catch {
      navigate(-1);
    }
  }, [location, params]);

  useEffect(() => {
    if (deleteSuccess) {
      dispatch(
        baseActions.setToastMessage({
          message: t('carrier-maskings.removed-toast'),
          type: 'success',
        })
      );
      setTimeout(gotoList, 800);
    }
  }, [deleteSuccess, gotoList]);

  const duplicateCase = useMemo(() => {
    try {
      return location.pathname.endsWith('duplicate');
    } catch {
      return false;
    }
  }, [params]);

  const addCase = useMemo(() => {
    return params.maskingId === 'add';
  }, [params]);

  useEffect(() => {
    if (duplicateCase) {
      // eslint-disable-next-line no-unused-vars
      const { uuid: _, ...data } = currentMasking;
      setCurrCarrierRule({ ...data, index: 0 });
    }
  }, [duplicateCase]);

  const {
    data: maskings,
    isLoading: isMaskingsLoading,
    isSuccess: isMaskingsSuccess,
    isFetching: isMaskingsFetching,
  } = maskingsResponse || {};

  useEffect(() => {
    if (
      addSuccess &&
      !isMaskingsFetching &&
      !isMaskingsLoading &&
      !addingCarrier
    ) {
      if (duplicateCase) {
        try {
          const routeLink =
            location.pathname.split(`/${params.maskingId}`)[0] +
            '/' +
            addData.uuid;
          navigate(routeLink);
        } catch {
          navigate(-1);
        }
      } else {
        const newPath = window.location.pathname.replace('add', addData.uuid);
        navigate(newPath);
      }
      addResponse.reset();
    }
  }, [
    addSuccess,
    addData,
    duplicateCase,
    isMaskingsFetching,
    isMaskingsLoading,
    addingCarrier,
  ]);

  const currentMasking = useMemo(() => {
    if (
      isMaskingsSuccess &&
      !isMaskingsLoading &&
      Array.isArray(maskings) &&
      maskings.length
    ) {
      return maskings.find((m) => m.uuid === params.maskingId);
    }
  }, [maskings, params.maskingId, isMaskingsSuccess, isMaskingsLoading]);

  useEffect(() => {
    if (currentMasking) {
      setCurrCarrierRule({ ...currentMasking });
    }
  }, [currentMasking]);

  const isDirtyState = useMemo(() => {
    try {
      if (addCase || duplicateCase) {
        return true;
      }
      // eslint-disable-next-line no-unused-vars
      const { created_at: a, updated_at: b, ...currMasking } = currentMasking;
      // eslint-disable-next-line no-unused-vars
      const { created_at, updated_at, ...carrRule } = currCarrierRule;

      return !isEqual(currMasking, carrRule);
    } catch {
      return false;
    }
  }, [currentMasking, currCarrierRule, addCase, duplicateCase]);

  const nextUUID = useMemo(() => {
    if (Array.isArray(maskings) && maskings.length) {
      const currMaskingIndex = maskings.findIndex(
        (m) => m.uuid === params.maskingId
      );
      if (currMaskingIndex + 1 < maskings.length) {
        return maskings[currMaskingIndex + 1].uuid;
      }
      return null;
    }
  }, [maskings, params]);

  const PrevUUID = useMemo(() => {
    if (Array.isArray(maskings) && maskings.length) {
      const currMaskingIndex = maskings.findIndex(
        (m) => m.uuid === params.maskingId
      );
      if (currMaskingIndex - 1 >= 0) {
        return maskings[currMaskingIndex - 1].uuid;
      }
      return null;
    }
  }, [maskings, params]);

  const handleChoiceChange = useCallback(
    (key, value) => {
      // if target carriers are slected then turn off target_carriers_all also
      if (key === 'target_carriers_uuid') {
        setCurrCarrierRule({
          ...currCarrierRule,
          target_carriers_all: false,
          [key]: value,
        });
      } else {
        setCurrCarrierRule({ ...currCarrierRule, [key]: value });
      }
    },
    [setCurrCarrierRule, currCarrierRule]
  );

  const handleDiscard = () => {
    setCurrCarrierRule({ ...currentMasking });
  };

  const isValidForm = () => {
    const { isValid, validations } = validateForm({
      formValidations,
      rule: currCarrierRule,
    });
    steFormValidations({ ...validations });
    return isValid;
  };

  const handleSave = () => {
    if (isValidForm()) {
      if (addCase || duplicateCase) {
        addCarrierMasking({ ...currCarrierRule });
      } else {
        const { uuid: id, ...data } = currCarrierRule;
        updateCarrierMasking({ id, data });
      }
    }
  };

  const handleDelete = () => {
    const { uuid } = currCarrierRule;
    deleteCarrierMasking(uuid);
  };

  return isMaskingsLoading ? (
    <SkeletonMaskingPage />
  ) : (
    <RushPage
      backAction={{
        content: t('carrier-maskings.content'),
        onAction: gotoList,
      }}
      title={
        addCase || duplicateCase
          ? t('carrier-maskings.new-rule')
          : t('carrier-maskings.rule')
      }
      titleMetadata={
        currCarrierRule.is_active ? (
          <Badge status="success">{t('carrier-maskings.active')}</Badge>
        ) : (
          <Badge>{t('carrier-maskings.inactive')}</Badge>
        )
      }
      compactTitle
      secondaryActions={
        addCase || duplicateCase
          ? []
          : [
              {
                content: t('carrier-maskings.duplicate'),
                accessibilityLabel: 'Secondary action label',
                onAction: () => navigate(`duplicate`),
              },
              // {
              //   content: t('carrier-maskings.preview'),
              // },
            ]
      }
      pagination={
        addCase || duplicateCase
          ? null
          : {
              hasNext: !!nextUUID,
              onNext: () => {
                const newPath = window.location.pathname.replace(
                  params.maskingId,
                  nextUUID
                );

                navigate(newPath);
              },
              hasPrevious: !!PrevUUID,
              onPrevious: () => {
                const newPath = window.location.pathname.replace(
                  params.maskingId,
                  PrevUUID
                );
                navigate(newPath);
              },
            }
      }
    >
      <Layout>
        <Layout.Section>
          <VerticalStack gap={'5'}>
            <OverrideCarriersCard
              currCarrierRule={{ ...currCarrierRule }}
              handleChoiceChange={handleChoiceChange}
              formValidations={formValidations}
            />
            <OverrideSetupCard
              currCarrierRule={{ ...currCarrierRule }}
              handleChoiceChange={handleChoiceChange}
              formValidations={formValidations}
            />
          </VerticalStack>
        </Layout.Section>
        <Layout.Section secondary>
          <Card roundedAbove="sm">
            <Text variant="headingMd" as="h2">
              {t('carrier-maskings.status')}
            </Text>
            <Dropdown
              options={[
                { value: true, label: t('carrier-maskings.active') },
                { value: false, label: t('carrier-maskings.inactive') },
              ]}
              selected={currCarrierRule.is_active}
              onChange={(value) => {
                handleChoiceChange('is_active', value);
              }}
              title={t('carrier-maskings.status')}
            />
          </Card>
        </Layout.Section>
      </Layout>
      <br />
      {!(addCase || duplicateCase) && (
        <PageActions
          secondaryActions={[
            {
              content: t('carrier-maskings.delete'),
              destructive: true,
              onAction: handleDelete,
              outline: true,
              loading: deletingCarrier,
            },
          ]}
        />
      )}
      {isDirtyState && (
        <RushSaveBar
          message={t('carrier-maskings.unsaved-changes')}
          saveAction={{
            content:
              addCase || duplicateCase
                ? t('carrier-maskings.create')
                : t('carrier-maskings.save'),
            onAction: handleSave,
            loading: addingCarrier || updatingCarrier || isMaskingsFetching,
          }}
          discardAction={
            addCase ||
            duplicateCase ||
            isMaskingsFetching ||
            addingCarrier ||
            updatingCarrier
              ? {}
              : {
                  onAction: handleDiscard,
                  content: t('common.discard'),
                }
          }
        />
      )}
      <Layout.Section>
        <RushFooter>
          <TranslateLink text={t('footer_help.dashboard')} />
        </RushFooter>
      </Layout.Section>
    </RushPage>
  );
}
export default CarrierMaskingPage;
