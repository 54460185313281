import { Box, Text, VerticalStack } from '@shopify/polaris';
import { isEmpty } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';

function ShippingAddress({ destination_address = {} }) {
  const [t] = useTranslation();

  return isEmpty(destination_address) ? (
    ''
  ) : (
    <Box padding={4}>
      <VerticalStack gap={4}>
        <Text variant="headingMd">
          {t('shipment_details.shipment_addresss')}
        </Text>
        <Box>
          <Text>{destination_address.address} </Text>
          {destination_address.address2 && 
            <Text>{destination_address.address2} </Text>
          }
          <Text>{destination_address.city} </Text>
          <Text>{destination_address.country} </Text>
        </Box>
      </VerticalStack>
    </Box>
  );
}

export default ShippingAddress;
