import {
  Card,
  Text,
  Divider,
  VerticalStack,
  HorizontalStack,
  Box,
  Link,
  Badge,
  SkeletonBodyText,
  SkeletonDisplayText,
  Button,
  Modal,
  Icon,
} from '@shopify/polaris';
import { ClipboardMinor, ExternalSmallMinor } from '@shopify/polaris-icons';
import RushModal from 'components/custom/RushModal';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  useGetNotificationDetailsQuery,
  useGetNotificationsQuery,
} from '../NotificationsActivityFeedApi';
import startCase from 'lodash/startCase';
import { STORE_URLS } from 'config/urls';
import { formatDate, transformParams } from '../utils';
import { groupBy } from 'lodash';
import moment from 'moment';
import './styles.scss';
import { baseActions } from 'redux/store/baseSlice';
import { useDispatch } from 'react-redux';
import { getEventBadge, getActivityStatusBadge } from 'Utils/BadgeUtils';
import ExternalLink from 'components/ExternalLink';
import RushLoading from 'components/custom/RushLoader';
import RushPage from 'components/custom/RushPage';

function NotificationsActivityDetails() {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const { notificationId, uuid } = useParams();
  const navigate = useNavigate();
  const [modalDetails, setModalDetails] = useState(null);

  const { isFetching, data = {} } = useGetNotificationDetailsQuery({
    uuid: notificationId,
  });

  const urlParams = new URLSearchParams(location.state?.search);

  const notificationsResponse = useGetNotificationsQuery(
    transformParams(urlParams).toString(),
    { skip: urlParams.size === 0 }
  );
  const {
    data: { data: responseData = [] } = {
      responseData: [],
    },
    isLoading: isNotificationsLoading,
  } = notificationsResponse;

  let messageType = '';
  switch (data.channel?.slug) {
    case 'klaviyo':
    case 'yotpo':
      messageType = 'JSON';
      break;
    case 'shopify_fulfilment_preferences':
    case 'shopify_fulfilment':
      messageType = 'GraphQL';
      break;
    case 'app':
      messageType = 'Email';
      break;
    default:
      messageType = 'JSON';
      break;
  }

  const DetailsSectionRenderer = useMemo(() => {
    const eventBadge = getEventBadge(data.trigger?.slug);
    return (
      <Box padding="5">
        <VerticalStack gap="5">
          <Text as="h4" variant="headingMd">
            {t('notifications_activity.details')}
          </Text>
          {isFetching ? (
            <Box>
              <SkeletonBodyText lines={5} />
            </Box>
          ) : (
            <VerticalStack gap="4">
              <VerticalStack gap="1">
                <Text as="span" variant="heading2Xs" color="subdued">
                  {t('notifications_activity.channel')}
                </Text>
                <Text as="span" variant="heading2Xs">
                  {data.channel?.name}
                </Text>
              </VerticalStack>

              <VerticalStack gap="1">
                <Text as="span" variant="heading2Xs" color="subdued">
                  {t('notifications_activity.messagetype')}
                </Text>
                <Text as="span" variant="heading2Xs">
                  {messageType}
                </Text>
              </VerticalStack>

              <VerticalStack gap="1">
                <Text as="span" variant="heading2Xs" color="subdued">
                  {t('notifications_activity.event')}
                </Text>
                <Box>
                  <Badge
                    status={eventBadge.status}
                    progress={eventBadge.progress}
                  >
                    {data.trigger?.title}
                  </Badge>
                </Box>
              </VerticalStack>

              {data.subscriber?.email ? (
                <HorizontalStack wrap={false} gap="2">
                  <Text as="h1" variant="heading2Xs">
                    {t('common.to')}:
                  </Text>
                  <Text as="h1" variant="heading2Xs">
                    <Text as="h1" variant="heading2Xs">
                      {data.subscriber?.email}
                    </Text>
                  </Text>
                </HorizontalStack>
              ) : (
                ''
              )}

              {/* <HorizontalStack wrap={false} gap="2">
                <Text as="h1" variant="heading2Xs">
                  {t('common.from')}:
                </Text>
                <Text as="h1" variant="heading2Xs">
                  <Link
                    monochrome
                    url="https://help.shopify.com/manual"
                    removeUnderline={true}
                  >
                    ?
                  </Link>
                </Text>
              </HorizontalStack> */}
            </VerticalStack>
          )}
        </VerticalStack>
      </Box>
    );
  }, [data, isFetching]);

  const RelatedSectionRenderer = useMemo(() => {
    return data.entity_id ||data.order_label? (
      <Box padding="5">
        <VerticalStack gap="2">
          <Text as="h4" variant="headingMd">
            {t('notifications_activity.related_to')}
          </Text>
          {isFetching ? (
            <Box>
              <SkeletonBodyText lines={2} />
            </Box>
          ) : (
            <VerticalStack gap="4">
              {data.order_label ? (
                <VerticalStack gap="1">
                  <Text as="span" variant="heading2Xs" color="subdued">
                    {t('notifications_activity.order')}
                  </Text>
                  <HorizontalStack>
                    <ExternalLink url={data.order_external_link}>
                      {data.order_label}
                    </ExternalLink>
                    <Box>
                      <Icon source={ExternalSmallMinor} color="interactive" />
                    </Box>
                  </HorizontalStack>
                </VerticalStack>
              ) : (
                ''
              )}

              {data.entity_id ? (
                <VerticalStack gap="1">
                  <Text as="span" variant="heading2Xs" color="subdued">
                    {t('notifications_activity.shipment')}
                  </Text>
                  <HorizontalStack>
                    <Link
                      url={`/s/${uuid}${STORE_URLS.SHIPMENTS}/${data.entity_id}`}
                    >
                      {data.entity_id}
                    </Link>
                  </HorizontalStack>
                </VerticalStack>
              ) : null}
            </VerticalStack>
          )}
        </VerticalStack>
      </Box>
    ) : '';
  }, [data, isFetching]);

  const EmailSectionRenderer = useMemo(() => {
    return (
      <Box padding="5">
        <VerticalStack gap="5">
          <Text as="h4" variant="headingMd">
            {t('notifications_activity.message')}:
          </Text>
          {isFetching ? (
            <Box>
              <SkeletonBodyText lines={2} />
            </Box>
          ) : (
            <VerticalStack gap="2">
              <HorizontalStack wrap={false} gap="2">
                <Text as="h1" variant="heading2Xs">
                  {t('notifications_activity.subject')}:
                </Text>
              </HorizontalStack>
              <Box
                padding="5"
                borderColor="border"
                borderWidth="1"
                width={'100%'}
                minHeight={'200px'}
              ></Box>
            </VerticalStack>
          )}
        </VerticalStack>
      </Box>
    );
  }, [data, isFetching]);

  const PayloadSectionRenderer = useMemo(() => {
    if (Array.isArray(data.timelines) && data.timelines.length) {
      let payload = '';
      let formatedPayload = '';
      if (messageType === 'JSON') {
        payload = data.timelines?.find((t) => !!t?.response?.payload?.data)
          ?.response?.payload?.data;
        formatedPayload = JSON.stringify(payload, null, 2);
      } else if (messageType === 'GraphQL') {
        payload = data.timelines?.find((t) => !!t?.response?.graphql)?.response
          ?.graphql;
        formatedPayload = payload;
      }

      return payload && payload !== null ? (
        <Box padding="5">
          <VerticalStack gap="5">
            <HorizontalStack align="space-between">
              <Text as="h4" variant="headingMd">
                {t('notifications_activity.payload')}
              </Text>
              <Box>
                <Button
                  plain
                  icon={ClipboardMinor}
                  onClick={() => copyToClipboard(payload)}
                />
              </Box>
            </HorizontalStack>
            {isFetching ? (
              <Box>
                <SkeletonBodyText lines={2} />
              </Box>
            ) : (
              <Box
                padding="5"
                paddingBlockStart="1"
                paddingBlockEnd="1"
                borderColor="border"
                borderWidth="1"
                width={'100%'}
                minHeight={'200px'}
                overflowX={'auto'}
                overflowY={'auto'}
              >
                <pre>{formatedPayload}</pre>
              </Box>
            )}
          </VerticalStack>
        </Box>
      ) : (
        ''
      );
    } else {
      return '';
    }
  }, [data, isFetching]);

  const getStatusMessage = (timeLine, showLink = true) => {
    const { status, response } = timeLine;
    switch (status) {
      case 'Queued':
        return t('notifications_activity.queued_description');
      case 'Processing':
        return t('notifications_activity.processing_description');
      case 'Sent':
        return (
          <HorizontalStack gap={'1'}>
            {t('notifications_activity.sent_description')}
            {showLink ? (
              <Link onClick={() => setModalDetails(timeLine)}>Details</Link>
            ) : (
              ''
            )}
          </HorizontalStack>
        );
      case 'Failed':
        return (
          <HorizontalStack gap={'1'}>
            {response?.user_friendly_message ||
              t('notifications_activity.failed_description')}
            {showLink ? (
              <Link onClick={() => setModalDetails(timeLine)}>Details</Link>
            ) : (
              ''
            )}
          </HorizontalStack>
        );
    }
  };

  const TimelineRenderer = useMemo(() => {
    const timeline = data.timelines?.map((t) => {
      return {
        ...t,
        day: moment.utc(t.created_at).format('L'),
      };
    });
    const groups = groupBy(timeline, 'day');
    return (
      <Box padding="3" width="100%">
        <Box paddingBlockEnd="3" paddingBlockStart={'3'}>
          <Text as="h2" variant="headingMd">
            {t('notifications_activity.timeline')}:
          </Text>
          <Divider borderColor="border" />
        </Box>
        {Object.keys(groups).map((g) => {
          const group = groups[g];

          return group.length > 0 ? (
            <VerticalStack gap={'5'}>
              <Box paddingBlockStart={'1'}>
                <Text as="span">{formatDate(group[0].created_at)}</Text>
              </Box>

              <VerticalStack gap="2" width="100%">
                {group?.map((timeline) => {
                  const message = getStatusMessage(timeline);
                  const { status: badgestatus, progress } =
                    getActivityStatusBadge(timeline.status);
                  return (
                    <HorizontalStack
                      key={timeline.status}
                      wrap={false}
                      gap="2"
                      align={'space-between'}
                      blockAlign="start"
                    >
                      <Box maxWidth="90%">
                        <HorizontalStack wrap={false} gap="2" align="start">
                          <Badge status={badgestatus} progress={progress}>
                            {startCase(timeline.status)}
                          </Badge>
                          <Text as="span" variant="heading2Xs">
                            {message}
                          </Text>
                        </HorizontalStack>
                      </Box>
                      <Text as="p" color="subdued">
                        {moment.utc(timeline.created_at).format('LT')}
                      </Text>
                    </HorizontalStack>
                  );
                })}
              </VerticalStack>
              <br />
            </VerticalStack>
          ) : (
            ''
          );
        })}

        <br></br>
      </Box>
    );
  }, [data]);

  const getCurrentNotificationIndex = () => {
    return responseData.findIndex(({ id }) => id === notificationId);
  };

  const hasPreviousNotification = () => {
    return (
      !isNotificationsLoading &&
      responseData?.length &&
      getCurrentNotificationIndex() > 0
    );
  };
  const hasNextNotification = () => {
    return (
      !isNotificationsLoading &&
      responseData?.length > 1 &&
      getCurrentNotificationIndex() < responseData?.length - 1
    );
  };

  const getPreviousNotification = () => {
    try {
      const prevNotification = responseData[getCurrentNotificationIndex() - 1];

      navigate({
        pathname: `/s/${uuid}${STORE_URLS.NOTIFICATIONS_ACTIVITY_FEED}/${prevNotification?.id}`,
        state: { search: location.state?.search || '' },
      });

      // eslint-disable-next-line no-empty
    } catch {}
  };
  const getNextNotification = () => {
    try {
      const nextNotification = responseData[getCurrentNotificationIndex() + 1];
      navigate({
        pathname: `/s/${uuid}${STORE_URLS.NOTIFICATIONS_ACTIVITY_FEED}/${nextNotification?.id}`,
        state: { search: location.state?.search || '' },
      });
      // eslint-disable-next-line no-empty
    } catch {}
  };
  const { status: timelineStatus, progress } = data?.latest_status
    ? getActivityStatusBadge(data?.latest_status)
    : {};

  const copyToClipboard = (body) => {
    navigator.clipboard.writeText(JSON.stringify(body));
    dispatch(
      baseActions.setToastMessage({
        message: 'Copied to clipboard',
        type: 'success',
      })
    );
  };

  const renderDetailsModal = () => {
    const { status, response = {} } = modalDetails;
    const { code, http_status } = response || {};
    const responseBody = response?.body;
    return (
      <RushModal
        open={open}
        onClose={() => {
          setModalDetails(null);
        }}
        title={`${status} details`}
      >
        <Modal.Section>
          <VerticalStack gap={'3'}>
            <Text variant="headingMd" as="h3">
              {getStatusMessage(modalDetails, false)}
            </Text>
            <Text>
              HTTP response code: <strong>{code || http_status}</strong>
            </Text>
            {responseBody && responseBody !== null ? (
              <VerticalStack gap={'5'}>
                <HorizontalStack align="space-between">
                  <Text variant="headingSm" as="span">
                    Response Body:
                  </Text>
                  <Box>
                    <Button
                      plain
                      icon={ClipboardMinor}
                      onClick={() => copyToClipboard(responseBody)}
                    />
                  </Box>
                </HorizontalStack>
                <div className="respone-body-container">
                  <Box padding={'2'}>
                    <Text wrap={true} as="span">
                      <pre>{JSON.stringify(responseBody, null, 2)}</pre>
                    </Text>
                  </Box>
                </div>
              </VerticalStack>
            ) : (
              ''
            )}
          </VerticalStack>
        </Modal.Section>
      </RushModal>
    );
  };

  return (
    <>
      {isNotificationsLoading || isFetching ? <RushLoading /> : ''}
      <RushPage
        title={notificationId}
        titleMetadata={
          !isFetching ? (
            <Text as="h1" variant="heading2Xs">
              <HorizontalStack gap={2}>
                <Badge status={timelineStatus} progress={progress}>
                  {startCase(data?.latest_status)}
                </Badge>
                {data.is_test && <Badge>{`Test`}</Badge>}
              </HorizontalStack>
            </Text>
          ) : (
            <Box>
              <SkeletonDisplayText size="medium" />
            </Box>
          )
        }
        pagination={{
          hasPrevious: hasPreviousNotification(),
          onPrevious: getPreviousNotification,
          hasNext: hasNextNotification(),
          onNext: getNextNotification,
        }}
        backAction={{
          onAction: () => {
            navigate(
              `/s/${uuid}${STORE_URLS.NOTIFICATIONS_ACTIVITY_FEED}${
                location.state?.search || ''
              }`
            );
          },
        }}
      >
        <Card roundedAbove="sm" padding="0">
          {DetailsSectionRenderer}
          <Divider borderColor="border" />
          {RelatedSectionRenderer}
          <Divider borderColor="border" />
          {messageType === 'Email' ? EmailSectionRenderer : ''}
          {PayloadSectionRenderer}
        </Card>
        {!isFetching ? TimelineRenderer : ''}
        {modalDetails ? renderDetailsModal() : ''}
      </RushPage>
    </>
  );
}
export default NotificationsActivityDetails;
