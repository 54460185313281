import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { billingActions } from 'pages/Billing/billingSlice';
import UpdatePlanModal from 'pages/Billing/components/UpdatePlanModal';
import RushBanner from './custom/RushBanner';

function UpgradePlanBanner({ isAdvancedPlanFeature }) {
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const showModal = () => {
    dispatch(billingActions.toggleUpdateModal());
  };
  return (
    <>
      <RushBanner
        bannerKey={'upgrade-plan-banner'}
        title={t(
          isAdvancedPlanFeature
            ? 'common.upgrade-to-advanced-to-unlock'
            : 'common.upgrade-to-unlock'
        )}
        action={{
          content: t('common.upgrade-to-growth.title'),
          onAction: showModal,
        }}
        status="critical"
      >
        <p>
          {t(
            isAdvancedPlanFeature
              ? 'common.upgrade-to-advanced.description'
              : 'common.upgrade-to-growth.description'
          )}
        </p>
      </RushBanner>
      <UpdatePlanModal />
    </>
  );
}

export default UpgradePlanBanner;
