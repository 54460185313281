import React, { useState, useCallback } from 'react';
import {
  Popover,
  ActionList,
  Button,
  Text,
  VerticalStack,
} from '@shopify/polaris';
import { useTranslation } from 'react-i18next';

export default function FlagDropDown({
  handleIsUpdate,
  selectedCountryFlag,
  setSelectedCountryFlag,
  countriesList,
  loading,
}) {
  const [t] = useTranslation();
  const [isLanguageActive, setIsLanguageActive] = useState(false);
  const toggleActiveLanguage = useCallback(
    () => setIsLanguageActive((isLanguageActive) => !isLanguageActive),
    []
  );

  const languageActivator = (
    <VerticalStack gap={'1'}>
      <Text variant="bodyMd" as="span">
        {t('settings.tracking_page.localisation.custom_language.flag')}
      </Text>
      <Button
        onClick={toggleActiveLanguage}
        disclosure
        size={'slim'}
        loading={loading}
        icon={
          <img
            height="25px"
            width="25px"
            alt=""
            src={selectedCountryFlag?.svg_flag_url}
          />
        }
      />
    </VerticalStack>
  );

  const handleCountryFlag = (country) => {
    handleIsUpdate();
    setSelectedCountryFlag(country);
    toggleActiveLanguage();
  };

  const formattedFlagArray = (countries) => {
    const formatArray = [];
    countries &&
      countries.map((country) => {
        formatArray.push({
          content: (
            <div style={{ display: 'flex' }}>
              <div>
                <img
                  height="25px"
                  width="25px"
                  alt=""
                  src={country.svg_flag_url}
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  marginLeft: '0.625rem',
                  alignItems: 'center',
                }}
              >
                {country.name}
              </div>
            </div>
          ),
          onAction: () => handleCountryFlag(country),
        });
        return false;
      });
    return formatArray;
  };

  return (
    <Popover
      active={isLanguageActive}
      activator={languageActivator}
      onClose={toggleActiveLanguage}
      preferredAlignment="left"
    >
      <ActionList items={formattedFlagArray(countriesList)} />
    </Popover>
  );
}
