import React from 'react';
import {
  Text,
  Card,
  Checkbox,
  VerticalStack,
  Box,
  HorizontalStack,
} from '@shopify/polaris';
import { useTranslation } from 'react-i18next';

function LocalizeMessages({ isSelected, onChange }) {
  const [t] = useTranslation();

  const localizeMessagesTitle = t('tracking_page_new.localize_messages_title');
  const localizeMessagesDescription = t(
    'tracking_page_new.localize_messages_description'
  );
  const localizeMessagesCheckbox = t(
    'tracking_page_new.localize_messages_checkbox'
  );
  const localizeMessagesFooter = t(
    'tracking_page_new.localize_messages_footer'
  );

  return (
    <Card roundedAbove="sm">
      <VerticalStack gap="5">
        <VerticalStack gap="2">
          <Text variant="headingMd" as="span">
            {localizeMessagesTitle}
          </Text>
          <Text color="subdued">{localizeMessagesDescription}</Text>
        </VerticalStack>
        <Box>
          <Checkbox
            disabled={true}
            label={localizeMessagesCheckbox}
            checked={isSelected}
            onChange={() => onChange(!isSelected)}
          />
        </Box>
        {!isSelected ? (
          <HorizontalStack blockAlign="center">
            <Text color="subdued">{localizeMessagesFooter}</Text>
          </HorizontalStack>
        ) : (
          ''
        )}
      </VerticalStack>
    </Card>
  );
}

export default LocalizeMessages;
