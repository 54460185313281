import { Button, FooterHelp, HorizontalStack, Icon } from '@shopify/polaris';
import { FRONTEND_URL } from 'config';
import useIsEmbededApp from 'hooks/useIsEmbededApp';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { ExternalSmallMinor } from '@shopify/polaris-icons';
import useOtt from 'hooks/useOtt';
import { ClientStorage } from 'ClientStorage';
import { LOCAL_STORAGE } from 'Constants';

const EmbeddedFooter = () => {
  const [fetchOtt, setFetchOtt] = useState(false);
  const {
    isLoading,
    isSuccess,
    data: ottResponse = {},
    remove,
  } = useOtt({ enabled: fetchOtt });
  const [t] = useTranslation();
  const location = useLocation();

  useEffect(() => {
    if (!isLoading && isSuccess && fetchOtt) {
      let params = new URLSearchParams(location.search);
      params.set('ott', ottResponse.query?.ott);
      params.set('locale', ClientStorage.get(LOCAL_STORAGE.LANGUAGE));
      params.set('popout_case', 'true');
      setFetchOtt(false);
      console.log({
        l: `${FRONTEND_URL}${location.pathname}?${params.toString()}`,
      });
      window.open(
        `${FRONTEND_URL}${location.pathname}?${params.toString()}`,
        '_blank'
      );
    }
  }, [isLoading, isSuccess, fetchOtt]);

  return (
    <HorizontalStack gap={1}>
      <Button
        plain
        loading={isLoading}
        onClick={(event) => {
          event.preventDefault();
          remove();
          setFetchOtt(true);
        }}
        url={`${FRONTEND_URL}${location.pathname}${location.search}`}
      >
        {t('common.pop-page-out')}
      </Button>
      {!isLoading && <Icon source={ExternalSmallMinor} color={'interactive'} />}
    </HorizontalStack>
  );
};

function RushFooter(props) {
  const isEmbededApp = useIsEmbededApp();

  const { children } = props;

  return (
    <FooterHelp>{isEmbededApp ? <EmbeddedFooter /> : children}</FooterHelp>
  );
}

export default RushFooter;
