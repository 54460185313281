import stringHelper from 'helpers/string';
export default {
  historyPush: function (navigate, location, to) {
    let queryString = '';
    if (location?.search) {
      const cookieParams = stringHelper.parseQueryString(location.search);
      if (cookieParams) {
        queryString = Object.keys(cookieParams)
          .map((key) => key + '=' + cookieParams[key])
          .join('&');
      }
    }

    let customURL = `${to}?${queryString}`;
    if (to.indexOf('?') > 0) {
      customURL = `${to}&${queryString}`;
    }
    navigate(customURL);
  },
};
