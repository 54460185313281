import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  TextField,
  Text,
  VerticalStack,
  Box,
  HorizontalStack,
  Button,
} from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import '../styles.scss';
import { STORE_URLS } from 'config/urls';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import '../styles.scss';

function Step2({ title, onTitleChange, handleBack, pagesPrefix }) {
  const navigate = useNavigate();
  const shop = useSelector((state) => state.shop);

  const [t] = useTranslation();
  const [showError, setShowError] = useState(false);

  const handleAddPage = () => {
    if (title !== '') {
      navigate(`/s/${shop.storeUUID}${STORE_URLS.TRACKING_PAGE_MODERN}`);
    } else {
      setShowError(true);
    }
  };

  const primaryAction = useMemo(() => {
    return {
      content: t('settings.os2_tracking_page.wizard.step3.add_page'),
      onClick: handleAddPage,
    };
  }, [handleAddPage]);

  const secondaryAction = useMemo(() => {
    return {
      content: t('settings.os2_tracking_page.wizard.secondary_action'),
      onClick: handleBack,
    };
  }, [handleBack]);

  return (
    <div className="step3-container">
      <Card roundedAbove="sm" padding={0}>
        <Box padding={'5'} paddingBlockEnd={'0'}>
          <Text variant="headingMd" as="h2">
            {t('modern_page.wizard.step3.title')}
          </Text>
        </Box>

        <Box padding={'5'}>
          <VerticalStack gap={'3'}>
            <TextField
              autoComplete={'off'}
              label={t(
                'settings.os2_tracking_page.wizard.step3.name_box.title'
              )}
              value={title}
              onChange={onTitleChange}
              error={
                showError &&
                !title &&
                t('settings.os2_tracking_page.wizard.step3.page_title_required')
              }
              autoFocus
            />
            <TextField
              autoComplete={'off'}
              label={t(
                'settings.os2_tracking_page.wizard.step3.handle_box.title'
              )}
              prefix={
                pagesPrefix.domain + pagesPrefix.proxy + pagesPrefix.extension
              }
              disabled={true}
            />
          </VerticalStack>
        </Box>
        <Box
          padding={'4'}
          borderBlockStartWidth="1"
          borderColor="border-subdued"
        >
          <HorizontalStack align="end" gap={'2'}>
            {secondaryAction ? (
              <Button onClick={secondaryAction.onClick}>
                {secondaryAction.content}
              </Button>
            ) : (
              ''
            )}
            <Button primary onClick={primaryAction.onClick}>
              {primaryAction.content}
            </Button>
          </HorizontalStack>
        </Box>
      </Card>
    </div>
  );
}

Step2.propTypes = {
  onChange: PropTypes.func.isRequired,
  showToast: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  wizardData: PropTypes.object.isRequired,
};

export default Step2;
