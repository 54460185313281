import React from 'react';
import { VerticalStack,Modal} from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import TranslateLink from 'components/TranslateLink';
import RushModal from 'components/custom/RushModal';
export default function AutofillModal({
  show,
  onClose,
  selectedLanguageName,
  selectedOriginLanguageName,
  onAutofill,
  onOk,
}) {
  const [t] = useTranslation();

  return (
    <RushModal
      open={show}
      onClose={onClose}
      title={
        <TranslateLink
          text={t(
            'settings.tracking_page.localisation.custom_language.autofill_modal.title',
            { language: selectedLanguageName }
          ).replace('****', '')}
        />
      }
      primaryAction={{
        content: t(
          'settings.tracking_page.localisation.custom_language.autofill_modal.primary_action'
        ),
        onAction: onOk,
      }}
      secondaryActions={[
        {
          content: t(
            'settings.tracking_page.localisation.custom_language.autofill_modal.secondary_action'
          ),
          onAction: onAutofill,
        },
      ]}
    >
      <Modal.Section>
        <VerticalStack gap="2">
          <p>
            {t(
              'settings.tracking_page.localisation.custom_language.autofill_modal.description1',
              { language: selectedOriginLanguageName }
            )}
          </p>
          <p>
            {t(
              'settings.tracking_page.localisation.custom_language.autofill_modal.description2'
            )}
          </p>
        </VerticalStack>
      </Modal.Section>
    </RushModal>
  );
}
