import React, { useEffect } from 'react';
import { ClientStorage } from 'ClientStorage';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import { GOOGLE_TAG_ID, FULL_STORY_ORG, STAGE } from 'config';
import { LOCAL_STORAGE, ENV_PRODUCTION } from 'Constants';
import { useDispatch } from 'react-redux';
import { baseActions } from 'redux/store/baseSlice';
import { useGetChatIdentifierQuery } from 'redux/store/commonBaseApis';
import { useParams } from 'react-router-dom';
import i18next from 'i18next';
import * as Sentry from '@sentry/browser';

export default function TrackingManager({ base, accessToken, user, skip }) {
  const params = useParams();
  const {
    data = {},
    isLoading,
    isSuccess,
  } = useGetChatIdentifierQuery('', {
    skip: STAGE !== ENV_PRODUCTION || !accessToken || skip || user.sudo,
  });

  const sendDataToGTM = useGTMDispatch();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isLoading && isSuccess) {
      try {
        if (
          ClientStorage.get(LOCAL_STORAGE.TRACK_ANALYTICS) !== true ||
          !window.HubSpotConversations ||
          !user.email ||
          user.sudo
        ) {
          return;
        }

        if (!data?.hubspot_identification_token) {
          const errorMessage = `HubSpot Identifier Missing`;
          Sentry.setExtra('Identifier_response', JSON.stringify(data));
          Sentry.captureMessage(errorMessage);
        }

        window.hsConversationsSettings = {
          identificationEmail: user.email,
          identificationToken: data?.hubspot_identification_token,
        };

        window.HubSpotConversations.widget.load();
      } catch (e) {
        console.log(e);
      }
    }
  }, [data, isLoading, isSuccess, user.email, user.sudo]);

  useEffect(() => {
    if (!FULL_STORY_ORG || FULL_STORY_ORG === 'null' || !window.FS) {
      window.FS?.log({ FULL_STORY_ORG });
      return;
    }

    if (ClientStorage.get(LOCAL_STORAGE.TRACK_ANALYTICS) !== true) {
      window.FS.log(
        'Shutting down fullstory because "trackAnalytics !== true"'
      );
      window.FS?.shutdown();
      return;
    }

    if (base.isFullstoryLoaded) {
      return;
    }

    const FSData = {
      displayName: `${user.first_name} ${user.last_name}`,
      email: user.email,
      language: i18next.language,
    };

    window.FS.event('appLoaded', FSData);
    window.FS.identify(user.id, FSData);
    dispatch(baseActions.markFullstoryLoaded(true));
  }, [user, base.isFullstoryLoaded]);

  useEffect(() => {
    if (!GOOGLE_TAG_ID || GOOGLE_TAG_ID === 'null') {
      return;
    }

    if (base.gtmDataSent) {
      return;
    }

    if (ClientStorage.get('trackAnalytics') !== true) {
      return;
    }

    sendDataToGTM({
      event: 'appLoaded',
      email: user.email,
      name: `${user.first_name} ${user.last_name}`,
      storeUUID: params.uuid,
    });

    dispatch(baseActions.markGtmDataSentTrue());
  }, [sendDataToGTM, dispatch, params.uuid]);
  return <></>;
}
