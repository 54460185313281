import React from 'react';
import {
  SkeletonPage,
  Card,
  SkeletonBodyText,
  Box,
  SkeletonDisplayText,
  VerticalStack,
  HorizontalStack,
  SkeletonThumbnail,
} from '@shopify/polaris';
import { useTranslation } from 'react-i18next';

function PerksSkeleton() {
  const [t] = useTranslation();

  return (
    <SkeletonPage title={t('perks.title')} subtitle={t('perks.description')}>
      <VerticalStack gap="5">
        <Card roundedAbove="sm" padding={0}>
          <Box padding="5">
            <VerticalStack gap={'5'}>
              <HorizontalStack gap={'3'} blockAlign="center">
                <Box>
                  <SkeletonThumbnail size="small" />
                </Box>

                <Box width="20%">
                  <SkeletonBodyText lines={'2'} />
                </Box>
              </HorizontalStack>
              <Box>
                <SkeletonBodyText lines={'2'} />
              </Box>

              <VerticalStack gap={'6'}>
                <Box width="10%">
                  <SkeletonBodyText lines={'1'} />
                </Box>
                <Box width="20%">
                  <SkeletonBodyText lines={'1'} />
                </Box>
              </VerticalStack>
              <HorizontalStack align="end">
                <Box width="10%">
                  <SkeletonDisplayText size="small" />
                </Box>
              </HorizontalStack>
            </VerticalStack>
          </Box>
        </Card>
        <Card roundedAbove="sm" padding={0}>
          <Box padding="5">
            <VerticalStack gap={'5'}>
              <HorizontalStack gap={'3'} blockAlign="center">
                <Box>
                  <SkeletonThumbnail size="small" />
                </Box>

                <Box width="20%">
                  <SkeletonBodyText lines={'2'} />
                </Box>
              </HorizontalStack>
              <Box>
                <SkeletonBodyText lines={'2'} />
              </Box>

              <VerticalStack gap={'6'}>
                <Box width="10%">
                  <SkeletonBodyText lines={'1'} />
                </Box>
                <Box width="30%">
                  <SkeletonBodyText lines={'1'} />
                </Box>
              </VerticalStack>
              <HorizontalStack align="end">
                <Box width="10%">
                  <SkeletonDisplayText size="small" />
                </Box>
              </HorizontalStack>
            </VerticalStack>
          </Box>
        </Card>
        <Card roundedAbove="sm" padding={0}>
          <Box padding="5">
            <VerticalStack gap={'5'}>
              <HorizontalStack gap={'3'} blockAlign="center">
                <Box>
                  <SkeletonThumbnail size="small" />
                </Box>

                <Box width="20%">
                  <SkeletonBodyText lines={'2'} />
                </Box>
              </HorizontalStack>
              <Box>
                <SkeletonBodyText lines={'2'} />
              </Box>

              <VerticalStack gap={'6'}>
                <Box width="10%">
                  <SkeletonBodyText lines={'1'} />
                </Box>
                <Box width="20%">
                  <SkeletonBodyText lines={'1'} />
                </Box>
              </VerticalStack>
              <HorizontalStack align="end">
                <Box width="10%">
                  <SkeletonDisplayText size="small" />
                </Box>
              </HorizontalStack>
            </VerticalStack>
          </Box>
        </Card>
        <Card roundedAbove="sm" padding={0}>
          <Box padding="5">
            <VerticalStack gap={'5'}>
              <HorizontalStack gap={'3'} blockAlign="center">
                <Box>
                  <SkeletonThumbnail size="small" />
                </Box>

                <Box width="20%">
                  <SkeletonBodyText lines={'2'} />
                </Box>
              </HorizontalStack>
              <Box>
                <SkeletonBodyText lines={'2'} />
              </Box>

              <HorizontalStack align="end">
                <Box width="10%">
                  <SkeletonDisplayText size="small" />
                </Box>
              </HorizontalStack>
            </VerticalStack>
          </Box>
        </Card>
        <Card roundedAbove="sm" padding={0}>
          <Box padding="5">
            <VerticalStack gap={'5'}>
              <HorizontalStack gap={'3'} blockAlign="center">
                <Box>
                  <SkeletonThumbnail size="small" />
                </Box>

                <Box width="20%">
                  <SkeletonBodyText lines={'2'} />
                </Box>
              </HorizontalStack>
              <Box>
                <SkeletonBodyText lines={'1'} />
              </Box>

              <VerticalStack gap={'6'}>
                <Box width="10%">
                  <SkeletonBodyText lines={'1'} />
                </Box>
                <Box width="10%">
                  <SkeletonBodyText lines={'1'} />
                </Box>
              </VerticalStack>
              <HorizontalStack align="end">
                <Box width="10%">
                  <SkeletonDisplayText size="small" />
                </Box>
              </HorizontalStack>
            </VerticalStack>
          </Box>
        </Card>
        <Card roundedAbove="sm" padding={0}>
          <Box padding="5">
            <VerticalStack gap={'5'}>
              <HorizontalStack gap={'3'} blockAlign="center">
                <Box>
                  <SkeletonThumbnail size="small" />
                </Box>

                <Box width="20%">
                  <SkeletonBodyText lines={'2'} />
                </Box>
              </HorizontalStack>
              <Box width="10%">
                <SkeletonBodyText lines={'1'} />
              </Box>
              <HorizontalStack align="end">
                <Box width="10%">
                  <SkeletonDisplayText size="small" />
                </Box>
              </HorizontalStack>
            </VerticalStack>
          </Box>
        </Card>
      </VerticalStack>
    </SkeletonPage>
  );
}
export default PerksSkeleton;
