import { Box, HorizontalStack, Text, VerticalStack } from '@shopify/polaris';
import { getHumanDate } from 'pages/Shipments3/components/helpers';
import React from 'react';
import { useTranslation } from 'react-i18next';

function ShipmentInformation({ statistics = {} }) {
  const [t] = useTranslation();

  const { last_synced_at, client_waiting_time } = statistics;
  const lastCheck = getHumanDate(last_synced_at, false);
  return !(last_synced_at && client_waiting_time) ? (
    ''
  ) : (
    <Box padding={4}>
      <VerticalStack gap={4}>
        <Text variant="headingMd">
          {t('shipment_details.shipment_information')}
        </Text>
        <VerticalStack gap={1}>
          {/* <HorizontalStack align="space-between">
            <Text> {t('shipment_details.eta')}</Text>
            <Text>Mar 1,2022</Text>
          </HorizontalStack>
          <HorizontalStack align="space-between">
            <Text> {t('shipment_details.status')}</Text>
            <Text>Delivered</Text>
          </HorizontalStack>
          <HorizontalStack align="space-between">
            <Text> {t('shipment_details.quota_mark')}</Text>
            <Text>free</Text>
          </HorizontalStack> */}
          {client_waiting_time && (
            <HorizontalStack align="space-between">
              <Text> {t('shipment_details.client_waiting')}</Text>
              <Text>{client_waiting_time}</Text>
            </HorizontalStack>
          )}
          {!!last_synced_at && (
            <HorizontalStack align="space-between">
              <Text> {t('shipment_details.last_carrier_check')}</Text>
              <Text variation={lastCheck.variation}>{lastCheck?.text}</Text>
            </HorizontalStack>
          )}
        </VerticalStack>
      </VerticalStack>
    </Box>
  );
}

export default ShipmentInformation;
