import React, { useState, useCallback, useEffect } from 'react';
import {
  Popover,
  ActionList,
  Button,
  Text,
  VerticalStack,
  HorizontalStack,
} from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

export default function PublicLanguagesDropDown({
  handleIsUpdate,
  languages,
  getLanguageData,
  selectedLanguage,
  setSelectedLanguage,
  loading,
}) {
  const [t] = useTranslation();

  const [isLanguageActive, setIsLanguageActive] = useState(false);
  const toggleActiveLanguage = useCallback(
    () => setIsLanguageActive((isLanguageActive) => !isLanguageActive),
    []
  );

  useEffect(() => {
    getLanguageData(selectedLanguage?.language_iso_639 || i18next.language);
  }, [getLanguageData]);

  const languageActivator = (
    <VerticalStack gap={'1'}>
      <Text variant="bodyMd" as="span">
        {t('settings.tracking_page.localisation.custom_language.origin')}
      </Text>
      <Button
        onClick={toggleActiveLanguage}
        disclosure
        icon={
          <img src={selectedLanguage?.svg_flag_url} alt={'logo'} width={'25'} />
        }
        loading={loading || !selectedLanguage}
        size={'slim'}
      >
        {selectedLanguage?.label_local}
      </Button>
    </VerticalStack>
  );

  const handleLanguageSelection = useCallback(
    (language) => {
      handleIsUpdate();
      setSelectedLanguage(language);
      getLanguageData(language.language_iso_639);
      toggleActiveLanguage();
    },
    [toggleActiveLanguage]
  );

  const formattedFlagArray = useCallback(() => {
    const formatArray = [];
    languages &&
      languages
        .sort((a, b) => a.label_english.localeCompare(b.label_english))
        .map((language) => {
          formatArray.push({
            content: (
              <HorizontalStack>
                <img src={language.svg_flag_url} alt={'logo'} width={'25'} />
                <Text variant="bodyMd" as="span">
                  {' '}
                  {language.label_local}
                </Text>
              </HorizontalStack>
            ),
            onAction: () => handleLanguageSelection(language),
          });
          return false;
        });
    return formatArray;
  }, [languages, handleLanguageSelection]);

  return (
    <Popover
      active={isLanguageActive}
      activator={languageActivator}
      onClose={toggleActiveLanguage}
      preferredAlignment="left"
    >
      <ActionList items={formattedFlagArray()} />
    </Popover>
  );
}
