import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useGetStoreStatisticsQuery } from 'redux/store/commonBaseApis';
import { useGetCurrentBillingPlanQuery } from 'redux/store/commonStoreApis';

const ContactCardsHOC = (Component) => (props) => {
  const params = useParams();

  /**
   * check for 3 cases if fulfilled only then show the cards
   */

  const { data: statisticsData = {}, isSuccess: isStatisticsLoaded } =
    useGetStoreStatisticsQuery(params.uuid, {
      skip: !params.uuid,
    });

  const { isSuccess: isPlanLoaded, data: currentPlan = {} } =
    useGetCurrentBillingPlanQuery(undefined, { skip: !params.uuid });

  const isOnPlusPlan = useMemo(() => {
    if (isStatisticsLoaded && statisticsData) {
      return statisticsData.statistics?.platform_plan_name === 'plus';
    }
    return false;
  }, [isStatisticsLoaded, statisticsData]);

  const has5kShipments = useMemo(() => {
    if (isStatisticsLoaded && statisticsData) {
      return statisticsData.statistics?.platform_orders_per_month >= 5000;
    }
    return false;
  }, [isStatisticsLoaded, statisticsData]);

  const isMonthlyPlan = useMemo(() => {
    return currentPlan?.billing_plan?.subscription_plan
      ?.is_charge_limit_supported;
  }, [currentPlan]);

  const hasRemarablePlan = useMemo(() => {
    if (isPlanLoaded && currentPlan) {
      const price = (currentPlan.billing_plan?.features || []).find(
        ({ feature_id }) => feature_id === 1
      )?.price;
      if (isMonthlyPlan) {
        return price >= 199;
      } else {
        return price >= 199 * 12;
      }
    }
    return false;
  }, [isPlanLoaded, currentPlan, isMonthlyPlan]);

  const showModals = useMemo(() => {
    return isOnPlusPlan || has5kShipments || hasRemarablePlan;
  }, [isOnPlusPlan, has5kShipments, hasRemarablePlan]);

  return <Component {...props} isConditionFulfilled={showModals} />;
};

export default ContactCardsHOC;
