import React, { useEffect } from 'react';
import { ClientStorage } from 'ClientStorage';
import { LOCAL_STORAGE } from 'Constants';
import { useDispatch } from 'react-redux';
import { baseActions } from 'redux/store/baseSlice';
import publicIp from 'public-ip';

export default function SegmentAnalytics({ shop, base }) {
  const dispatch = useDispatch();
  const trackAnalytics = async () => {
    if (
      shop.storeUUID &&
      !base.segmentIdentified &&
      window.analytics &&
      ClientStorage.get(LOCAL_STORAGE.TRACK_ANALYTICS) === true
    ) {
      const ipAddress = await publicIp.v4();
      window.analytics.identify(shop.storeUUID, {
        name: shop.user.fullName,
        email: shop.user.email,
        ip: ipAddress,
      });
      window.analytics.track('Shopify App login');
      dispatch(baseActions.markSegmentIdentified());
    }
  };

  useEffect(() => {
    trackAnalytics();
  }, [base.segmentIdentified, shop, dispatch]);
  return <></>;
}
