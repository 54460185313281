import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Frame } from '@shopify/polaris';
import { useDispatch, useSelector } from 'react-redux';
import TopBarMarkup from './components/TopBarMarkup';
import LeftNavigation from './components/LeftNavigation';
import TrackingManager from './components/TrackingManager';
import SegmentAnalytics from './components/SegmentAnalytics';
import { SENTRY_URL, STAGE } from 'config';
import * as Sentry from '@sentry/browser';
import { useLocation } from 'react-router-dom';
import { useI18n } from './i18n'; // Import your custom hook

import {
  baseActions,
  IsErrorToast,
  ShowToast,
  ToastMessage,
  ToastDismissCallBack,
} from 'redux/store/baseSlice';
import 'styles/toaster.scss';
import './styles/custom.scss';
import RushToast from 'components/custom/RushToast';
import { PolarisProvider } from 'components/Providers/PolarisProvider';
import { AppBridgeProvider } from 'components/Providers/AppBridgeProvider';
import { NavigationMenu } from '@shopify/app-bridge-react';
import { QueryProvider } from 'components/Providers/QueryProvider';
import RushLoading from 'components/custom/RushLoader';
import LoadingPage from 'components/LoadingPage';
import { getEmbeddedNavMenu } from 'components/LeftNavigation/navUtils';
import useCheckPermission from 'hooks/useCheckPermission';
import { AccessToken } from 'pages/Visitor/visitorSlice';
import { StoreUUID } from 'redux/store/shopSlice';
import AppRoutes from 'routes';
import useGetAndSetUser from 'hooks/useGetAndSetUser';
import useCheckQuestionaire from 'hooks/useCheckQuestionaire';
import useStorageStoreId from 'hooks/useStorageStoreId';
import { useGetFeaturesQuery } from 'redux/store/commonStoreApis';
import {
  TOP_BAR_EXCLUDED_URLS,
  LEFT_NAVIGATION_EXCLUDED_URLS,
} from 'Constants';
import SudoBanner from 'components/SudoBanner';
import useEmbededAppRedirect from 'hooks/useEmbededAppRedirect';
import { AUTH_URLS, USER_URLS } from 'config/urls';
import useIsEmbededApp from 'hooks/useIsEmbededApp';
import { isEmbededAppExperience } from 'Utils/utils';
import useGetAndSetToken from 'hooks/useGetAndSetToken';
import ScrollToTop from 'components/ScrollToTop';
const authPaths = [AUTH_URLS.SHOPIFY_AUTH, AUTH_URLS.RUSH_AUTH];
const approvalRequiredPaths = [
  AUTH_URLS.RUSH_APPROVAL,
  AUTH_URLS.RUSH_EXIT_IFRAME,
];
const splashPaths = [AUTH_URLS.RUSH_SPLASH];

function App() {
  const dispatch = useDispatch();
  const location = useLocation();

  const accessToken = useSelector(AccessToken);
  const storeUUID = useSelector(StoreUUID);
  const showToast = useSelector(ShowToast);
  const toastMessage = useSelector(ToastMessage);
  const toastDismissCallBack = useSelector(ToastDismissCallBack);
  const isErrorToast = useSelector(IsErrorToast);

  const [showMobileNav, setShowMobileNav] = useState(false);

  const toggleMobileNav = useCallback(
    () => setShowMobileNav((active) => !active),
    []
  );

  const isVerifiedApp = useMemo(
    () => window.__SHOPIFY_DEV_IS_VERIFIED_APP || accessToken,
    [accessToken]
  );

  const isTokenLoading = useGetAndSetToken();
  const isEmbededApp = useIsEmbededApp();
  const tempEmbeddedExperience = isEmbededAppExperience(storeUUID);
  const isVerifyingEmbededApp = isEmbededApp && !isVerifiedApp;

  useI18n({
    skip:
      (isVerifyingEmbededApp &&
        !approvalRequiredPaths.includes(location.pathname)) ||
      authPaths.includes(location.pathname),
  });
  useStorageStoreId({ skip: isVerifyingEmbededApp || isEmbededApp });
  useEmbededAppRedirect();
  const { isLoading: isUserLoading, user = {} } = useGetAndSetUser({
    skip:
      !accessToken ||
      authPaths.includes(location.pathname) ||
      isVerifyingEmbededApp,
  });

  const { isLoading: isCheckingPermissions } = useCheckPermission({
    skip:
      isEmbededApp ||
      (isEmbededApp && !tempEmbeddedExperience) ||
      isUserLoading ||
      authPaths.includes(location.pathname) ||
      !storeUUID ||
      !accessToken,
  });
  const { isLoading: isCheckingQuestionnaire } = useCheckQuestionaire({
    skip:
      (isEmbededApp && !tempEmbeddedExperience) ||
      isUserLoading ||
      authPaths.includes(location.pathname) ||
      isVerifyingEmbededApp ||
      !accessToken,
  });
  useGetFeaturesQuery(undefined, {
    skip:
      (isEmbededApp && !tempEmbeddedExperience) ||
      !storeUUID ||
      !accessToken ||
      authPaths.includes(location.pathname) ||
      isVerifyingEmbededApp,
  });

  useEffect(() => {
    if (SENTRY_URL) {
      Sentry.init({
        dsn: SENTRY_URL,
        environment: STAGE,
        ignoreErrors: [
          'y is not a function',
          /getElementsByTagName/,
          /Failed to execute 'insertBefore' on 'Node'/,
          /Failed to execute 'removeChild' on 'Node'/,
        ],
      });

      Sentry.addBreadcrumb({
        category: 'route',
        level: 'info',
        data: { location: location, shop: storeUUID },
      });
    }
  }, []);

  const showLoadingPage = useMemo(() => {
    return (
      (isTokenLoading ||
        isCheckingPermissions ||
        isCheckingQuestionnaire ||
        isVerifyingEmbededApp ||
        isUserLoading) &&
      !approvalRequiredPaths.includes(location.pathname)
    );
  }, [
    isCheckingPermissions,
    isCheckingQuestionnaire,
    isVerifyingEmbededApp,
    isUserLoading,
    location.pathname,
    isTokenLoading,
  ]);

  const excludeLeftBar =
    isEmbededApp ||
    (isEmbededApp && tempEmbeddedExperience) ||
    !accessToken ||
    isCheckingQuestionnaire ||
    LEFT_NAVIGATION_EXCLUDED_URLS.some((url) =>
      location.pathname.endsWith(url)
    );

  const excludeTopBar =
    isEmbededApp ||
    (isEmbededApp && tempEmbeddedExperience) ||
    !accessToken ||
    isCheckingQuestionnaire ||
    TOP_BAR_EXCLUDED_URLS.some((url) => location.pathname.endsWith(url));

  const toastMarkup = useMemo(() => {
    if (showToast && toastMessage) {
      return (
        <RushToast
          content={toastMessage}
          duration={4000}
          onDismiss={() => {
            if (toastDismissCallBack) {
              toastDismissCallBack();
            }
            dispatch(baseActions.dismissToast());
          }}
          error={isErrorToast}
        />
      );
    }
  }, [showToast, toastMessage, dispatch, isErrorToast]);

  const renderEmpededNavBar = () => {
    const nonQPaths = [
      ...authPaths,
      ...approvalRequiredPaths,
      ...splashPaths,
      USER_URLS.QUESTIONNAIRE,
    ];
    return !isVerifyingEmbededApp &&
      tempEmbeddedExperience &&
      !showLoadingPage &&
      !nonQPaths.includes(location.pathname) ? (
      <NavigationMenu
        navigationLinks={getEmbeddedNavMenu({
          storeUUID,
        })}
        matcher={(link, location) => link.destination === location.pathname}
      ></NavigationMenu>
    ) : (
      ''
    );
  };
  const renderRushApp = () => {
    return (
      <Frame
        topBar={
          !excludeTopBar &&
          !showLoadingPage && <TopBarMarkup toggleMobileNav={toggleMobileNav} />
        }
        navigation={!excludeLeftBar && !showLoadingPage && <LeftNavigation />}
        showMobileNavigation={showMobileNav}
        onNavigationDismiss={toggleMobileNav}
      >
        {!showLoadingPage ? (
          <>
            <TrackingManager
              user={user}
              skip={
                approvalRequiredPaths.includes(location.pathname) ||
                splashPaths.includes(location.pathname) ||
                authPaths.includes(location.pathname)
              }
            />
            <SegmentAnalytics />
          </>
        ) : (
          ''
        )}

        <div className="main">
          {showLoadingPage && !authPaths.includes(location.pathname) && (
            <>
              <LoadingPage />
              <RushLoading />
            </>
          )}
          {!showLoadingPage &&
            !approvalRequiredPaths.includes(location.pathname) &&
            !splashPaths.includes(location.pathname) &&
            !authPaths.includes(location.pathname) && (
              <SudoBanner user={user} />
            )}
          {!isTokenLoading && !isCheckingQuestionnaire && (
            <>
              <ScrollToTop />
              <AppRoutes isLoading={showLoadingPage} />
            </>
          )}
        </div>
        {toastMarkup}
      </Frame>
    );
  };

  return isEmbededApp ? (
    <AppBridgeProvider>
      <PolarisProvider>
        <QueryProvider>
          {renderRushApp()}
          {renderEmpededNavBar()}
        </QueryProvider>
      </PolarisProvider>
    </AppBridgeProvider>
  ) : (
    <PolarisProvider>{renderRushApp()}</PolarisProvider>
  );
}

export default App;
