import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { QuestionnairePage } from '@rush-app-admin/storybooks';
import { useLocation, useNavigate } from 'react-router-dom';
import { ClientStorage } from 'ClientStorage';
import { LOCAL_STORAGE } from '../../../Constants';
import i18next from 'i18next';
import {
  useGetQuestionnaireQuery,
  useUpdateQuestionnaireMutation,
} from '../questionnaireApi';
import {
  useUpdateUserLocaleMutation,
  useGetUserLanguagesQuery,
} from 'redux/store/commonBaseApis';
import { useSelector } from 'react-redux';
import RushLoading from 'components/custom/RushLoader';
import useIsEmbededApp from 'hooks/useIsEmbededApp';
import { AccessToken } from 'pages/Visitor/visitorSlice';

export default function Questionnaire() {
  const [t] = useTranslation();
  const [count, setCount] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const isEmbededApp = useIsEmbededApp();
  const accessToken = useSelector(AccessToken);

  const storeUUID = useSelector((state) => state.shop?.storeUUID);
  const [selectedLang, setSelectedLang] = useState(i18next.language);
  const [updateUserLocale, { isLoading: isUpdatingLanguage }] =
    useUpdateUserLocaleMutation();
  const { data: languages = [], isLoading: isLoadingLanguages } =
    useGetUserLanguagesQuery(undefined, { skip: !accessToken });

  const {
    data: questionnaire = [],
    isFetching: questionnaireLoading,
    refetch,
  } = useGetQuestionnaireQuery('');

  useEffect(() => {
    if (selectedLang !== i18next.language) {
      refetch();
      setSelectedLang(i18next.language);
    }
  }, [i18next.language]);

  const [updateQuestionnaire] = useUpdateQuestionnaireMutation();

  const request = useRef({
    answers: {
      questionnaire: {
        version: 0,
        max_step: 0,
        current_step: 0,
        completed: false,
        answers: {},
      },
    },
  });

  useEffect(() => {
    if (!questionnaire) {
      return;
    }
    request.current.answers.questionnaire = {
      ...request.current.answers.questionnaire,
      version: questionnaire.version,
      max_step: questionnaire.questions.length,
      current_step: count + 1,
    };
  }, [questionnaire]);

  const handleNextClick = (data) => {
    request.current.answers.questionnaire = {
      ...request.current.answers.questionnaire,
      current_step: count + 1,
      completed: count + 1 >= questionnaire.questions.length,
      answers: {
        ...request.current.answers.questionnaire.answers,
        [questionnaire.questions[count].id]: data,
      },
    };

    if (count + 1 >= questionnaire.questions.length) {
      if (location.search.includes(storeUUID) && storeUUID) {
        request.current.store_uuid = storeUUID;
      }
      updateQuestionnaire(request.current);
      const navigationPath = storeUUID ? `/s/${storeUUID}` : `/s`;
      navigate(`${navigationPath}`, { replace: true });
    }

    setCount(count + 1);
  };

  let translations;
  try {
    translations = require(`@shopify/polaris/locales/${selectedLang}.json`);
  } catch (e) {
    translations = require('@shopify/polaris/locales/en.json');
  }

  const lang = useMemo(() => {
    return (
      (languages || []).find(
        ({ language_iso_639 }) => language_iso_639 === selectedLang
      ) || languages.find(({ language_iso_639 }) => language_iso_639 === 'en')
    );
  }, [selectedLang, languages]);

  const languagesList = useMemo(() => {
    if (isEmbededApp) {
      return undefined;
    } else {
      return (
        languages?.map((lang) => ({
          uuid: lang.uuid,
          label: lang.label_local,
          flagUrl: lang.svg_flag_url,
        })) || []
      );
    }
  }, [isEmbededApp, languages]);

  return questionnaire ? (
    <>
      <QuestionnairePage
        languages={languagesList}
        selectedLanguageUuid={lang?.uuid}
        onChangeLanguage={(uuid) => {
          const newLang = languages.find((lang) => lang.uuid === uuid);
          ClientStorage.set(LOCAL_STORAGE.LANGUAGE, newLang?.language_iso_639);
          i18next.changeLanguage(newLang?.language_iso_639);
          updateUserLocale({ language: newLang?.language_iso_639 });
        }}
        loading={false}
        onNextClick={handleNextClick}
        onSelectAnswer={() => {}}
        questions={questionnaire.questions}
        selectedQuestionIndex={count}
        title={t('questions.title')}
        subtitle={t('questionnaire.subtitle', {
          count: count + 1,
          total: questionnaire.questions.length,
        })}
        actionText={
          count === questionnaire.questions.length - 1
            ? t('questionnaire.action.complete')
            : t('questionnaire.action.next')
        }
        translationFile={translations}
      />
      {isLoadingLanguages || isUpdatingLanguage || questionnaireLoading ? (
        <RushLoading />
      ) : (
        ''
      )}
    </>
  ) : (
    <RushLoading />
  );
}
