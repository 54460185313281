import { Box, Banner, Text } from '@shopify/polaris';
import React from 'react';

function SudoBanner({ user }) {
  return user.sudo ? (
    <Box padding={{ sm: '0', md: '5' }} paddingBlockEnd={'0'}>
      <Banner title={`Sudo session!`} status={'warning'}>
        <Text
          variant="bodyMd"
          as="span"
        >{`${user.sudo.name} with ${user.sudo.email} logged as ${user.first_name} ${user.last_name} with ${user.email}`}</Text>
      </Banner>
    </Box>
  ) : (
    ''
  );
}

export default SudoBanner;
