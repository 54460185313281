import React, { useEffect, useState } from 'react';
import { Button, HorizontalStack, Spinner } from '@shopify/polaris';
import { useGetJobStatusByLinkQuery } from 'redux/store/commonBaseApis';
function ButtonWithJob({
  primary,
  label,
  job_link,
  onClick,
  onJobDone,
  skipCall,
  skipLoader,
}) {
  const [isJobDone, setIsJobDone] = useState(false);
  const jobStatusResponse = useGetJobStatusByLinkQuery(job_link, {
    skip: isJobDone || !job_link || skipCall,
    pollingInterval: 5000,
  });
  const { data: jobData = {}, isSuccess, isLoading } = jobStatusResponse || {};

  useEffect(() => {
    if (job_link) {
      setIsJobDone(false);
    }
  }, [job_link]);
  useEffect(() => {
    if (!isLoading && isSuccess && jobData.status === 'finished') {
      setIsJobDone(true);
      onJobDone(jobData);
    }
  }, [isLoading, isSuccess, jobData]);
  return (
    <Button primary={primary} onClick={() => onClick(isLoading)}>
      <HorizontalStack blockAlign="center" gap={'1'}>
        {!isJobDone && job_link && !skipLoader ? <Spinner size="small" /> : ''}
        {label}
      </HorizontalStack>
    </Button>
  );
}

export default ButtonWithJob;
