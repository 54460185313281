import {
  Form,
  FormLayout,
  Modal,
  VerticalStack,
  TextField,
  Text,
} from '@shopify/polaris';
import DatePickerButton from 'components/DatePicker/DatePickerButton';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import RushModal from 'components/custom/RushModal';
function NonShippingModal({ values = {}, onChange, onClose }) {
  const [t] = useTranslation();
  const [showNoteError, setShowNoteError] = useState(false);
  const [formData, setFormData] = useState({
    from: moment()._d,
    to: moment()._d,
    note: '',
  });

  useEffect(() => {
    if (Object.keys(values).length)
      setFormData({
        ...values,
        from: moment(values.from)._d,
        to: moment(values.to)._d,
      });
  }, [values]);

  const handleChange = (value, key) => {
    if (key === 'note') {
      setShowNoteError(false);
    }
    if (key === 'range') {
      setFormData((data) => {
        return { ...data, from: value.start, to: value.end };
      });
    } else {
      setFormData((data) => {
        return { ...data, [key]: value };
      });
    }
  };

  const onSave = () => {
    if (formData.note.trim()) {
      onChange({
        from: moment(formData.from).format('YYYY-MM-DD'),
        to: moment(formData.to).format('YYYY-MM-DD'),
        note: formData.note,
      });
    } else {
      setShowNoteError(true);
    }
  };

  return (
    <RushModal
      open={true}
      title={t('smart-edd.non-shipping-title')}
      onClose={onClose}
      primaryAction={{
        content: t('common.save'),
        onAction: onSave,
      }}
      secondaryActions={[
        {
          content: t('common.discard'),
          onAction: onClose,
        },
      ]}
    >
      <Modal.Section>
        <Form>
          <FormLayout>
            <FormLayout.Group condensed>
              <VerticalStack gap="1">
                <Text variant="bodyMd" as="span">
                  {t('common.interval')}
                </Text>
                <DatePickerButton
                  range={true}
                  label={t('common.interval')}
                  value={{ start: formData.from, end: formData.to }}
                  onChange={(value) => handleChange(value, 'range')}
                />
              </VerticalStack>
            </FormLayout.Group>
            <TextField
              autoComplete={'off'}
              label={t('smart-edd.non-shipping-dropdown-title')}
              onChange={(value) => handleChange(value, 'note')}
              value={formData.note}
              helpText={t('smart-edd.non-shipping.note.help')}
              error={
                showNoteError
                  ? t('common.field-required', {
                      field_name: t('smart-edd.non-shipping-dropdown-title'),
                    })
                  : ''
              }
            />
          </FormLayout>
        </Form>
      </Modal.Section>
    </RushModal>
  );
}

export default NonShippingModal;
