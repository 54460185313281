import {
  Layout,
  Card,
  SkeletonBodyText,
  VerticalStack,
  SkeletonDisplayText,
  Box,
  Divider,
  HorizontalStack,
} from '@shopify/polaris';
import RushPage from 'components/custom/RushPage';
import { STORE_URLS } from 'config/urls';
import React from 'react';
import { useParams } from 'react-router-dom';

export default function SipmentsDetailSkelton() {
  const params = useParams();
  return (
    <RushPage
      title={<SkeletonDisplayText />}
      secondaryActions={
        <HorizontalStack gap={2}>
          <Box width="100px">
            <SkeletonDisplayText />
          </Box>
          <Box width="100px">
            <SkeletonDisplayText />
          </Box>
        </HorizontalStack>
      }
      fullWidth={false}
      narrowWidth={false}
      backAction={{
        url: `/s/${params.uuid}${STORE_URLS.SHIPMENTS}`,
      }}
    >
      <Layout>
        <Layout.Section>
          <VerticalStack gap={4}>
            <Card roundedAbove="sm" padding={0}>
              <Box padding={4}>
                <VerticalStack gap={'5'}>
                  <Box>
                    <SkeletonBodyText lines={2} />
                  </Box>
                  <Box>
                    <SkeletonBodyText lines={2} />
                  </Box>
                </VerticalStack>
              </Box>

              <Divider />

              <Box padding={4}>
                <VerticalStack gap={'5'}>
                  <Box>
                    <SkeletonBodyText lines={10} />
                  </Box>
                </VerticalStack>
              </Box>
            </Card>

            {/* <Box padding={4}>
              <VerticalStack gap={'5'}>
                <Box>
                  <SkeletonBodyText lines={4} />
                </Box>
              </VerticalStack>
            </Box> */}
          </VerticalStack>
        </Layout.Section>
        <Layout.Section secondary>
          <VerticalStack gap={4}>
            <Card roundedAbove="sm">
              <Box>
                <VerticalStack gap="4">
                  <SkeletonDisplayText size="small" />
                  <Box>
                    <SkeletonBodyText lines={1} />
                  </Box>
                </VerticalStack>
              </Box>
            </Card>
            <Card roundedAbove="sm">
              <Box>
                <VerticalStack gap="4">
                  <SkeletonDisplayText size="small" />
                  <Box>
                    <SkeletonBodyText lines={2} />
                  </Box>
                </VerticalStack>
              </Box>
            </Card>
            <Card roundedAbove="sm">
              <Box>
                <VerticalStack gap="4">
                  <SkeletonDisplayText size="small" />
                  <Box>
                    <SkeletonBodyText lines={2} />
                  </Box>
                </VerticalStack>
              </Box>
            </Card>

            <Card roundedAbove="sm">
              <VerticalStack gap={'4'}>
                <Box>
                  <SkeletonBodyText lines={6} />
                </Box>
                <Divider />
                <Box>
                  <SkeletonBodyText lines={6} />
                </Box>
                <Divider />
                <Box>
                  <SkeletonBodyText lines={6} />
                </Box>
                <Divider />
                <Box>
                  <SkeletonBodyText lines={6} />
                </Box>
                <Divider />
                <Box>
                  <SkeletonBodyText lines={3} />
                </Box>
              </VerticalStack>
            </Card>
            <VerticalStack gap={4}>
              <Card roundedAbove="sm">
                <Box>
                  <VerticalStack gap="4">
                    <SkeletonDisplayText size="small" />
                    <Box>
                      <SkeletonBodyText lines={4} />
                    </Box>
                  </VerticalStack>
                </Box>
              </Card>
              <Card roundedAbove="sm">
                <Box>
                  <VerticalStack gap="4">
                    <SkeletonDisplayText size="small" />
                    <Box>
                      <SkeletonBodyText lines={2} />
                    </Box>
                  </VerticalStack>
                </Box>
              </Card>
              <Divider />
            </VerticalStack>
          </VerticalStack>
        </Layout.Section>
      </Layout>
    </RushPage>
  );
}
