import { connect } from 'react-redux';
import TrackingManager from './TrackingManager';

function mapStateToProps(state) {
  return {
    base: state.base,
    accessToken: state.visitor.accessToken,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(TrackingManager);
