import React, { useCallback, useEffect, useMemo, useState } from 'react';
import SmallTopMessage from 'components/SmallTopMessage';
import {
  Button,
  ButtonGroup,
  Icon,
  SkeletonBodyText,
  Text,
  InlineCode,
  VerticalStack,
  Card,
  HorizontalStack,
  Box,
  Divider,
  ChoiceList,
} from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import {
  ArrowDownMinor,
  ArrowUpMinor,
  DeleteMinor,
  EditMinor,
} from '@shopify/polaris-icons';
import ShipmentSettingRuleModal from './ShipmentSettingRuleModal';
import TranslateLink from 'components/TranslateLink';
import FulfillmentSettings from './FulfillmentSettings';
import UpdateFrequency from './UpdateFrequency';

import {
  useGetFulfilmentPreferencesQuery,
  useGetRulesQuery,
  useGetShipmentSettingsQuery,
  usePickTriggerMutation,
  useUpdateFulfilmentPreferencesMutation,
  useUpdateRulesMutation,
  useUpdateShipmentSettingsMutation,
} from '../ShipmentSettingsApi';
import isEqual from 'lodash/isEqual';
import { useGetCountriesQuery } from 'redux/store/commonStoreApis';
import './styles.scss';
import { useAllCarriersQuery } from 'redux/store/commonBaseApis';
import Subtitle from 'components/Subtitle';
import { baseActions } from 'redux/store/baseSlice';
import { useDispatch } from 'react-redux';
import RushLoading from 'components/custom/RushLoader';
import RushSaveBar from 'components/custom/RushSaveBar';
import RushPage from 'components/custom/RushPage';
import RushFooter from 'components/custom/RushFooter';
import { STORE_URLS } from 'config/urls';
import { useParams } from 'react-router-dom';
import useIsEmbededApp from 'hooks/useIsEmbededApp';
export default function ShipmentSettings() {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const params = useParams();
  const isEmbededApp = useIsEmbededApp();
  const [pickTrigger, pickTriggerResponse] = usePickTriggerMutation();

  const [togglePreference, toggleResponse] =
    useUpdateFulfilmentPreferencesMutation();
  const isConfigSaving = useMemo(() => {
    return pickTriggerResponse.isLoading || toggleResponse.isLoading;
  }, [pickTriggerResponse, toggleResponse]);
  const isConfigSaved = useMemo(() => {
    return pickTriggerResponse.isSuccess || toggleResponse.isSuccess;
  }, [pickTriggerResponse, toggleResponse]);

  const [saveRules, rulesMutationResponse] = useUpdateRulesMutation();
  const { isLoading: isRulesSaving, isSuccess: isRulesSaved } =
    rulesMutationResponse;

  const [saveCheckpointConfig, saveCheckpointConfigResponse] =
    useUpdateShipmentSettingsMutation();
  const { isLoading: isCheckpointSaving, isSuccess: isCheckpointSaved } =
    saveCheckpointConfigResponse;
  const carriersResponse = useAllCarriersQuery();
  const { data: carriers = [], isLoading: isCarriersLoading } =
    carriersResponse;

  const locationsResponse = useGetCountriesQuery();
  const { data = [], isLoading: isLocationsLoading } = locationsResponse;
  const locationsData = data.filter((geo = {}) => geo.type === 'country') || [];
  const [showSuccessToast, setShowSuccessToast] = useState(false);

  useEffect(() => {
    (isConfigSaved || isRulesSaved || isCheckpointSaved) &&
      setShowSuccessToast(true);
  }, [isConfigSaved, isRulesSaved, isCheckpointSaved]);

  useEffect(() => {
    showSuccessToast &&
      dispatch(
        baseActions.setToastMessage({
          message: t('shipments.settings_page.save_message'),
          type: 'success',
        })
      );
  }, [showSuccessToast]);

  const countriesList = useMemo(() => {
    return locationsData.map((item) => {
      return {
        label: item.label,
        value: item.loc_id,
      };
    });
  }, [locationsData]);

  const carriersList = useMemo(() => {
    return carriers.map((item) => {
      return {
        label: item.title,
        value: item.uuid,
      };
    });
  }, [carriers]);

  const [rules, setRules] = useState([]);
  const [processAddressFlag, setProcessAddressFlag] = useState(false);
  const [selectedFullFillSetting, setSelectedFullFillSetting] = useState('');
  const [isEnabled, setIsEnabled] = useState(false);
  const [editRuleIndex, setEditRuleIndex] = useState(-1);
  const [showModal, setShowModal] = useState(false);

  const checkpointResponse = useGetShipmentSettingsQuery();
  const {
    data: checkpointData = {},
    isLoading: ischeckpointLoading,
    isFetching: ischeckpointFetching,
    isSuccess: ischeckpointLoaded,
  } = checkpointResponse;

  useEffect(() => {
    if (!ischeckpointFetching && ischeckpointLoaded) {
      setProcessAddressFlag(checkpointData.process_address_flag);
    }
  }, [ischeckpointFetching, ischeckpointLoaded]);

  const {
    data: fulfillmentSettings = {},
    isLoading: isPreferencesLoading,
    isFetching: isPreferencesFetching,
    isSuccess: isPreferencesLoaded,
  } = useGetFulfilmentPreferencesQuery();

  useEffect(() => {
    if (isPreferencesLoaded && !isPreferencesFetching) {
      const selected = fulfillmentSettings.triggers?.find(
        (t) => t.is_enabled
      )?.id;
      setSelectedFullFillSetting(selected);
      setIsEnabled(fulfillmentSettings.is_active);
    }
  }, [isPreferencesLoaded, isPreferencesFetching]);

  const rulesResponse = useGetRulesQuery();
  const {
    data: rulesResData,
    isLoading: isRulesLoading,
    isFetching: isRulesFetching,
    isSuccess: isRulesLoaded,
  } = rulesResponse;

  useEffect(() => {
    if (isRulesLoaded && rulesResData) {
      setRules(rulesResData?.rules);
    }
  }, [isRulesLoaded, rulesResData]);

  const toggleModal = () => {
    setShowModal((show) => !show);
  };

  const editRule = (index) => {
    setEditRuleIndex(index);
    toggleModal();
  };

  const deleteRule = (index) => {
    setRules((rules) => {
      let updatedRules = [...rules];
      updatedRules.splice(index, 1);
      return updatedRules;
    });
  };

  const moveRule = (index, direction) => {
    try {
      setRules((rules) => {
        let updatedRules = [...rules];
        const rule = updatedRules.splice(index, 1)[0];
        if (direction === 'down') {
          updatedRules.splice(index + 1, 0, rule);
        } else if (direction === 'up') {
          updatedRules.splice(index - 1, 0, rule);
        }
        return updatedRules;
      });
    } catch {
      console.log('error in moveRule');
    }
  };

  const handleFulFillSettingChange = useCallback(([value]) => {
    setSelectedFullFillSetting(value);
  }, []);

  const togglePreferences = useCallback((checked) => {
    setIsEnabled(checked);
  }, []);

  const onAddNew = () => {
    setEditRuleIndex(-1);
    toggleModal();
  };

  const handleAddUpdateRule = (rule) => {
    if (editRuleIndex !== -1) {
      setRules((rules) => {
        let updatedRules = [...rules];
        updatedRules.splice(editRuleIndex, 1, { ...rule });
        return updatedRules;
      });
      setEditRuleIndex(null);
    } else {
      setRules((rules = []) => {
        return [...rules].concat(rule);
      });
    }
    toggleModal();
  };

  const isCheckPointsDirty = useMemo(() => {
    if (ischeckpointLoaded) {
      return processAddressFlag !== checkpointData.process_address_flag;
    } else {
      return false;
    }
  }, [ischeckpointLoaded, checkpointData, processAddressFlag]);

  const isRulesDirty = useMemo(() => {
    if (isRulesLoaded) {
      return !isEqual(rules, rulesResData?.rules);
    } else {
      return false;
    }
  }, [isRulesLoaded, rulesResData, rules]);

  const isConfigDirty = useMemo(() => {
    if (isPreferencesLoaded) {
      const selected = fulfillmentSettings.triggers?.find(
        (t) => t.is_enabled
      )?.id;
      return selectedFullFillSetting !== selected;
    } else {
      return false;
    }
  }, [isPreferencesLoaded, fulfillmentSettings, selectedFullFillSetting]);

  const isToggled = useMemo(() => {
    if (isPreferencesLoaded) {
      return isEnabled !== fulfillmentSettings.is_active;
    } else {
      return false;
    }
  }, [isEnabled, fulfillmentSettings]);

  const isDirtyState = useMemo(() => {
    try {
      return isToggled || isConfigDirty || isRulesDirty || isCheckPointsDirty;
    } catch {
      return false;
    }
  }, [isToggled, isConfigDirty, isRulesDirty, isCheckPointsDirty]);

  const handleSave = useCallback(() => {
    if (isToggled) {
      togglePreference(isEnabled ? 'activate' : 'deactivate');
    }
    if (isRulesDirty) {
      saveRules(rules);
    }
    if (isConfigDirty) {
      pickTrigger({
        configuration_id: fulfillmentSettings.id,
        trigger_id: selectedFullFillSetting,
      });
    }
    if (isCheckPointsDirty) {
      saveCheckpointConfig({ process_address_flag: processAddressFlag });
    }
  }, [
    rules,
    selectedFullFillSetting,
    isRulesDirty,
    isConfigDirty,
    isCheckPointsDirty,
    processAddressFlag,
    isToggled,
  ]);

  const handleDiscard = useCallback(() => {
    try {
      const selected = fulfillmentSettings.triggers?.find(
        (t) => t.is_enabled
      )?.id;

      setSelectedFullFillSetting(selected);
      setIsEnabled(fulfillmentSettings.is_active);
      setRules(rulesResData?.rules);
      setProcessAddressFlag(checkpointData.process_address_flag);
    } catch {
      return false;
    }
  }, [fulfillmentSettings, rulesResData, checkpointData]);

  const saveBarMarkup = useMemo(
    () =>
      isDirtyState &&
      !isPreferencesFetching &&
      !isRulesFetching &&
      !ischeckpointFetching ? (
        <RushSaveBar
          message={t('carrier-maskings.unsaved-changes')}
          saveAction={{
            content: t('carrier-maskings.save'),
            onAction: handleSave,
            loading: isRulesSaving || isConfigSaving || isCheckpointSaving,
          }}
          discardAction={{
            onAction: handleDiscard,
            loading: isRulesSaving || isConfigSaving || isCheckpointSaving,
            content: t('common.discard'),
          }}
        />
      ) : (
        ''
      ),
    [
      isDirtyState,
      isRulesSaving,
      isCheckpointSaving,
      isConfigSaving,
      isPreferencesFetching,
      isRulesFetching,
      handleSave,
      handleDiscard,
    ]
  );

  const rulesListMarkup = useMemo(() => {
    return rules?.map((item, index) => {
      const selectedCarrier =
        carriersList.find((c) => c.value === item.carrier_uuid) || {};
      const countryNames = countriesList
        .filter((c) => item?.countries?.includes(c.value))
        .map((c) => c.label);
      return (
        <div className="SearchReplace-List" key={item.regex}>
          <div className="SearchReplace-List-Heading">
            <VerticalStack gap={5}>
              <Text variant="bodyMd" as="span">
                {t('shipments.settings_page.tracking_number_is')}{' '}
                <Text variant="bodyMd" as="span">
                  <InlineCode>{item.regex}</InlineCode>
                </Text>
                {countryNames.length > 0 ? (
                  <p>
                    {countryNames.length === 1
                      ? t('shipments.settings_page.singular_destination')
                      : t('shipments.settings_page.plural_destination')}{' '}
                    <Text
                      variant="bodyMd"
                      as="span"
                      fontWeight="semibold"
                    >{`${countryNames.join(', ')}`}</Text>
                  </p>
                ) : (
                  ''
                )}
                <p>
                  {t('shipments.settings_page.assigning_to_carrier')}{' '}
                  <Text
                    variant="bodyMd"
                    as="span"
                    fontWeight="semibold"
                  >{`${selectedCarrier.label}`}</Text>
                </p>
              </Text>
            </VerticalStack>
          </div>
          <div className="SearchReplace-List-Buttons">
            <ButtonGroup segmented>
              <Button
                size="slim"
                onClick={() => moveRule(index, 'up')}
                icon={<Icon source={ArrowUpMinor} />}
                disabled={index === 0}
              />
              <Button
                size="slim"
                onClick={() => moveRule(index, 'down')}
                icon={<Icon source={ArrowDownMinor} />}
                disabled={index === rules?.length - 1}
              />
              <Button
                size="slim"
                onClick={() => editRule(index)}
                icon={<Icon source={EditMinor} />}
              />
              <Button
                size="slim"
                onClick={() => deleteRule(index)}
                icon={<Icon source={DeleteMinor} />}
              />
            </ButtonGroup>
          </div>
        </div>
      );
    });
  }, [rules, carriersList, countriesList]);

  const toggleCheckpoints = ([value]) => {
    setProcessAddressFlag(value === 'retain_info');
  };

  return (
    <>
      <SmallTopMessage />

      <RushPage
        title={t('shipment_settings_new.page_title')}
        backAction={
          isEmbededApp
            ? {
                url: `/s/${params.uuid}${STORE_URLS.SHIPMENTS}`,
              }
            : undefined
        }
        subtitle={
          <Subtitle
            text={t('shipment_settings_new.sub_title')}
            actions={[
              {
                text: t('common.get-started'),
                source: t('shipment_settings_new.get_started_link'),
              },
            ]}
          />
        }
      >
        <VerticalStack gap={'5'}>
          <Card roundedAbove="sm" padding={0}>
            <Box padding="5">
              <VerticalStack gap={'2'}>
                <HorizontalStack align="space-between">
                  <Text as="h4" variant="headingMd">
                    {t('shipment_settings_new.carrier_assigning.card_title')}
                  </Text>
                  <Button onClick={onAddNew} plain>
                    {t('shipment_settings_new.carrier_assigning.button')}
                  </Button>
                </HorizontalStack>
                <Text variant="bodyMd" as="span" color="subdued">
                  <TranslateLink
                    text={t(
                      'shipment_settings_new.carrier_assigning.card_description'
                    )}
                  />
                </Text>
              </VerticalStack>
            </Box>
            <Divider />
            {isCarriersLoading || isLocationsLoading || isRulesLoading ? (
              <Box padding={'5'}>
                <SkeletonBodyText />
              </Box>
            ) : (
              <>
                {rules && rules.length > 0 && rulesListMarkup}

                {rules && rules.length === 0 && (
                  <Box padding={'6'}>
                    <div className="CustomEmptyState">
                      <Text variant="bodyMd" as="span" color="subdued">
                        {t(
                          'shipments.settings_page.carrier_assigning.no_rules'
                        )}
                      </Text>
                    </div>
                  </Box>
                )}
                {!rules && (
                  <Box padding={'6'}>
                    <div className="CustomEmptyState">
                      <Text variant="bodyMd" as="span" color="subdued">
                        {t('shipments.settings_page.carrier_assigning.loading')}
                      </Text>
                    </div>
                  </Box>
                )}
              </>
            )}
          </Card>
          <Card roundedAbove="sm">
            <VerticalStack gap={'5'}>
              <VerticalStack gap={'2'}>
                <Text as="h4" variant="headingMd">
                  {t('shipment_settings_new.checkpoint.card_title')}
                </Text>

                <Text as="span" variant="bodyMd" color="subdued">
                  <TranslateLink
                    text={t(
                      'shipment_settings_new.checkpoint.card_description'
                    )}
                  />
                </Text>
              </VerticalStack>
              {ischeckpointLoading ? (
                <Box>
                  <SkeletonBodyText />
                </Box>
              ) : (
                <div className="small-gap-list">
                  <ChoiceList
                    title=""
                    choices={[
                      {
                        label: t('shipment_settings_new.checkpoint.card_opt1'),
                        value: 'ignore_info',
                      },
                      {
                        label: t('shipment_settings_new.checkpoint.card_opt2'),
                        value: 'retain_info',
                      },
                    ]}
                    selected={
                      processAddressFlag ? 'retain_info' : 'ignore_info'
                    }
                    onChange={toggleCheckpoints}
                  />
                </div>
              )}
            </VerticalStack>
          </Card>
          <FulfillmentSettings
            selectedOption={selectedFullFillSetting}
            isLoading={isPreferencesLoading}
            fulfillmentSettings={fulfillmentSettings}
            isEnabled={isEnabled}
            togglePreferences={togglePreferences}
            onChangeSetting={handleFulFillSettingChange}
          />

          <UpdateFrequency
            frequencyRate={checkpointData.shipments_refresh_hourly_rate}
          />

          <RushFooter>
            <TranslateLink text={t('footer_help.dashboard')} />
          </RushFooter>
        </VerticalStack>
      </RushPage>
      {showModal && (
        <ShipmentSettingRuleModal
          rule={rules[editRuleIndex]}
          onSave={handleAddUpdateRule}
          onClose={toggleModal}
          countriesList={countriesList}
          carriersList={carriersList}
        />
      )}
      {saveBarMarkup}
      {isPreferencesFetching && isRulesFetching && ischeckpointFetching ? (
        <RushLoading />
      ) : (
        ''
      )}
    </>
  );
}
