import React from 'react';
import {
  SkeletonPage,
  Card,
  SkeletonBodyText,
  VerticalStack,
  Box,
} from '@shopify/polaris';

function SkeletonBlackListPage() {
  return (
    <SkeletonPage>
      <VerticalStack gap="4">
        <Card roundedAbove="sm">
          <VerticalStack gap="2">
            <Box>
              <SkeletonBodyText />
            </Box>
          </VerticalStack>
          <br />
          <VerticalStack gap="2">
            <Box>
              <SkeletonBodyText />
            </Box>
          </VerticalStack>
        </Card>
        <Card roundedAbove="sm">
          <VerticalStack gap="2">
            <Box>
              <SkeletonBodyText />
            </Box>
          </VerticalStack>
          <br />
          <VerticalStack gap="2">
            <Box>
              <SkeletonBodyText />
            </Box>
          </VerticalStack>
        </Card>
        <Card roundedAbove="sm">
          <VerticalStack gap="2">
            <Box>
              <SkeletonBodyText />
            </Box>
          </VerticalStack>
          <br />
          <VerticalStack gap="2">
            <Box>
              <SkeletonBodyText />
            </Box>
          </VerticalStack>
        </Card>
      </VerticalStack>
    </SkeletonPage>
  );
}
export default SkeletonBlackListPage;
