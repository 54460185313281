import React from 'react';
import {
  Layout,
  SkeletonBodyText,
  SkeletonDisplayText,
  VerticalStack,
} from '@shopify/polaris';
import '../style.scss';
import LoadingCard from './LoadingCard';

export default function LoadingLayoutPage() {
  return (
    <Layout>
      <Layout.AnnotatedSection
        title={<SkeletonDisplayText />}
        description={<SkeletonBodyText />}
      >
        <VerticalStack gap={'5'}>
          <LoadingCard />
          <LoadingCard />
        </VerticalStack>
      </Layout.AnnotatedSection>
      <Layout.AnnotatedSection
        title={<SkeletonDisplayText />}
        description={<SkeletonBodyText />}
      >
        <VerticalStack gap={'5'}>
          <LoadingCard />
          <LoadingCard />
          <LoadingCard />
          <LoadingCard />
        </VerticalStack>
      </Layout.AnnotatedSection>
    </Layout>
  );
}
