import {
  Box,
  HorizontalStack,
  Icon,
  Text,
  VerticalStack,
} from '@shopify/polaris';
import React from 'react';
import moment from 'moment';

import { useTranslation } from 'react-i18next';
import { ExternalSmallMinor } from '@shopify/polaris-icons';
import ExternalLink from 'components/ExternalLink';
import { isEmpty } from 'lodash';

function OrderInformation({ order = {} }) {
  const [t] = useTranslation();
  const formatDate = (date) => moment(date).format('ll');

  return isEmpty(order) ? (
    ''
  ) : (
    <Box padding={4}>
      <VerticalStack gap={4}>
        <Text variant="headingMd">
          {t('shipment_details.order_information')}
        </Text>
        <VerticalStack gap={1}>
          {!!order.created_at && (
            <HorizontalStack align="space-between">
              <Text>{t('shipment_details.created_at')}</Text>
              <Text>{formatDate(order.created_at)}</Text>
            </HorizontalStack>
          )}
          {!!order.fulfilled_at_date && (
            <HorizontalStack align="space-between">
              <Text>{t('shipment_details.Fulfilled_at')}</Text>
              <Text>{formatDate(order.fulfilled_at_date)}</Text>
            </HorizontalStack>
          )}
          {!!order.label && (
            <HorizontalStack align="space-between">
              <Text>{t('shipment_details.number')}</Text>
              <HorizontalStack align="space-between">
                <ExternalLink
                  external={false}
                  target="_blank"
                  url={order.order_admin_link}
                >
                  {order.label}
                </ExternalLink>
                <Icon source={ExternalSmallMinor} color={'interactive'} />
              </HorizontalStack>
            </HorizontalStack>
          )}
          {!!order.order_customer_link && (
            <HorizontalStack align="space-between">
              <Text>{t('shipment_details.order_status_page')}</Text>
              <HorizontalStack>
                <ExternalLink
                  external={true}
                  target="_blank"
                  url={order.order_customer_link}
                >
                  Link
                </ExternalLink>
                <Icon source={ExternalSmallMinor} color={'interactive'} />
              </HorizontalStack>
            </HorizontalStack>
          )}
          <HorizontalStack align="space-between">
            <Text>{t('shipment_details.total_value')}</Text>
            <Text>{order.order_total_price}</Text>
          </HorizontalStack>
        </VerticalStack>
      </VerticalStack>
    </Box>
  );
}
export default OrderInformation;
