import {
  Box,
  Button,
  HorizontalStack,
  Icon,
  Link,
  Text,
  Tooltip,
  VerticalStack,
} from '@shopify/polaris';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import RenderBadge from './RenderBadge';
import { isEmpty } from 'lodash';
import UpdateCarrierModal from 'pages/Shipments3/components/UpdateCarrierModal';
import { ExternalSmallMinor } from '@shopify/polaris-icons';
import ExternalLink from 'components/ExternalLink';
import TranslateLink from 'components/TranslateLink';
import { ViewMinor, HideMinor } from '@shopify/polaris-icons';
import { LOCAL_STORAGE } from 'Constants';

function Carriers({
  shipments = [],
  statuses,
  refetchWithDelay,
  toggleHiddenCarrier,
  hiddenCarrierDetails = [],
}) {
  const [t] = useTranslation();
  const [showCarrierModal, setShowCarrierModal] = useState(false);
  const [shipmentToUpdate, setShipmentToUpdate] = useState();

  const renderMileCarrier = (index) => {
    if (shipments.length === 1) {
      return '';
    } else if (index == 0) {
      return (
        <Text variant="bodySm" color="subdued">
          {'First mile carrier'}
        </Text>
      );
    } else if (index === shipments.length - 1) {
      return (
        <ExternalLink
          url="https://support.rush.app/last-mile-carrier"
          target={'_blank'}
        >
          <Text variant="bodySm" color="subdued">
            {'Last mile carrier'}
          </Text>
        </ExternalLink>
      );
    } else {
      return (
        <Text variant="bodySm" color="subdued">
          {'Next mile carrier'}
        </Text>
      );
    }
  };

  const hasAnyCarrierInfo = (shipments = []) => {
    return shipments.find((shipment) => {
      return !isEmpty(shipment.carrier);
    });
  };

  const renderEmptyState = () => {
    return (
      <VerticalStack>
        <Box padding={4}>
          <TranslateLink text={t('shipment_details.no_carrier_message')} />
        </Box>
        <Box padding={4}>
          <HorizontalStack align="end">
            <Button
              onClick={() => {
                setShipmentToUpdate(
                  shipments?.length ? shipments[0] : undefined
                );
                setShowCarrierModal(true);
              }}
              primary
            >
              {t('shipment_detail.change_carrier.carrier_search_input_label')}
            </Button>
          </HorizontalStack>
        </Box>
      </VerticalStack>
    );
  };

  const renderEditButton = (shipment, index) => {
    const editButton = (
      <Button
        plain
        onClick={() => {
          setShipmentToUpdate(shipment);
          setShowCarrierModal(true);
        }}
      >
        {t('common.edit')}
      </Button>
    );
    let mileCarrier = 'next mile carrier';
    if (index === 0) {
      mileCarrier = 'fisrt mile carrier';
    } else if (index === shipments.length - 1) {
      mileCarrier = 'last mile carrier';
    }
    const storagekey = `${LOCAL_STORAGE.SHIPMENT_DETAILS_CARRIER_HIDDEN}_${
      index + 1
    }`;
    const isHidden = hiddenCarrierDetails.includes(storagekey);
    const showHide = isHidden ? 'Show' : 'Hide';
    const icon = isHidden ? HideMinor : ViewMinor;

    const tooltipContent = `${showHide} ${mileCarrier}`;
    const showHideIcon = shipments?.length > 1 && (
      <Tooltip content={tooltipContent}>
        <i
          style={{ cursor: 'pointer' }}
          onClick={() => {
            toggleHiddenCarrier(storagekey, !isHidden);
          }}
        >
          <Icon source={icon} color={isHidden ? 'subdued' : 'interactive'} />
        </i>
      </Tooltip>
    );

    return (
      <HorizontalStack gap={1} blockAlign="center">
        {showHideIcon}
        {editButton}
      </HorizontalStack>
    );
  };

  return (
    <>
      {!hasAnyCarrierInfo(shipments) ? (
        renderEmptyState()
      ) : shipments.length ? (
        <>
          <Box padding={4}>
            <VerticalStack gap={4}>
              {shipments.map((shipment = {}, index) => {
                const {
                  carrier = {},
                  uuid,
                  status_uuid,
                  sub_status_uuid,
                } = shipment;
                return isEmpty(carrier) ? (
                  ''
                ) : (
                  <HorizontalStack align="space-between" key={uuid}>
                    <Box maxWidth="calc(100% - 50px)">
                      <HorizontalStack
                        gap={2}
                        align="start"
                        blockAlign="center"
                      >
                        <img width="35px" src={carrier.logo_svg} />
                        <VerticalStack gap={1}>
                          <HorizontalStack gap={2}>
                            <Text>{carrier.title}</Text>
                            {shipment.tracking_link ? (
                              <HorizontalStack>
                                <Link
                                  target="_blank"
                                  url={shipment.tracking_link}
                                >
                                  {shipment.tracking_number}
                                </Link>
                                <Icon
                                  source={ExternalSmallMinor}
                                  color={'interactive'}
                                />
                              </HorizontalStack>
                            ) : (
                              <Text>{shipment.tracking_number}</Text>
                            )}
                            <RenderBadge
                              status_uuid={status_uuid}
                              statuses={statuses}
                            />
                            <RenderBadge
                              status_uuid={status_uuid}
                              sub_status_uuid={sub_status_uuid}
                              statuses={statuses}
                            />
                          </HorizontalStack>
                          {renderMileCarrier(index)}
                        </VerticalStack>
                      </HorizontalStack>
                    </Box>

                    {renderEditButton(shipment, index)}
                  </HorizontalStack>
                );
              })}
            </VerticalStack>
          </Box>
        </>
      ) : (
        ''
      )}
      {showCarrierModal && (
        <UpdateCarrierModal
          initializer={'shipment_detail'}
          shipmentTrackNumber={shipmentToUpdate?.tracking_number}
          shipmentCarrier={shipmentToUpdate?.carrier?.slug}
          shipmentIds={[shipmentToUpdate.uuid]}
          onClose={({ isUpdated } = {}) => {
            setShowCarrierModal(false);
            if (isUpdated) {
              refetchWithDelay();
            }
          }}
        />
      )}
    </>
  );
}

export default Carriers;
