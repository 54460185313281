import React, {
  useCallback,
  useEffect,
  useReducer,
  useState,
  useMemo,
} from 'react';
import {
  Tooltip,
  Icon,
  ChoiceList,
  Badge,
  Text,
  RangeSlider,
  HorizontalStack,
  useIndexResourceState,
  Box,
} from '@shopify/polaris';
import {
  ClipboardMinor,
  ExportMinor,
  OrdersMajor,
  RefreshMajor,
  RiskMinor,
  NotificationMajor,
} from '@shopify/polaris-icons';
import { useTranslation } from 'react-i18next';
import sortBy from 'lodash/sortBy';
import SmallTopMessage from 'components/SmallTopMessage';
import TranslateLink from 'components/TranslateLink';
import Subtitle from 'components/Subtitle';
import ExternalLink from 'components/ExternalLink';
import { SearchableChoiceList } from '@rush-app-admin/storybooks';
import _ from 'lodash';
import './style.scss';
import OrderImportModal from 'components/OrderImportModal';
import UpdateCarrierModal from './UpdateCarrierModal';
import {
  getQueryObject,
  getShipmentSortOptions,
  getSubStatusLabelFromId,
  getCarrierNameFromUuid,
  formatDate,
  getQueryString,
  getCarrierChoices,
  getHumanDate,
  getStatusLabelFromId,
  getStatusIdFromUuid,
  getStatusIdFromSubStatusUuid,
  MIN_DAYS_PASSED_WITHOUT_CARRIER_UPDATE,
  MAX_DAYS_PASSED_WITHOUT_CARRIER_UPDATE,
  MIN_TRANSIT_DAYS,
  MAX_TRANSIT_DAYS,
  getColumns,
  getTextForFilterTag,
  DEFAULT_SHIPMENT_SORT_OPTION,
  COLUMN_MAPPINGS,
  getSearchCategories,
  getFlagFilterOptions,
  getCountryNameFromUUID,
} from './helpers';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  DATE_RANGE_VALUE_CUSTOM,
  DATE_RANGE_VALUE_LAST_60_DAYS,
  LOCAL_STORAGE,
} from 'Constants';
import { ClientStorage } from 'ClientStorage';
import DateRangeList from 'components/DatePicker/DateRangeList';
import {
  useDeleteShipments3Mutation,
  useGetShipments3Query,
  useLazyExportShipments3Query,
  useMarkAsDelivered3Mutation,
  useReTrackShipments3Mutation,
  useReProcessShipments3Mutation,
  useMuteNotificationsMutation,
  useGetShipment3CarriersQuery,
  useGetStoreFirstMileCarriersQuery,
  useGetStoreLastMileCarriersQuery,
  useGetStoreDestinationsQuery,
} from '../shipmentsApi';
import { useDispatch, useSelector } from 'react-redux';
import {
  GlobalSearch,
  shipmentsActions,
  ShipmentsQueryString,
} from '../shipmentSlice';
import { baseActions } from 'redux/store/baseSlice';
import {
  useGetShipmentStatusesQuery,
  useGetUserDetailsQuery,
} from 'redux/store/commonBaseApis';
import { getShipmentBadge } from 'Utils/BadgeUtils';
import TabsTableSkeleton from 'components/Skeletons/TabsTableSkeleton';
import FilterTable from 'components/FilterTable';
import { StrictModeDroppable } from 'components/FilterTable/StrictModeDroppable';
import { DragDropContext } from 'react-beautiful-dnd';
import RushLoading from 'components/custom/RushLoader';
import { shipmentSubItems } from 'components/LeftNavigation/navUtils';
import useGetEmbededHost from 'hooks/useGetEmbededHost';
import RushPage from 'components/custom/RushPage';
import RushFooter from 'components/custom/RushFooter';

export default function Shipments() {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const storeUUID = useSelector((state) => state.shop.storeUUID);
  const host = useGetEmbededHost();

  // Hack to refetch data with alternative option if not found by order_number or tracking_number
  const [isRetried, setIsRetried] = useState(false);

  // State
  const [paramsLoaded, setParamsLoaded] = useState(false);
  const [openOrderImportModal, setOpenOrderImportModal] = useState(false);
  const [tabsList, setTabsList] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [page, setPage] = useState(1);
  const [isDelayedLoading, setIsDelayedLoading] = useState(false);
  const [showCarrierModal, setShowCarrierModal] = useState(false);

  const flagFilterOptions = getFlagFilterOptions(t);
  const shipmentsQueryString = useSelector(ShipmentsQueryString);
  const search = useSelector(GlobalSearch);

  const setSearch = (obj) => {
    dispatch(shipmentsActions.setGlobalSearch(obj));
  };

  const SHIPMENTS_SORT_OPTIONS = getShipmentSortOptions(t);

  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const { data: userInfo = {} } = useGetUserDetailsQuery();

  const [markShipmentAsDelivered, deliveredResponse] =
    useMarkAsDelivered3Mutation();
  const {
    data: markDeliveredData,
    isLoading: isMarkingDelivered,
    isSuccess: isMarkedDelivered,
  } = deliveredResponse;

  const [deleteShipment, deleteResponse] = useDeleteShipments3Mutation();
  const { isLoading: isDeleting, isSuccess: isDeleted } = deleteResponse;

  const [muteNotifications, muteResponse] = useMuteNotificationsMutation();
  const {
    isLoading: isMuting,
    isSuccess: isMuted,
    data: muteMessage,
  } = muteResponse;

  const [reTrackShipment, reTrackShipmentResponse] =
    useReTrackShipments3Mutation();
  const { isLoading: isReTracking, isSuccess: isReTracked } =
    reTrackShipmentResponse;

  const [reProcessShipment, reProcessShipmentResponse] =
    useReProcessShipments3Mutation();
  const {
    data: reprocessRes = {},
    isLoading: isReProcessing,
    isSuccess: isReProcessed,
  } = reProcessShipmentResponse;

  const [exportShipments, exportResponse] = useLazyExportShipments3Query();
  const {
    // data: exportData,
    isFetching: isExporting,
    // isSuccess: isExportSucess,
  } = exportResponse;
  const {
    data: shipmentStatuses = [],
    isFetching: isStatusesLoading,
    isSuccess: isStatusesLoaded,
    refetch: refetchStatuses,
  } = useGetShipmentStatusesQuery();

  const {
    data: carriersRes = [],
    isFetching: isCarriersLoading,
    isSuccess: isCarriersLoaded,
    refetch: refetchShipmentCarriers,
  } = useGetShipment3CarriersQuery();

  const shipmentCarriers = useMemo(() => {
    if (!isCarriersLoading && isCarriersLoaded) {
      return carriersRes.carriers;
    }
    return [];
  }, [carriersRes, isCarriersLoading, isCarriersLoaded]);

  const {
    data: shipmentFirstMileCarriers = {},
    refetch: refetchShipmentFirstMileCarriers,
  } = useGetStoreFirstMileCarriersQuery();
  const {
    data: shipmentLastMileCarriers = [],
    refetch: refetchShipmentLastMileCarriers,
  } = useGetStoreLastMileCarriersQuery();
  const { data: shipmentDestinations = [], isLoading: isCountriesLoading } =
    useGetStoreDestinationsQuery();

  const countriesList = useMemo(() => {
    return sortBy([...shipmentDestinations], 'label').map((item) => {
      return {
        label: item.label,
        value: item.country_uuid,
      };
    });
  }, [shipmentDestinations]);

  const debounceDaysPassedWithoutCarrierUpdate = useCallback(
    _.debounce((value) => {
      setFilters({
        daysPassedWithoutCarrierUpdate: value,
      });
    }, 500),

    []
  );

  const debounceTransitDaysBetween = useCallback(
    _.debounce((value) => {
      setFilters({
        transitDaysBetween: value,
      });
    }, 500),

    []
  );

  useEffect(() => {
    refetchStatuses();
  }, [t]);

  // Handlers
  const handleTabChange = useCallback(
    (index) => {
      setSelectedTab(index);
      handleSubStatusesChange([]);
      if (index === 0) {
        handleStatusesChange([]);
        setFilters({ status: undefined });
        return;
      }
      handleStatusesChange([shipmentStatuses[index - 1].slug]);
    },

    [shipmentStatuses]
  );
  const handlePageChange = useCallback((page) => {
    setPage(page);
    setFilters({ page });
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  // Filters
  const [filters, setFilters] = useReducer(
    (state, newState) => {
      if (!newState.page && paramsLoaded) {
        // When any filter other than page is changed, set the page = 1
        handlePageChange(1);
      }
      return { ...state, ...newState };
    },
    {
      search_category: getSearchCategories(search.text),
      search: search.text,
      sort: DEFAULT_SHIPMENT_SORT_OPTION,
      statuses: [],
      subStatuses: [],
      selectedFlagOptions: [],
      selectedDeliveryDestinations: [],
      firstMileCarriers: [],
      lastMileCarriers: [],
      daysPassedWithoutCarrierUpdate: [
        MIN_DAYS_PASSED_WITHOUT_CARRIER_UPDATE,
        MAX_DAYS_PASSED_WITHOUT_CARRIER_UPDATE,
      ],
      transitDaysBetween: [MIN_TRANSIT_DAYS, MAX_TRANSIT_DAYS],
      ordersCreatedIn: DATE_RANGE_VALUE_LAST_60_DAYS,
      ordersCreatedInFrom: undefined,
      ordersCreatedInTo: undefined,
      page: 1,
    }
  );

  const transformedStorageColumns = (
    ClientStorage.get(LOCAL_STORAGE.SHIPMENT_COLUMNS) || []
  ).map((c) => {
    return {
      ...c,
      title: c.label || c.title,
    };
  });
  // Filters State
  const [columns, setColumns] = useState(
    ClientStorage.get(LOCAL_STORAGE.SHIPMENT_COLUMNS)
      ? transformedStorageColumns
      : getColumns(t)
  );
  const [statuses, setStatuses] = useState([]);
  const [sortByOption, setSortByOption] = useState(
    DEFAULT_SHIPMENT_SORT_OPTION
  );
  const [subStatuses, setSubStatuses] = useState({});
  const [firstMileCarriers, setFirstMileCarriers] = useState([]);
  const [selectedDeliveryDestinations, setSelectedDeliveryDestinations] =
    useState([]);
  const [selectedFlagOptions, setSelectedFlagOptions] = useState([]);
  const [lastMileCarriers, setLastMileCarriers] = useState([]);
  const [daysPassedWithoutCarrierUpdate, setDaysPassedWithoutCarrierUpdate] =
    useState([
      MIN_DAYS_PASSED_WITHOUT_CARRIER_UPDATE,
      MAX_DAYS_PASSED_WITHOUT_CARRIER_UPDATE,
    ]);
  const [transitDaysBetween, setTransitDaysBetween] = useState([
    MIN_TRANSIT_DAYS,
    MAX_TRANSIT_DAYS,
  ]);
  const [ordersCreatedIn, setOrdersCreatedIn] = useState(
    DATE_RANGE_VALUE_LAST_60_DAYS
  );
  const [ordersCreatedInFrom, setOrdersCreatedInFrom] = useState(null);
  const [ordersCreatedInTo, setOrdersCreatedInTo] = useState(null);

  const shipmentsResponse = useGetShipments3Query(
    getQueryString(page, filters, shipmentStatuses, columns),
    {
      skip: _.isEmpty(shipmentStatuses) || !paramsLoaded,
    }
  );

  const {
    data: { shipments = [], meta: shipmentsMeta = {} } = {},
    isLoading: shipmentsLoading,
    isFetching: shipmentsFetching,
    isSuccess: shipmentsFetched,
    refetch: refreshShipments,
  } = shipmentsResponse;

  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange,
    clearSelection,
  } = useIndexResourceState(shipments, {
    resourceIDResolver: (item) => item.uuid,
  });

  useEffect(() => {
    if (!isMarkingDelivered && isMarkedDelivered) {
      dispatch(
        baseActions.setToastMessage({
          message: markDeliveredData.message,
          type: 'success',
        })
      );
      clearSelection();
      refetchShipmentsWithDelay();
    }
  }, [isMarkingDelivered, isMarkedDelivered]);

  useEffect(() => {
    if (!isMarkingDelivered && isMarkedDelivered) {
      dispatch(
        baseActions.setToastMessage({
          message: markDeliveredData.message,
          type: 'success',
        })
      );
      clearSelection();
    }
  }, [isMarkingDelivered, isMarkedDelivered]);

  useEffect(() => {
    if (!isDeleting && isDeleted) {
      dispatch(
        baseActions.setToastMessage({
          message: t('shipments.delete.toster_message'),
          type: 'success',
        })
      );
      clearSelection();
      refetchShipmentsWithDelay();
    }
  }, [isDeleting, isDeleted]);

  useEffect(() => {
    if (!isMuting && isMuted) {
      dispatch(
        baseActions.setToastMessage({
          message: muteMessage?.message,
          type: 'success',
        })
      );
      clearSelection();
      refetchShipmentsWithDelay();
    }
  }, [isMuting, isMuted]);

  useEffect(() => {
    if (!isReTracking && isReTracked) {
      dispatch(
        baseActions.setToastMessage({
          message: t('shipment_detail.retrack.toster_message'),
          type: 'success',
        })
      );
      clearSelection();
      refetchShipmentsWithDelay();
    }
  }, [isReTracking, isReTracked]);

  useEffect(() => {
    if (!isReProcessing && isReProcessed) {
      dispatch(
        baseActions.setToastMessage({
          message: reprocessRes?.message,
          type: 'success',
        })
      );
      clearSelection();
      refetchShipmentsWithDelay();
    }
  }, [isReProcessing, isReProcessed]);

  useEffect(() => {
    const needToRefetch =
      !shipmentsFetching &&
      !isRetried &&
      shipmentsFetched &&
      shipments.length === 0 &&
      (filters.search_category === 'order_number' ||
        filters.search_category === 'tracking_number');
    const updatedSearchCategory =
      filters.search_category === 'order_number'
        ? 'tracking_number'
        : 'order_number';

    if (needToRefetch) {
      setIsRetried(true);
      setFilters({
        ...filters,
        search_category: updatedSearchCategory,
      });
    }
  }, [shipmentsFetched, shipmentsFetching, isRetried]);

  // Filters State Handlers
  const handleColumnsChange = useCallback((values) => {
    setColumns(values);
    ClientStorage.set(LOCAL_STORAGE.SHIPMENT_COLUMNS, values);
  }, []);

  const handleQueryChange = (text) => {
    setSearch({ text });
  };
  const handleSortChange = (value) => {
    setSortByOption(value);
    setFilters({ sort: value });
  };
  const handleStatusesChange = useCallback((value) => {
    setStatuses(value);
    setFilters({ statuses: value });
  }, []);
  const handleSubStatusesChange = (value, name) => {
    const newSubStatuses = { ...subStatuses, [name]: value };
    setSubStatuses(newSubStatuses);
    setFilters({ subStatuses: Object.values(newSubStatuses).flat(1) });
  };
  const handleSubStatusesClear = () => {
    setSubStatuses({});
    setFilters({ subStatuses: [] });
  };
  const handleFirstMileCarriersChange = useCallback((value) => {
    setFirstMileCarriers(value);
    setFilters({ firstMileCarriers: value });
  }, []);

  const handleDeliveryDestinationsChange = useCallback((value) => {
    setSelectedDeliveryDestinations(value);
    setFilters({ selectedDeliveryDestinations: value });
  }, []);

  const handleFlagOptionChange = useCallback((value) => {
    setSelectedFlagOptions(value);
    setFilters({ selectedFlagOptions: value });
  }, []);
  const handleLastMileCarriersChange = useCallback((value) => {
    setLastMileCarriers(value);
    setFilters({ lastMileCarriers: value });
  }, []);
  const handleDaysPassedWithoutCarrierUpdateChange = useCallback(
    (value) => {
      setDaysPassedWithoutCarrierUpdate(value);
      debounceDaysPassedWithoutCarrierUpdate(value);
    },
    [debounceDaysPassedWithoutCarrierUpdate]
  );
  const handleTransitDaysBetweenChange = useCallback(
    (value) => {
      setTransitDaysBetween(value);
      debounceTransitDaysBetween(value);
    },
    [debounceTransitDaysBetween]
  );
  const handleOrdersCreatedInChange = useCallback(
    (value, from = undefined, to = undefined) => {
      setOrdersCreatedIn(value);
      setOrdersCreatedInFrom(from);
      setOrdersCreatedInTo(to);
      setFilters({
        ordersCreatedIn: value,
        ordersCreatedInFrom: from,
        ordersCreatedInTo: to,
      });
    },
    []
  );

  const handleClearAllFilters = () => {
    setSearch({ categoryIndex: 0, text: '' });
    setSortByOption(DEFAULT_SHIPMENT_SORT_OPTION);
    setStatuses([]);
    setSubStatuses({});
    setSelectedFlagOptions([]);
    setFirstMileCarriers([]);
    setSelectedDeliveryDestinations([]);
    setLastMileCarriers([]);
    setSelectedTab(0);
    setDaysPassedWithoutCarrierUpdate([
      MIN_DAYS_PASSED_WITHOUT_CARRIER_UPDATE,
      MAX_DAYS_PASSED_WITHOUT_CARRIER_UPDATE,
    ]);
    setTransitDaysBetween([MIN_TRANSIT_DAYS, MAX_TRANSIT_DAYS]);
    setOrdersCreatedIn(DATE_RANGE_VALUE_LAST_60_DAYS);
    setOrdersCreatedInFrom(null);
    setOrdersCreatedInTo(null);

    setFilters({
      search_category: 'order_number',
      search: '',
      sort: DEFAULT_SHIPMENT_SORT_OPTION,
      statuses: [],
      subStatuses: [],
      selectedFlagOptions: [],
      selectedDeliveryDestinations: [],
      firstMileCarriers: [],
      lastMileCarriers: [],
      daysPassedWithoutCarrierUpdate: [
        MIN_DAYS_PASSED_WITHOUT_CARRIER_UPDATE,
        MAX_DAYS_PASSED_WITHOUT_CARRIER_UPDATE,
      ],
      transitDaysBetween: [MIN_TRANSIT_DAYS, MAX_TRANSIT_DAYS],
      ordersCreatedIn: DATE_RANGE_VALUE_LAST_60_DAYS,
      ordersCreatedInFrom: undefined,
      ordersCreatedInTo: undefined,
      page: 1,
    });
  };

  useEffect(() => {
    if (!isStatusesLoading && isStatusesLoaded) {
      const list = [t('shipments.filter.shipment_statuses.all')];
      setTabsList(list.concat(shipmentStatuses.map((status) => status.label)));
    }
  }, [shipmentStatuses, isStatusesLoading, isStatusesLoaded, t]);

  useEffect(() => {
    if (
      _.isEmpty(shipmentStatuses) ||
      isStatusesLoading ||
      paramsLoaded ||
      isCountriesLoading
    ) {
      return;
    }

    let tempParams = params;
    if (params.toString() === '' && shipmentsQueryString) {
      tempParams = new URLSearchParams(`?${shipmentsQueryString}`);
    }

    tempParams.get('page') &&
      handlePageChange(parseInt(tempParams.get('page')));

    const search = decodeURIComponent(tempParams.get('search') || '');
    if (search) {
      setSearch({ text: search });
      setFilters({
        search: search,
        search_category: getSearchCategories(search),
      });
    }

    tempParams.get('statuses_uuid') &&
      handleStatusesChange(
        tempParams
          .get('statuses_uuid')
          .split(',')
          .map((uuid) => getStatusIdFromUuid(shipmentStatuses, uuid))
      );
    const tIndex = shipmentStatuses.findIndex(
      ({ uuid }) => uuid === tempParams.get('statuses_uuid')
    );
    setSelectedTab(tIndex + 1);

    if (tempParams.get('sub_statuses_uuid')) {
      const object = {};
      tempParams
        .get('sub_statuses_uuid')
        .split(',')
        .forEach((uuid) => {
          const { status, subStatus } = getStatusIdFromSubStatusUuid(
            shipmentStatuses,
            uuid
          );
          object[status]
            ? object[status].push(subStatus)
            : (object[status] = [subStatus]);
        });

      setSubStatuses(object);
      setFilters({ subStatuses: Object.values(object).flat(1) });
    }
    const filterOpts = flagFilterOptions
      .filter(({ value }) => tempParams.has(value))
      .map(({ value }) => value);
    handleFlagOptionChange(filterOpts);
    tempParams.get('country_uuid') &&
      handleDeliveryDestinationsChange(
        tempParams.get('country_uuid').split(',')
      );

    tempParams.get('firstmile_carriers_uuid') &&
      handleFirstMileCarriersChange(
        tempParams.get('firstmile_carriers_uuid').split(',')
      );

    tempParams.get('lastmile_carriers_uuid') &&
      handleLastMileCarriersChange(
        tempParams.get('lastmile_carriers_uuid').split(',')
      );

    if (tempParams.get('sort')) {
      const paramSort = tempParams.get('sort');
      if (paramSort.startsWith('-')) {
        handleSortChange([`${paramSort.slice(1)} desc`]);
      } else {
        handleSortChange([`${paramSort} asc`]);
      }
    }

    if (
      tempParams.get('days_without_carrier_update_min') ||
      tempParams.get('days_without_carrier_update_max')
    ) {
      const min =
        tempParams.get('days_without_carrier_update_min') ||
        MIN_DAYS_PASSED_WITHOUT_CARRIER_UPDATE;
      const max =
        tempParams.get('days_without_carrier_update_max') ||
        MAX_DAYS_PASSED_WITHOUT_CARRIER_UPDATE;

      setDaysPassedWithoutCarrierUpdate([min, max]);
      setFilters({
        daysPassedWithoutCarrierUpdate: [min, max],
      });
    }

    if (
      tempParams.get('days_in_transit_min') ||
      tempParams.get('days_in_transit_max')
    ) {
      const min = tempParams.get('days_in_transit_min') || MIN_TRANSIT_DAYS;
      const max = tempParams.get('days_in_transit_max') || MAX_TRANSIT_DAYS;

      setTransitDaysBetween([min, max]);
      setFilters({
        transitDaysBetween: [min, max],
      });
    }

    const ordersCreatedIn = tempParams.get('orders_created_in');
    if (ordersCreatedIn) {
      if (ordersCreatedIn === DATE_RANGE_VALUE_CUSTOM) {
        const start = tempParams.get('orders_created_from');
        const end = tempParams.get('orders_created_to');
        if (start && end) {
          handleOrdersCreatedInChange(DATE_RANGE_VALUE_CUSTOM, start, end);
        } else {
          handleOrdersCreatedInChange(DATE_RANGE_VALUE_LAST_60_DAYS);
        }
      } else {
        handleOrdersCreatedInChange(ordersCreatedIn);
      }
    }

    setTimeout(() => {
      setParamsLoaded(true);
    }, 1);
  }, [shipmentStatuses, isStatusesLoading, isCountriesLoading]);

  useEffect(() => {
    if (paramsLoaded) {
      clearSelection();
      const queryObject = getQueryObject(
        page,
        filters,
        shipmentStatuses,
        columns
      );
      const queryString = getQueryString(
        page,
        filters,
        shipmentStatuses,
        columns
      );

      dispatch(
        shipmentsActions.setShipmentsQuery({ queryString, queryObject })
      );
      navigate({ search: queryString }, { replace: true });
    }
  }, [filters, shipmentStatuses, paramsLoaded, columns]);

  // useEffect(() => {
  //   if (!isExporting && isExportSucess && exportData) {
  //     const link = document.createElement('a');
  //     link.href = exportData.url;
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //   }
  // }, [isExportSucess, isExporting]);

  useEffect(() => {
    if (params.has('message_id')) {
      dispatch(
        baseActions.setToastMessage({
          message: t(params.get('message_id')),
          type: 'success',
        })
      );
    }
  }, []);

  useEffect(() => {
    const translatedColumns = getColumns(t);

    const newLabels = {};
    translatedColumns.forEach((col) => {
      newLabels[col.value] = col.title;
    });

    const newColumns = [...columns];
    newColumns.forEach((newColumn) => {
      newColumn.title = newLabels[newColumn.value];
    });

    handleColumnsChange(newColumns);
  }, [t]);

  const onRefreshAction = useCallback(() => {
    refreshShipments();
  }, [refreshShipments]);

  const refetchShipmentsWithDelay = (delay = 2000, carrierUpdateCase) => {
    setIsDelayedLoading(true);
    setTimeout(() => {
      refreshShipments();
      setIsDelayedLoading(false);
      if (carrierUpdateCase) {
        refetchShipmentCarriers();
        refetchShipmentFirstMileCarriers();
        refetchShipmentLastMileCarriers();
      }
    }, delay);
  };

  const onImportAction = useCallback(() => {
    setOpenOrderImportModal((oIModal) => !oIModal);
  }, []);

  const handleMuteNotifications = (selection, mute) => {
    muteNotifications(
      selection.map((s) => ({
        shipment_uuid: s,
        is_muted: mute,
      }))
    );
  };

  const onExportAction = useCallback(() => {
    if (isExporting) {
      return;
    }

    let exportParams = new URLSearchParams(`?${shipmentsQueryString}`);
    const fields = columns
      .filter(({ selected }) => selected)
      .map(({ value }) => COLUMN_MAPPINGS[value])
      .join(',');

    exportParams.set('fields', fields);
    exportParams.set('user_uuid', userInfo.id);
    exportShipments(exportParams.toString());
  }, [shipmentsQueryString, columns]);

  // Helpers
  useEffect(() => {
    setIsRetried(false);
    if (paramsLoaded) {
      debounceQuery(search.text);
    }
  }, [search.text]);

  useEffect(() => {
    return () => {
      setSearch({ text: '', categoryIndex: 0 });
    };
  }, []);

  const debounceQuery = useCallback(
    _.debounce((text) => {
      setFilters({
        search: text,
        search_category: getSearchCategories(text),
      });
    }, 500),
    [search.text]
  );

  const copyToClipboard = (link) => {
    navigator.clipboard.writeText(link);
    dispatch(
      baseActions.setToastMessage({
        message: 'Copied to clipboard',
        type: 'success',
      })
    );
  };

  const getAppliedFilters = useCallback(() => {
    const appliedFilters = [];
    const temp = Object.values(subStatuses).flat(1);

    if (!_.isEmpty(statuses) || !_.isEmpty(temp)) {
      const label = _.isEmpty(statuses)
        ? t('pre-shipment.sub-status')
        : t('shipments.filter.status_label');

      const betweenComma = !_.isEmpty(statuses) && !_.isEmpty(temp) ? ',' : '';
      appliedFilters.push({
        key: 'status',
        label: `${label}: ${statuses
          .map((id) => getStatusLabelFromId(shipmentStatuses, id))
          .join(', ')}${betweenComma} ${temp
          .map((id) => getSubStatusLabelFromId(shipmentStatuses, id))
          .join(', ')}`,
        onRemove: () => {
          handleStatusesChange([]);
          handleSubStatusesClear();
          setSelectedTab(0);
        },
      });
    }

    !_.isEmpty(selectedFlagOptions) &&
      appliedFilters.push({
        key: 'flag_filter',
        label: `${t('shipments.filter.flag')}: ${flagFilterOptions
          ?.filter((opt) => selectedFlagOptions.includes(opt.value))
          ?.map((opt) => opt.label)
          ?.join(', ')}`,
        onRemove: () => handleFlagOptionChange([]),
      });

    !_.isEmpty(selectedDeliveryDestinations) &&
      appliedFilters.push({
        key: 'delivery_destination',
        label: `${t(
          'shipments.filter.delivery_destination'
        )}: ${selectedDeliveryDestinations
          .map(
            (uuid) =>
              getCountryNameFromUUID(countriesList, uuid) ||
              t('shipments.filter.delivery_destination_not_assigned')
          )
          .join(', ')}`,
        onRemove: () => handleDeliveryDestinationsChange([]),
      });

    !_.isEmpty(firstMileCarriers) &&
      appliedFilters.push({
        key: 'first-mile-carrier',
        label: `${t('shipments.filter.first_mile_carrier')}: ${firstMileCarriers
          .map(
            (uuid) =>
              getCarrierNameFromUuid(
                shipmentFirstMileCarriers?.carriers,
                uuid
              ) || t('shipments.filter.carrier_not_assigned')
          )
          .join(', ')}`,
        onRemove: () => handleFirstMileCarriersChange([]),
      });

    !_.isEmpty(lastMileCarriers) &&
      appliedFilters.push({
        key: 'last-mile-carrier',
        label: `${t('shipments.filter.last_mile_carrier')}: ${lastMileCarriers
          .map(
            (uuid) =>
              getCarrierNameFromUuid(
                shipmentLastMileCarriers?.carriers,
                uuid
              ) || t('shipments.filter.carrier_not_assigned')
          )
          .join(', ')}`,
        onRemove: () => handleLastMileCarriersChange([]),
      });

    (daysPassedWithoutCarrierUpdate[0] >
      MIN_DAYS_PASSED_WITHOUT_CARRIER_UPDATE ||
      daysPassedWithoutCarrierUpdate[1] <
        MAX_DAYS_PASSED_WITHOUT_CARRIER_UPDATE) &&
      appliedFilters.push({
        key: 'daysPassedWithoutCarrierUpdate',
        label: `${t(
          'shipments.filter.days_passed_without_carrier_update_title'
        )}: ${daysPassedWithoutCarrierUpdate[0]} - ${
          daysPassedWithoutCarrierUpdate[1]
        }`,
        onRemove: () => {
          setDaysPassedWithoutCarrierUpdate([
            MIN_DAYS_PASSED_WITHOUT_CARRIER_UPDATE,
            MAX_DAYS_PASSED_WITHOUT_CARRIER_UPDATE,
          ]);
          setFilters({
            daysPassedWithoutCarrierUpdate: [
              MIN_DAYS_PASSED_WITHOUT_CARRIER_UPDATE,
              MAX_DAYS_PASSED_WITHOUT_CARRIER_UPDATE,
            ],
          });
        },
      });

    (transitDaysBetween[0] > MIN_TRANSIT_DAYS ||
      transitDaysBetween[1] < MAX_TRANSIT_DAYS) &&
      appliedFilters.push({
        key: 'transitDaysBetween',
        label: `${t('shipments.filter.transit_days_between_title')}: ${
          transitDaysBetween[0]
        } - ${transitDaysBetween[1]}`,
        onRemove: () => {
          setTransitDaysBetween([MIN_TRANSIT_DAYS, MAX_TRANSIT_DAYS]);
          setFilters({
            transitDaysBetween: [MIN_TRANSIT_DAYS, MAX_TRANSIT_DAYS],
          });
        },
      });

    if (ordersCreatedIn !== DATE_RANGE_VALUE_LAST_60_DAYS) {
      appliedFilters.push({
        key: 'ordersCreatedIn',
        label: `${t(
          'shipments.filter.orders_created_in_title'
        )}: ${getTextForFilterTag(
          ordersCreatedIn,
          t,
          ordersCreatedInFrom,
          ordersCreatedInTo
        )}`,
        onRemove: () =>
          handleOrdersCreatedInChange(DATE_RANGE_VALUE_LAST_60_DAYS),
      });
    }

    return appliedFilters;
  }, [
    statuses,
    subStatuses,
    firstMileCarriers,
    selectedDeliveryDestinations,
    selectedFlagOptions,
    lastMileCarriers,
    daysPassedWithoutCarrierUpdate,
    transitDaysBetween,
    ordersCreatedIn,
    ordersCreatedInFrom,
    ordersCreatedInTo,
    shipmentStatuses,
    shipmentCarriers,
  ]);

  const getStatusChoices = () => {
    if (!shipmentStatuses) {
      return [];
    }

    return shipmentStatuses.map((status) => ({
      label: status.label,
      value: status.id,
      renderChildren: status.substatuses
        ? () => (
            <div className="small-gap-list">
              <ChoiceList
                choices={status.substatuses.map((substatus) => ({
                  label: substatus.label,
                  value: substatus.id,
                }))}
                onChange={handleSubStatusesChange}
                selected={subStatuses[status.id] || []}
                title={''}
                name={status.id}
                titleHidden
                allowMultiple
              />
            </div>
          )
        : undefined,
    }));
  };

  const filtersData = {
    placeholder: t('shipments.search.input.placeholder'),
    query: search.text,
    onQueryChange: handleQueryChange,
    appliedFilters: getAppliedFilters(),
    filters: [
      {
        key: 'status',
        label: t('shipments.filter.status_label'),
        filter: (
          <div className="small-gap-list">
            <ChoiceList
              name={'status'}
              title={''}
              titleHidden={true}
              choices={getStatusChoices()}
              selected={statuses}
              onChange={handleStatusesChange}
              allowMultiple
            />
          </div>
        ),
        shortcut: true,
        hideClearButton: false,
      },
      {
        key: 'flag_filter',
        label: t('shipments.filter.flag'),
        filter: (
          <ChoiceList
            title={''}
            titleHidden={true}
            name={'first-mile-carrier'}
            choices={flagFilterOptions}
            onChange={handleFlagOptionChange}
            selected={selectedFlagOptions}
            allowMultiple
          />
        ),
        shortcut: true,
      },
      {
        key: 'delivery_destination',
        label: t('shipments.filter.delivery_destination'),
        filter: (
          <SearchableChoiceList
            name={'shipments.filter.delivery_destination'}
            choices={countriesList}
            onChange={handleDeliveryDestinationsChange}
            selected={selectedDeliveryDestinations}
            hideClearButton={true}
          />
        ),
        shortcut: true,
      },
      {
        key: 'first-mile-carrier',
        label: t('shipments.filter.first_mile_carrier'),
        filter: (
          <SearchableChoiceList
            name={'first-mile-carrier'}
            choices={getCarrierChoices(
              shipmentFirstMileCarriers?.carriers,
              t('shipments.filter.carrier_not_assigned')
            )}
            onChange={handleFirstMileCarriersChange}
            selected={firstMileCarriers}
            hideClearButton={true}
          />
        ),
        shortcut: true,
      },
      {
        key: 'last-mile-carrier',
        label: t('shipments.filter.last_mile_carrier'),
        filter: (
          <SearchableChoiceList
            name={'last-mile-carrier'}
            choices={getCarrierChoices(
              shipmentLastMileCarriers?.carriers,
              t('shipments.filter.carrier_not_assigned')
            )}
            onChange={handleLastMileCarriersChange}
            selected={lastMileCarriers}
            hideClearButton={true}
          />
        ),
        shortcut: true,
      },
      {
        key: 'daysPassedWithoutCarrierUpdate',
        label: t('shipments.filter.days_passed_without_carrier_update_title'),
        filter: (
          <Box>
            <DragDropContext onDragEnd={() => {}}>
              <StrictModeDroppable id={'dropable'}>
                {() => {
                  return (
                    <RangeSlider
                      label={t(
                        'shipments.filter.days_passed_without_carrier_update_description'
                      )}
                      value={daysPassedWithoutCarrierUpdate}
                      prefix={<p>{MIN_DAYS_PASSED_WITHOUT_CARRIER_UPDATE}</p>}
                      suffix={<p>{MAX_DAYS_PASSED_WITHOUT_CARRIER_UPDATE}+</p>}
                      output
                      min={MIN_DAYS_PASSED_WITHOUT_CARRIER_UPDATE}
                      max={MAX_DAYS_PASSED_WITHOUT_CARRIER_UPDATE}
                      step={1}
                      onChange={handleDaysPassedWithoutCarrierUpdateChange}
                    />
                  );
                }}
              </StrictModeDroppable>
            </DragDropContext>
          </Box>
        ),
        hideClearButton: false,
      },
      {
        key: 'transitDaysBetween',
        label: t('shipments.filter.transit_days_between_title'),
        filter: (
          <Box>
            <DragDropContext onDragEnd={() => {}}>
              <StrictModeDroppable id={'dropable'}>
                {() => {
                  return (
                    <RangeSlider
                      label={t(
                        'shipments.filter.transit_days_between_description'
                      )}
                      value={transitDaysBetween}
                      prefix={<p>{MIN_TRANSIT_DAYS}</p>}
                      suffix={<p>{MAX_TRANSIT_DAYS}+</p>}
                      output
                      min={MIN_TRANSIT_DAYS}
                      max={MAX_TRANSIT_DAYS}
                      step={1}
                      onChange={handleTransitDaysBetweenChange}
                    />
                  );
                }}
              </StrictModeDroppable>
            </DragDropContext>
          </Box>
        ),
        hideClearButton: false,
      },
      {
        key: 'ordersCreatedIn',
        label: t('shipments.filter.orders_created_in_title'),
        filter: (
          <DateRangeList
            selectedOption={ordersCreatedIn}
            start={ordersCreatedInFrom}
            end={ordersCreatedInTo}
            onChange={handleOrdersCreatedInChange}
          />
        ),
        hideClearButton: false,
      },
    ],
  };

  const getShipmentsList = () => {
    return shipments.map((shipment) => {
      const status = shipmentStatuses.find(
        ({ uuid }) => uuid === shipment.shipment_status_uuid
      );
      const subStatus = status?.substatuses?.find(
        (sub) => sub.uuid === shipment.shipment_sub_status_uuid
      );
      const firstMileCarrier = shipmentCarriers.find(
        ({ uuid }) => uuid === shipment.first_mile_carrier_uuid
      );
      const lastMileCarrier = shipmentCarriers.find(
        ({ uuid }) => uuid === shipment.last_mile_carrier_uuid
      );

      const isFinal = status?.is_final;
      const variation = isFinal ? 'subdued' : undefined;
      const lastSyncFormatted = isFinal
        ? {
            variation,
            text: '-',
          }
        : getHumanDate(shipment.last_sync_at, isFinal);
      const lastCheckFormatted = getHumanDate(
        shipment.shipment_last_checkpoint_at,
        isFinal
      );
      const shipmentBadge = getShipmentBadge(status?.slug);

      return {
        shipment_quota: shipment.shipment_quota,
        uuid: shipment.uuid,
        order: shipment.order_label && (
          <HorizontalStack gap={'2'} wrap={false} align="space-between">
            <div onClick={(e) => e.stopPropagation()}>
              <ExternalLink url={shipment.order_external_link} external={false}>
                {shipment.order_label}
              </ExternalLink>
            </div>
            {shipment.shipment_quota === 'over_quota' && (
              <Tooltip content={t('shipments.over_quota.tooltip')}>
                <Icon source={RiskMinor} color="warning" />
              </Tooltip>
            )}
            {shipment.is_muted && shipment.shipment_quota !== 'over_quota' && (
              <Tooltip content={t('shipments.muted.tooltip')}>
                <Icon source={NotificationMajor} color="subdued" />
              </Tooltip>
            )}
          </HorizontalStack>
        ),
        status: status?.label && (
          <Badge
            status={shipmentBadge.status}
            progress={shipmentBadge.progress}
          >
            {status?.label}
          </Badge>
        ),
        subStatus: subStatus?.label && (
          <Text variant="bodyMd" as="span" color={variation}>
            {subStatus?.label}
          </Text>
        ),
        eta: shipment.shipment_estimated_delivery_date && (
          <Text variant="bodyMd" as="span" color={variation}>
            {formatDate(shipment.shipment_estimated_delivery_date)}
          </Text>
        ),
        firstMileCarrier: firstMileCarrier?.label && (
          <Text variant="bodyMd" as="span" color={variation}>
            {firstMileCarrier?.label}
          </Text>
        ),
        firstMileTrackingNumber: shipment.first_mile_tracking_number && (
          <HorizontalStack gap="1">
            <div className="tracking_num_container">
              {shipment.first_mile_tracking_link ? (
                <div onClick={(e) => e.stopPropagation()}>
                  <ExternalLink
                    url={shipment.first_mile_tracking_link}
                    avoidUTM={true}
                  >
                    {shipment.first_mile_tracking_number}
                  </ExternalLink>
                </div>
              ) : (
                <Text variant="bodyMd" as="span" color={variation}>
                  {shipment.first_mile_tracking_number}
                </Text>
              )}
              {shipment.first_mile_tracking_number ? (
                <div
                  className="copy-icon"
                  title={t('common.copy')}
                  onClick={(event) => {
                    event.stopPropagation();
                    copyToClipboard(shipment.first_mile_tracking_number);
                  }}
                >
                  <Icon color="subdued" source={ClipboardMinor} />
                </div>
              ) : (
                ''
              )}
            </div>
          </HorizontalStack>
        ),
        lastMileCarrier: lastMileCarrier?.label && (
          <Text variant="bodyMd" as="span" color={variation}>
            {lastMileCarrier?.label}
          </Text>
        ),

        lastMileTrackingNumber: shipment.last_mile_tracking_number && (
          <HorizontalStack gap="1">
            <div className="tracking_num_container">
              {shipment.last_mile_tracking_link ? (
                <div onClick={(e) => e.stopPropagation()}>
                  <ExternalLink
                    url={shipment.last_mile_tracking_link}
                    avoidUTM={true}
                  >
                    {shipment.last_mile_tracking_number}
                  </ExternalLink>
                </div>
              ) : (
                <Text variant="bodyMd" as="span" color={variation}>
                  {shipment.last_mile_tracking_number}
                </Text>
              )}
              {shipment.last_mile_tracking_number ? (
                <div
                  className="copy-icon"
                  title={t('common.copy')}
                  onClick={(event) => {
                    event.stopPropagation();
                    copyToClipboard(shipment.last_mile_tracking_number);
                  }}
                >
                  <Icon color="subdued" source={ClipboardMinor} />
                </div>
              ) : (
                ''
              )}
            </div>
          </HorizontalStack>
        ),
        carrierLastUpdate: lastCheckFormatted.text && (
          <Text
            variant="bodyMd"
            as="span"
            variation={lastCheckFormatted.variation}
          >
            {lastCheckFormatted.text}
          </Text>
        ),
        destinationCountry: shipment.destination_country && (
          <Text variant="bodyMd" as="span" color={variation}>
            {shipment.destination_country}
          </Text>
        ),
        destinationCity: shipment.destination_city && (
          <Text variant="bodyMd" as="span" color={variation}>
            {shipment.destination_city}
          </Text>
        ),
        customerName: shipment.customer_name && (
          <Text variant="bodyMd" as="span" color={variation}>
            {shipment.customer_name}
          </Text>
        ),
        customerEmail: shipment.customer_email && (
          <Text variant="bodyMd" as="span" color={variation}>
            {shipment.customer_email}
          </Text>
        ),
        customerPhone: shipment.customer_phone && (
          <Text variant="bodyMd" as="span" color={variation}>
            {shipment.customer_phone}
          </Text>
        ),
        orderDate: shipment.order_created_at && (
          <Text variant="bodyMd" as="span" color={variation}>
            {formatDate(shipment.order_created_at)}
          </Text>
        ),
        shipmentDate: shipment.shipment_created_at && (
          <Text variant="bodyMd" as="span" color={variation}>
            {formatDate(shipment.shipment_created_at)}
          </Text>
        ),
        inTransitDate: shipment.shipment_in_transit_at && (
          <Text variant="bodyMd" as="span" color={variation}>
            {formatDate(shipment.shipment_in_transit_at)}
          </Text>
        ),
        deliveredDate: shipment.shipment_delivered_at && (
          <Text variant="bodyMd" as="span" color={variation}>
            {formatDate(shipment.shipment_delivered_at)}
          </Text>
        ),
        orderPrice: shipment.order_price_label && (
          <Text variant="bodyMd" as="span" color={variation}>
            {shipment.order_price_label}
          </Text>
        ),
        lastCarrierCheck: lastSyncFormatted.text && (
          <Text variation={lastSyncFormatted.variation}>
            {lastSyncFormatted.text}
          </Text>
        ),
      };
    });
  };

  return (
    <>
      <SmallTopMessage />
      {isMarkingDelivered ||
      isReTracking ||
      isDeleting ||
      isMuting ||
      isDelayedLoading ? (
        <RushLoading />
      ) : (
        ''
      )}
      <RushPage
        title={t('shipments.header.title')}
        secondaryActions={[
          {
            content: t('shipment_detail.actions.refresh'),
            icon: RefreshMajor,
            disabled:
              shipmentsLoading ||
              isCarriersLoading ||
              isStatusesLoading ||
              !paramsLoaded,
            onAction: onRefreshAction,
          },
          {
            content: t('shipments.header.export_btn_label'),
            icon: ExportMinor,
            onAction: onExportAction,
            disabled: !shipments.length,
            loading: isExporting,
          },
          {
            content: t('shipments.header.import_order_btn_label'),
            icon: OrdersMajor,
            onAction: onImportAction,
          },
        ]}
        actionGroups={
          host
            ? [
                {
                  title: t('common.more'),
                  actions: shipmentSubItems(t, storeUUID)?.map((item) => ({
                    ...item,
                    content: item.label,
                  })),
                },
              ]
            : undefined
        }
        subtitle={
          <Subtitle
            text={t('shipments.header.subtitle')}
            actions={[
              {
                text: t('shipments.subtitle.get_started'),
                source: 'https://support.rush.app/about-shipments',
              },
            ]}
          />
        }
        fullWidth
      >
        {isCarriersLoading ||
        isStatusesLoading ||
        !paramsLoaded ||
        isDelayedLoading ? (
          <TabsTableSkeleton />
        ) : (
          <div className="shipments-outer-container">
            <FilterTable
              isLoading={shipmentsFetching}
              filterProps={{
                sortOptions: SHIPMENTS_SORT_OPTIONS,
                sortSelected: sortByOption,
                onSort: handleSortChange,
                queryPlaceholder: filtersData.placeholder,
                queryValue: filtersData.query,
                filters: filtersData.filters,
                appliedFilters: filtersData.appliedFilters,
                onQueryChange: filtersData.onQueryChange,
                onQueryClear: () => filtersData.onQueryChange(''),
                onClearAll: handleClearAllFilters,
              }}
              tableProps={{
                columns: columns,
                onColumnsChange: handleColumnsChange,
                allowColumnsEdit: true,
                resourceName: {
                  singular: t('shipments.filter.shipment_singular'),
                  plural: t('shipments.filter.shipment_plural'),
                },
                data: getShipmentsList(),
                selectable: true,
                selectedResources: selectedResources,
                handleSelectionChange: handleSelectionChange,
                allResourcesSelected: allResourcesSelected,
                promotedBulkActions: [
                  {
                    content: t('shipments.mark_as_delivered.bulk_button'),
                    onAction: () =>
                      markShipmentAsDelivered(
                        selectedResources,
                        t('shipments.mark_as_delivered.toster_message')
                      ),
                  },
                  {
                    content: t('shipments.mute_notifications.bulk_button'),
                    onAction: () =>
                      handleMuteNotifications(selectedResources, true),
                  },
                  {
                    content: t('shipments.unmute_notifications.bulk_button'),
                    onAction: () =>
                      handleMuteNotifications(selectedResources, false),
                  },
                  {
                    content: t('shipments.change_carrier.bulk_button'),
                    onAction: () => setShowCarrierModal(true),
                  },
                  {
                    content: t('shipments.retrack.bulk_button'),
                    onAction: () => {
                      reTrackShipment(selectedResources);
                    },
                  },
                  {
                    content: t('shipments.reprocess.bulk_button'),
                    onAction: () => {
                      reProcessShipment(selectedResources);
                    },
                  },
                  {
                    content: t('shipments.delete.bulk_button'),
                    onAction: () => {
                      deleteShipment(selectedResources);
                    },
                  },
                ],
                onRowClick: (uuid, openInNewTab) => {
                  openInNewTab
                    ? window.open(`${location.pathname}/${uuid}`, '_blank')
                    : navigate(`${location.pathname}/${uuid}`);
                },
              }}
              tabProps={{
                tabs: tabsList,
                selectedTab: selectedTab,
                onTabChange: handleTabChange,
              }}
              paginationProps={{
                label: t('shipments.summary.pagination_label', {
                  startResult: shipmentsMeta.from + 1,
                  endResult: shipmentsMeta.to,
                  total_results: shipmentsMeta.total?.toLocaleString(),
                }),
                hasNext: shipmentsMeta.current_page < shipmentsMeta.last_page,
                hasPrevious: shipmentsMeta.current_page > 1,
                onNext: () => {
                  handlePageChange(page + 1);
                },
                onPrevious: () => {
                  handlePageChange(page - 1);
                },
              }}
            />
          </div>
        )}
        <OrderImportModal
          openOrderImportModal={openOrderImportModal}
          onClose={onImportAction}
        />
        {showCarrierModal && (
          <UpdateCarrierModal
            shipmentIds={selectedResources}
            onClose={({ isUpdated } = {}) => {
              if (isUpdated) {
                clearSelection();
                refetchShipmentsWithDelay(2000, true);
              }
              setShowCarrierModal(false);
            }}
            initializer={'shipments'}
          />
        )}

        <RushFooter>
          <TranslateLink text={t('footer_help.dashboard')} />
        </RushFooter>
      </RushPage>
    </>
  );
}
