import React, { useState, useEffect } from 'react';
import {
  Text,
  SkeletonBodyText,
  Divider,
  VerticalStack,
  HorizontalStack,
  Box,
} from '@shopify/polaris';
import {
  DASHBOARD_ANALYTICS_TYPE_REVENUE_STATISTICS,
  DB_DATE_FORMAT_STRING,
} from 'Constants';
import moment from 'moment';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import ExternalLink from 'components/ExternalLink';
import { useGetReportsMutation } from 'pages/Dashboard/DashboardApi';
import useAdminLink from 'hooks/useAdminLink';

export default function RevenueStatistics({ selectedDates = {} }) {
  const adminLink = useAdminLink();
  const [getReport, reportsResponse] = useGetReportsMutation();
  const {
    data: revenueStats = {},
    isLoading,
    isSuccess,
  } = reportsResponse || {};

  const [totalOrdersValue, setTotalOrdersValue] = useState(null);
  const [averageOrdersValue, setAverageOrdersValue] = useState(null);
  const [t] = useTranslation();

  useEffect(() => {
    getReport({
      type: DASHBOARD_ANALYTICS_TYPE_REVENUE_STATISTICS,
      kind: 'revenue',
      filter: {
        date_from: moment(selectedDates.start).format(DB_DATE_FORMAT_STRING),
        date_to: moment(selectedDates.end).format(DB_DATE_FORMAT_STRING),
      },
    });
  }, [getReport, selectedDates, i18next.language]);

  useEffect(() => {
    if (revenueStats?.table?.columns?.length) {
      revenueStats.table.columns.forEach((item, index) => {
        switch (item.id) {
          case 'total_orders_value':
            setTotalOrdersValue(revenueStats.table.data[index]);
            break;
          case 'average_orders_value':
            setAverageOrdersValue({
              label: item.label,
              value: revenueStats.table.data[index],
            });
            break;
          default:
        }
      });
    }
  }, [revenueStats]);

  return (
    <>
      {isLoading ||
      (revenueStats &&
        Array.isArray(totalOrdersValue) &&
        totalOrdersValue[0] !== '$0.00') ? (
        <>
          <Divider />
          <Box padding={'5'}>
            <VerticalStack gap={'3'}>
              <Text variant="headingSm" as="span">
                {t('dashboard.analytics_sheet.revenue_statistics_title')}
              </Text>
              {isLoading || !isSuccess ? (
                <Box>
                  <SkeletonBodyText />
                </Box>
              ) : (
                revenueStats &&
                totalOrdersValue !== ['$0.00'] && (
                  <VerticalStack gap="1">
                    <Box>
                      <HorizontalStack blockAlign="baseline">
                        {totalOrdersValue && (
                          <Box width="100%">
                            <Text variant="bodyLg" as="h2">
                              {adminLink ? (
                                <ExternalLink
                                  url={`${adminLink}/orders?tag=RUSH_ORDER`}
                                >
                                  {totalOrdersValue}
                                </ExternalLink>
                              ) : (
                                <Text
                                  variant="bodyMd"
                                  as="span"
                                  color={'subdued'}
                                >
                                  {totalOrdersValue}
                                </Text>
                              )}
                            </Text>
                          </Box>
                        )}
                      </HorizontalStack>
                    </Box>
                    {averageOrdersValue && (
                      <Box>
                        <HorizontalStack>
                          <Box width='"100%'>{averageOrdersValue.label}</Box>
                          <Box>{averageOrdersValue.value}</Box>
                        </HorizontalStack>
                      </Box>
                    )}
                  </VerticalStack>
                )
              )}
            </VerticalStack>
          </Box>
        </>
      ) : (
        ''
      )}
    </>
  );
}
