import React, { useState, useEffect } from 'react';
import { Layout } from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import SmallTopMessage from 'components/SmallTopMessage';
import FeatureSection from './FeatureSection';
import LoadingCard from '../SettingsLoadingPage/LoadingCard';
import { FEATURE_TRACKING_PAGE, FEATURE_EMAIL } from 'Constants';
import TranslateLink from 'components/TranslateLink';
import { STORE_URLS } from 'config/urls';
import i18next from 'i18next';
import RushPage from 'components/custom/RushPage';
import RushFooter from 'components/custom/RushFooter';
export default function Upsells({ getUpsells, putUpsells, upSellList, shop }) {
  const [t] = useTranslation();
  const [upSellObject, setUpSellObject] = useState(null);
  const [emailObject, setEmailObject] = useState(null);

  useEffect(() => {
    getUpsells();
  }, [i18next.language, getUpsells]);

  useEffect(() => {
    if (_.isEmpty(upSellList)) {
      getUpsells();
    } else {
      const tempUpSellObject = upSellList.list
        .map((item) => {
          if (item.feature_id === FEATURE_TRACKING_PAGE) {
            return item;
          } else {
            return undefined;
          }
        })
        .filter((item) => !_.isUndefined(item));
      const tempEmailObject = upSellList.list
        .map((item) => {
          if (item.feature_id === FEATURE_EMAIL) {
            return item;
          } else {
            return undefined;
          }
        })
        .filter((item) => !_.isUndefined(item));
      setUpSellObject(tempUpSellObject[0]);
      setEmailObject(tempEmailObject[0]);
    }
  }, [getUpsells, upSellList]);

  return (
    <>
      <SmallTopMessage />
      <RushPage
        backAction={{
          url: `/s/${shop.storeUUID}${STORE_URLS.ADDONS}`,
        }}
        title={t('settings.upsell_page.header.title')}
      >
        <Layout>
          <Layout.Section>
            {upSellObject ? (
              <FeatureSection
                translationField="upsell_page"
                putUpsells={putUpsells}
                sectionInfo={upSellObject}
                upSellListMeta={upSellList.meta}
              />
            ) : (
              <Layout.AnnotatedSection>
                <LoadingCard />
              </Layout.AnnotatedSection>
            )}
            {emailObject ? (
              <FeatureSection
                translationField="upsell_page.upsell_email"
                putUpsells={putUpsells}
                sectionInfo={emailObject}
                upSellListMeta={upSellList.meta}
              />
            ) : (
              <Layout.AnnotatedSection>
                <LoadingCard />
              </Layout.AnnotatedSection>
            )}
          </Layout.Section>
          <Layout.Section>
            <RushFooter>
              <TranslateLink text={t('footer_help.dashboard')} />
            </RushFooter>
          </Layout.Section>
        </Layout>
      </RushPage>
    </>
  );
}
