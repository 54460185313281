import React from 'react';
import i18n from 'i18next';

// import { STAGE } from 'config';
// import { ENV_PRODUCTION } from 'Constants';
import { USER_URLS } from 'config/urls';
import {
  HomeMajor,
  ShipmentMajor,
  CreditCardSecureMajor,
  GiftCardMajor,
  ThemesMajor,
  AppsMajor,
  NotificationMajor,
  CircleInformationMajor,
  StoreMajor,
  ProfileMajor,
  TeamMajor,
} from '@shopify/polaris-icons';
import { STORE_URLS } from 'config/urls';
import { Badge } from '@shopify/polaris';

export const getAccountsNavItems = () => {
  return [
    {
      url: USER_URLS.DETAILS,
      label: i18n.t('navigation.item.account'),
      icon: ProfileMajor,
      selected: location.pathname.includes(USER_URLS.DETAILS),
    },
    {
      url: '/s',
      label: i18n.t('navigation.item.stores'),
      icon: StoreMajor,
      selected: location.pathname.includes('/s'),
    },
    {
      url: USER_URLS.MEMBERS_AND_PERMISSIONS,
      label: i18n.t('navigation.item.members_and_permissions'),
      icon: TeamMajor,
      selected: location.pathname.includes(USER_URLS.MEMBERS_AND_PERMISSIONS),
    },
  ];
};

export const notificationsSubMenu = (t, storeUUID, showChangeLogBadges) => [
  {
    url: `/s/${storeUUID}${STORE_URLS.NOTIFICATIONS_ACTIVITY_FEED}`,
    label: i18n.t('navigation.item.activity'),
    selected: location.pathname.includes(
      STORE_URLS.NOTIFICATIONS_ACTIVITY_FEED
    ),
    badge: showChangeLogBadges ? (
      <Badge status="info" size="small">
        {i18n.t('navigation.item.new')}
      </Badge>
    ) : undefined,
  },
];
export const shipmentSubItems = (t, storeUUID) =>
  [
    {
      url: `/s/${storeUUID}${STORE_URLS.SHIPMENTS_BLACKLISTING}`,
      label: i18n.t('navigation.item.shipments_blacklisting'),
      selected: location.pathname.includes(STORE_URLS.SHIPMENTS_BLACKLISTING),
    },
    {
      url: `/s/${storeUUID}${STORE_URLS.CARRIER_MASKING}`,
      label: i18n.t('navigation.item.shipments_carriermasking'),
      selected: location.pathname.includes(STORE_URLS.CARRIER_MASKING),
    },
    {
      url: `/s/${storeUUID}${STORE_URLS.SHIPMENTS_PRE_SHIPMENT_TIMELINE}`,
      label: i18n.t('navigation.item.shipments_pre_shipment_timeline'),
      selected: location.pathname.includes(
        STORE_URLS.SHIPMENTS_PRE_SHIPMENT_TIMELINE
      ),
    },
    {
      url: `/s/${storeUUID}${STORE_URLS.SHIPMENTS_CONFIGURATIONS}`,
      label: i18n.t('navigation.item.shipments_configuration'),
      selected: location.pathname.includes(STORE_URLS.SHIPMENTS_CONFIGURATIONS),
    },
  ].filter((item) => !item.hidden);

export const getMainNavItems = ({
  t,
  storeUUID,
  showChangeLogBadges,
  showPerksNew,
}) => {
  return [
    {
      url: `/s/${storeUUID}${STORE_URLS.ONBOARDING}`,
      label: i18n.t('navigation.item.onboarding'),
      icon: CircleInformationMajor,
      selected: location.pathname.includes('/onboarding'),
    },
    {
      url: `/s/${storeUUID}${STORE_URLS.DASHBOARD}`,
      label: i18n.t('navigation.item.dashboard'),
      icon: HomeMajor,
      selected: location.pathname.includes('/dashboard'),
    },
    {
      url: `/s/${storeUUID}${STORE_URLS.SHIPMENTS}`,
      label: i18n.t('navigation.item.shipments'),
      icon: ShipmentMajor,
      selected: location.pathname.includes('/shipment'),
      //   onClick: () => navigate(`/s/${storeUUID}${STORE_URLS.SHIPMENTS}`),
      subNavigationItems: shipmentSubItems(t, storeUUID),
    },
    {
      url: `/s/${storeUUID}${STORE_URLS.TRACKING_PAGES}`,
      label: i18n.t('settings.upsell_page.title'),
      icon: ThemesMajor,
      selected: location.pathname.includes('/tracking-page'),
      // badge: showChangeLogBadges ? (
      //   <Badge status="info" size="small">
      //     {i18n.t('navigation.item.improved')}
      //   </Badge>
      // ) : undefined,
    },
    {
      url: `/s/${storeUUID}${STORE_URLS.NOTIFICATIONS}`,
      label: i18n.t('navigation.item.notifications'),
      icon: NotificationMajor,
      selected: location.pathname.includes('/notifications'),
      subNavigationItems: notificationsSubMenu(
        t,
        storeUUID,
        showChangeLogBadges
      ),
    },

    {
      url: `/s/${storeUUID}${STORE_URLS.ADDONS}`,
      label: i18n.t('navigation.item.addons'),
      icon: AppsMajor,
      selected: location.pathname.includes('/addons'),
    },
    {
      url: `/s/${storeUUID}${STORE_URLS.BILLING}`,
      label: i18n.t('navigation.item.billing'),
      icon: CreditCardSecureMajor,
      selected: location.pathname.includes('/billing'),
    },
    {
      url: `/s/${storeUUID}${STORE_URLS.PERKS}`,
      label: i18n.t('navigation.item.perks'),
      icon: GiftCardMajor,
      selected: location.pathname.includes('/perks'),
      badge: showPerksNew ? (
        <Badge status="info" size="small">
          {i18n.t('navigation.item.new')}
        </Badge>
      ) : undefined,
    },
  ];
};

export const getFooterNavItems = ({ onClick, changeLogExplored }) => {
  return [
    {
      label: i18n.t('navigation.item.whats-new'),
      icon: NotificationMajor,
      onClick,
      subNavigationItems: !changeLogExplored
        ? [
            {
              new: true,
            },
          ]
        : [],
    },
  ];
};

export const getNavItems = ({
  t,
  storeUUID,
  showChangeLogBadges,
  showPerksNew,
  pathname,
}) => {
  if (
    [
      USER_URLS.STORES_LIST,
      USER_URLS.DETAILS,
      USER_URLS.MEMBERS_AND_PERMISSIONS,
    ].includes(pathname)
  ) {
    return getAccountsNavItems(t);
  } else {
    return getMainNavItems({
      t,
      storeUUID,
      showChangeLogBadges,
      showPerksNew,
    });
  }
};

export const getEmbeddedNavMenu = ({ t, storeUUID }) => {
  const menuItems = getMainNavItems({
    t,
    storeUUID,
  });
  const commonMenu = menuItems
    .map(({ label, url }) => {
      return {
        label,
        destination: url,
      };
    })
    .filter((item) => item.label != i18n.t('navigation.item.dashboard'));

  const embededMenu = [
    // {
    //   label: i18n.t('navigation.item.whats-new'),
    // },
  ];

  return [...commonMenu, ...embededMenu];
};
