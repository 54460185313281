import React, { useEffect, useState, useCallback } from 'react';
import {
  Image,
  HorizontalStack,
  Select,
  SkeletonBodyText,
  Text,
  Modal,
} from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import TranslateLink from 'components/TranslateLink';
import SyncImage from 'images/sync.svg';
import _ from 'lodash';
import {
  useGetOrderSyncOptionsQuery,
  useSyncOrdersMutation,
} from 'pages/Shipments3/shipmentsApi';
import { useDispatch } from 'react-redux';
import { baseActions } from 'redux/store/baseSlice';
import RushLoading from 'components/custom/RushLoader';
import RushModal from 'components/custom/RushModal';

export default function OrderImportModal({ onClose, openOrderImportModal }) {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const [selectedSyncOption, setSelectedSyncOption] = useState('');
  const [syncOrders, syncResponse] = useSyncOrdersMutation();
  const {
    data: syncData,
    isLoading: isSyncing,
    isSuccess: isSynced,
  } = syncResponse;
  const {
    data: { options: orderSyncOptions = [], message: orderSyncText = '' } = {},
    isLoading: orderSyncLoading,
  } = useGetOrderSyncOptionsQuery('', { skip: !openOrderImportModal });

  useEffect(() => {
    if (!isSyncing && isSynced && syncData) {
      dispatch(
        baseActions.setToastMessage({
          message: syncData.message,
          type: 'success',
        })
      );
      onClose();
    }
  }, [isSyncing, isSynced, syncData]);

  useEffect(() => {
    openOrderImportModal && setSelectedSyncOption('');
  }, [openOrderImportModal]);

  useEffect(() => {
    if (!_.isEmpty(orderSyncOptions)) {
      setSelectedSyncOption('30');
    }
  }, [orderSyncOptions]);

  const handleSelectChange = useCallback((value) => {
    setSelectedSyncOption(value);
  }, []);

  const handlePostOrderSync = useCallback(() => {
    syncOrders({ total_days_sync: selectedSyncOption });
  }, [syncOrders, selectedSyncOption]);

  return (
    <>
      {isSyncing ? <RushLoading /> : ''}
      <RushModal
        open={openOrderImportModal}
        onClose={onClose}
        title={t('shipments.import_order_modal.title')}
        primaryAction={{
          content: t('shipments.import_order_modal.button_label'),
          onAction: handlePostOrderSync,
          disabled: orderSyncLoading,
          loading: isSyncing,
        }}
      >
        <Modal.Section>
          <HorizontalStack align="center">
            <Image
              source={SyncImage}
              alt={t('shipments.import_order_modal.input_label')}
            />
          </HorizontalStack>

          {orderSyncLoading ? (
            <SkeletonBodyText />
          ) : (
            <>
              <Select
                label={t('shipments.import_order_modal.input_label')}
                options={orderSyncOptions.map((option) => ({
                  label: option.label,
                  value: option.slug,
                }))}
                onChange={handleSelectChange}
                value={selectedSyncOption}
                disabled={orderSyncLoading}
              />
              <Text variant="bodyMd" as="span" color="subdued">
                <TranslateLink text={orderSyncText} />
              </Text>
            </>
          )}
        </Modal.Section>
      </RushModal>
    </>
  );
}
