import Subtitle from 'components/Subtitle';
import React, { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  Card,
  SkeletonBodyText,
  Button,
  HorizontalStack,
  VerticalStack,
} from '@shopify/polaris';
import PageURLCard from '../common/PageURLCard';
import SearchCard from '../common/SearchCard';
import SourceCodeCard from './SourceCodeCard';
import LocalizationCard from '../common/LocalizationCard';
import CustomLanguages from '../common/CustomLanguages';
import AnalyticsCard from '../common/AnalyticsCard';
import { useDispatch, useSelector } from 'react-redux';
import { NewSourceCodePageTitle } from 'pages/TrackingPageNew/trackingPageSlice';
import {
  useCreateSourceTrackingPageMutation,
  useGetProxyPageConfigQuery,
  useGetSourcePageCodeQuery,
  useUpdateTrackingPageMutation,
} from 'pages/TrackingPageNew/newTrackingPageApis';
import withProxyConfig from '../common/withProxyConfig';
import TranslateLink from 'components/TranslateLink';

import isEqual from 'lodash/isEqual';
import { STORE_URLS } from 'config/urls';
import { baseActions } from 'redux/store/baseSlice';
import MigrationWarningCard from '../MigrationWarningCard';
import PublishModal from '../common/PublishModal';
import PublishButton from '../common/PublishButton';
import RushSaveBar from 'components/custom/RushSaveBar';
import RushPage from 'components/custom/RushPage';
import RushFooter from 'components/custom/RushFooter';

function SourceCodePage(props) {
  const {
    proxyConfig,
    onProxyUpdate,
    isProxyLoading,
    isDirtyProxy,
    isProxyUpdating,
    isProxyUpdated,
    saveProxyConfig,
    resetConfig,
    metaInfo,
    isMetaLoading,
  } = props;

  const [t] = useTranslation();
  const pageTitle = t('tracking_page_new.source_code_page_title');
  const pagelearn = t('tracking_page_new.source_code_page_learn');
  const pagelearnlink = t('tracking_page_new.source_code_page_learn_link');
  const pageDescription = t('tracking_page_new.source_code_page_description');

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const params = useParams();
  const { pageId } = params;

  const title = useSelector(NewSourceCodePageTitle);
  const [showPublishModal, setShowPublishModal] = useState(false);

  const [codeError, setCodeError] = useState(false);
  const [pageState, setPageState] = useState({
    title: title,
    shopify_render_between_header_and_footer: true,
    page_code: '',
  });

  const {
    data: pageConfig,
    isFetching: isPageConfigFetching,
    isSuccess: isPageConfigLoaded,
  } = useGetProxyPageConfigQuery(pageId, {
    skip: !pageId,
  });

  const {
    data: { template = {} } = {},
    isLoading: isDefauldCodeLoading,
    isSuccess: isDefauldCodeLoaded,
  } = useGetSourcePageCodeQuery(undefined, {
    skip: pageId,
  });

  useEffect(() => {
    if (!isDefauldCodeLoading && isDefauldCodeLoaded) {
      setPageState({ ...pageState, page_code: template?.code });
    }
  }, [isDefauldCodeLoading, isDefauldCodeLoaded]);

  const [
    createPage,
    {
      isLoading: isCreatingPage,
      isSuccess: isPageCreated,
      data: { uuid: createdPageUUID } = {},
    },
  ] = useCreateSourceTrackingPageMutation();

  useEffect(() => {
    if (!isCreatingPage && isPageCreated && createdPageUUID) {
      setShowPublishModal(true);
    }
  }, [isPageCreated, isCreatingPage, createdPageUUID]);

  const [updatePage, { isLoading: isPageUpdating, isSuccess: isPageUpdated }] =
    useUpdateTrackingPageMutation();

  useEffect(() => {
    if (!isCreatingPage && isPageCreated) {
      dispatch(
        baseActions.setToastMessage({
          message: t('tracking_page_new.page_created'),
          type: 'success',
        })
      );
    }
  }, [isCreatingPage, isPageCreated]);

  useEffect(() => {
    if (
      (!isPageUpdating && isPageUpdated) ||
      (!isProxyUpdating && isProxyUpdated)
    ) {
      dispatch(
        baseActions.setToastMessage({
          message: t('tracking_page_new.page_updated'),
          type: 'success',
        })
      );
    }
  }, [isPageUpdating, isPageUpdated, isProxyUpdating, isProxyUpdated]);

  useEffect(() => {
    if (isPageConfigLoaded && !isPageConfigFetching) {
      setPageState({ ...pageConfig });
    }
  }, [isPageConfigLoaded, isPageConfigFetching]);

  const handleChange = (key, value) => {
    if (key == 'page_code') {
      setCodeError(!value);
    }
    setPageState({
      ...pageState,
      [key]: value,
    });
  };

  const isPageStateDirty = useMemo(() => {
    let isDirty = false;
    if (pageConfig) {
      const currentObject = { ...pageState };
      const orgObject = Object.keys(currentObject).reduce((prev, next) => {
        prev[next] = pageConfig[next];
        return prev;
      }, {});
      isDirty = !isEqual(orgObject, currentObject);
    }
    return isDirty;
  }, [pageConfig, pageState]);

  const isDirtyState = useMemo(() => {
    if (!pageId) {
      return true;
    } else {
      return isDirtyProxy || isPageStateDirty;
    }
  }, [pageId, isDirtyProxy, isPageStateDirty]);

  const handleSave = () => {
    if (pageId) {
      if (isPageStateDirty) {
        const { uuid, ...tobeSaved } = pageState;
        updatePage({ uuid, data: { ...tobeSaved } });
      }
    } else {
      createPage({ ...pageState, store_uuid: proxyConfig.store_uuid });
    }

    if (isDirtyProxy) {
      saveProxyConfig();
    }
  };

  const handleDiscard = () => {
    setPageState({ ...pageConfig });
    resetConfig();
    setCodeError(false);
  };

  const handleModalClose = () => {
    setShowPublishModal(false);
    navigate(`${location.pathname}/${createdPageUUID}`);
  };

  const handlePreview = () => {
    window.open(pageConfig.preview_link);
  };

  return (
    <>
      {!isProxyLoading &&
        !isPageConfigFetching &&
        isDirtyState &&
        !showPublishModal && (
          <RushSaveBar
            message={t('common.unsaved-changes')}
            saveAction={{
              content: t('common.save'),
              onAction: handleSave,
              loading:
                isPageUpdating ||
                isCreatingPage ||
                isProxyUpdating ||
                isDefauldCodeLoading,
              disabled: codeError,
            }}
            discardAction={
              pageId
                ? {
                    onAction: handleDiscard,
                    loading:
                      isPageUpdating || isCreatingPage || isProxyUpdating,
                    content: t('common.discard'),
                  }
                : undefined
            }
          />
        )}
      <RushPage
        title={pageTitle}
        primaryAction={
          pageId ? (
            <HorizontalStack gap={'3'}>
              <Button onClick={handlePreview}>{t('common.preview')}</Button>

              <PublishButton
                uuid={pageId}
                type={'source'}
                title={pageState.title}
              />
            </HorizontalStack>
          ) : undefined
        }
        backAction={{
          onAction: () =>
            navigate(`/s/${params.uuid}${STORE_URLS.TRACKING_PAGES}`),
        }}
        subtitle={
          <Subtitle
            text={pageDescription}
            actions={[
              {
                text: pagelearn,
                source: pagelearnlink,
              },
            ]}
          />
        }
      >
        <VerticalStack gap="3">
          <MigrationWarningCard />
          {pageId ? (
            <PageURLCard
              onProxyUpdate={onProxyUpdate}
              proxyConfig={proxyConfig}
              isLoading={
                isProxyLoading || isMetaLoading || isPageConfigFetching
              }
              proxies={metaInfo?.page_link?.proxy || []}
            />
          ) : (
            ''
          )}

          <SearchCard
            onProxyUpdate={onProxyUpdate}
            proxyConfig={proxyConfig}
            isLoading={isProxyLoading || isMetaLoading || isPageConfigFetching}
          />
          <AnalyticsCard
            onProxyUpdate={onProxyUpdate}
            proxyConfig={proxyConfig}
            isLoading={isProxyLoading || isMetaLoading || isPageConfigFetching}
          />
          <LocalizationCard
            onProxyUpdate={onProxyUpdate}
            proxyConfig={proxyConfig}
            isLoading={isProxyLoading || isMetaLoading || isPageConfigFetching}
          />
          <CustomLanguages
            onProxyUpdate={onProxyUpdate}
            proxyConfig={proxyConfig}
          />
          {isDefauldCodeLoading ? (
            <Card roundedAbove="sm">
              <SkeletonBodyText lines={5} />
            </Card>
          ) : (
            <SourceCodeCard
              error={codeError}
              page_code={pageState.page_code}
              onChange={handleChange}
            />
          )}
          <RushFooter>
            <TranslateLink text={t('tracking_page_new.footer')} />
          </RushFooter>
        </VerticalStack>
      </RushPage>
      {showPublishModal && (
        <PublishModal
          uuid={createdPageUUID}
          type={'source'}
          onClose={handleModalClose}
          title={pageState.title}
        />
      )}
    </>
  );
}

export default withProxyConfig(SourceCodePage);
