import React from 'react';
import { HorizontalStack,  Modal
} from '@shopify/polaris';
import './style.scss';
import RushModal from 'components/custom/RushModal';
export default function VideoModal(props) {
  return (
    <RushModal
      open={props.showVideoModal}
      onClose={props.handleModalChange}
      title={props.videoTitle}
    >
      <div>
        <Modal.Section>
          <HorizontalStack align="center" gap="5">
            <iframe
              width="560"
              height="315"
              src={props.videoSrc}
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="video-youtube"
            ></iframe>
          </HorizontalStack>
        </Modal.Section>
      </div>
    </RushModal>
  );
}
