import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Text } from '@shopify/polaris';
import { UserPage } from '@rush-app-admin/storybooks';
import { isEmailValid } from 'Utils/utils';
import { useNavigate } from 'react-router-dom';
import { VISITOR_URLS } from 'config/urls';
import { API_URL } from 'config';
import VisitorHOC from '../VisitorHOC';

function Login({
  changeVisitorLanguage,
  visitorLanguages,
  selectedVisitorLanguage,
  selectedVisitorLanguageCode,
  isLoading,
  login,
}) {
  const [t] = useTranslation();
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showEmailError, setShowEmailError] = useState(false);
  const [showPasswordError, setShowPasswordError] = useState(false);

  const handleEmailChange = useCallback((value) => {
    setShowEmailError(false);
    setEmail(value);
  }, []);

  const handlePasswordChange = useCallback((value) => {
    setShowPasswordError(false);
    setPassword(value);
  }, []);

  const handleSubmit = () => {
    setShowEmailError(false);
    setShowPasswordError(false);

    let hasErrors = false;

    if (!isEmailValid(email)) {
      setShowEmailError(true);
      hasErrors = true;
    }

    if (password.length < 6) {
      setShowPasswordError(true);
      hasErrors = true;
    }

    if (hasErrors) {
      return;
    }

    login({ email, password });
  };

  const handleSignupClick = () => {
    navigate(`${VISITOR_URLS.REGISTER}${location.search}`);
  };

  const handleForgotPasswordClick = () => {
    navigate(`${VISITOR_URLS.RECOVER}${location.search}`);
  };

  const handleGoogleClick = () => {
    window.open(
      `${API_URL}/social/google/redirect?language=${selectedVisitorLanguageCode}&force_language_parameter=1`,
      '_self'
    );
  };
  const handleFacebookClick = () => {
    window.open(
      `${API_URL}/social/facebook/redirect?language=${selectedVisitorLanguageCode}&force_language_parameter=1`,
      '_self'
    );
  };
  const handleLinkedIdClick = () => {
    window.open(
      `${API_URL}/social/linkedin/redirect?language=${selectedVisitorLanguageCode}&force_language_parameter=1`,
      '_self'
    );
  };

  return (
    <div className={'visitorPagesContainer'}>
      <div style={{ width: '100%' }}>
        <UserPage
          loading={isLoading}
          languages={
            visitorLanguages?.map((lang) => ({
              uuid: lang.uuid,
              label: lang.label_local,
              flagUrl: lang.svg_flag_url,
            })) || null
          }
          selectedLanguageUuid={selectedVisitorLanguage}
          onChangeLanguage={changeVisitorLanguage}
          title={t('signin_screen.title')}
          inputFields={[
            {
              key: 'email',
              placeholder: t('signin_screen.email_placeholder'),
              error: showEmailError ? t('errors.invalid_email') : null,
              value: email,
              onChange: handleEmailChange,
              type: 'email',
            },
            {
              key: 'password',
              placeholder: t('signin_screen.password_placeholder'),
              error: showPasswordError
                ? t('errors.password_minimum_length')
                : null,
              value: password,
              onChange: handlePasswordChange,
              type: 'password',
            },
          ]}
          forgotElement={
            <Link onClick={handleForgotPasswordClick}>
              {t('signin_screen.forgot_password_link')}
            </Link>
          }
          button={t('signin_screen.sign_in_btn')}
          footer1={
            <Text variant="bodyMd" as="span">
              {t('signin_screen.footer_message')}{' '}
              <Link onClick={handleSignupClick}>
                {t('signin_screen.footer_action_message_link')}
              </Link>
            </Text>
          }
          onSubmit={handleSubmit}
          brandButtons={{
            googleLabel: t('signin_screen.sign_in_with_google'),
            onGoogleClick: handleGoogleClick,
            onFacebookClick: handleFacebookClick,
            onLinkedInClick: handleLinkedIdClick,
          }}
        />
      </div>
    </div>
  );
}

export default VisitorHOC(Login);
