import React from 'react';
import {
  Badge,
  Card,
  Box,
  Text,
  HorizontalStack,
  VerticalStack,
  Button,
} from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import { useGetPerksQuery } from '../perksApi';
import PerksSkeleton from './PerksSkeleton';
import TranslateLink from 'components/TranslateLink';
import './styles.scss';
import RushLoading from 'components/custom/RushLoader';
import RushPage from 'components/custom/RushPage';
import RushFooter from 'components/custom/RushFooter';

function Perks() {
  const [t] = useTranslation();
  const perksResponse = useGetPerksQuery();
  const {
    data: perksList = [],
    isLoading: isPerksLoading,
    isFetching: isPerksFetching,
  } = perksResponse || {};

  return (
    <>
      {isPerksLoading ? (
        <PerksSkeleton />
      ) : (
        <RushPage title={t('perks.title')} subtitle={t('perks.description')}>
          <VerticalStack gap={'4'}>
            {perksList.map((perk) => {
              return (
                <Card roundedAbove="sm" key={perk.link}>
                  <HorizontalStack gap={'4'}>
                    <img
                      src={perk.logo}
                      width="40px"
                      height="40px"
                      style={{ borderRadius: '7px' }}
                    />
                    <VerticalStack gap="1">
                      <HorizontalStack gap="2">
                        {perk.is_new_badge ? (
                          <Badge status="success">New</Badge>
                        ) : (
                          ''
                        )}
                        <Text variant="bodyMd" as="span" fontWeight="semibold">
                          {perk.title}
                        </Text>
                      </HorizontalStack>
                      <Text variant="bodyMd" as="span" color="subdued">
                        {perk.category}
                      </Text>
                    </VerticalStack>
                  </HorizontalStack>

                  <Box paddingBlockEnd="0" paddingBlockStart="5">
                    <TranslateLink text={perk.description}></TranslateLink>
                  </Box>
                  <br />
                  {perk.how_to ? (
                    <VerticalStack gap="4">
                      <Text variant="bodyMd" as="span" fontWeight="semibold">
                        {t('perks.card.how_to_use')}
                      </Text>
                      <TranslateLink text={perk.how_to}></TranslateLink>
                    </VerticalStack>
                  ) : (
                    ''
                  )}
                  <HorizontalStack align="end">
                    <Button external primary target="_blank" url={perk.link}>
                      {t('perks.card.action')}
                    </Button>
                  </HorizontalStack>
                </Card>
              );
            })}
            <RushFooter>
              <TranslateLink text={t('footer_help.dashboard')} />
            </RushFooter>
          </VerticalStack>
        </RushPage>
      )}
      {isPerksFetching ? <RushLoading /> : ''}
    </>
  );
}

export default Perks;
