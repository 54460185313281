import React from 'react';
import { SkeletonDisplayText, Box, HorizontalStack } from '@shopify/polaris';
import './skeleton.scss';

function TabsSkeleton() {
  return (
    <div className="tabs-skeleton">
      <HorizontalStack align="space-between" wrap={false}>
        <Box overflowX="auto" padding={'3'}>
          <HorizontalStack align="space-between" gap="2" wrap={false}>
            <Box minWidth="40px">
              <SkeletonDisplayText size="small" />
            </Box>{' '}
            <Box minWidth="70px">
              <SkeletonDisplayText size="small" />
            </Box>{' '}
            <Box minWidth="70px">
              <SkeletonDisplayText size="small" />
            </Box>
            <Box width="70px">
              <SkeletonDisplayText size="small" />
            </Box>
          </HorizontalStack>
        </Box>
        <div className="vertical-divider"></div>

        <HorizontalStack gap="2" wrap={false}>
          <Box padding={'3'}>
            <HorizontalStack align="space-between" gap="2" wrap={false}>
              <Box width="50px">
                <SkeletonDisplayText size="small" />
              </Box>{' '}
              <Box width="30px">
                <SkeletonDisplayText size="small" />
              </Box>
            </HorizontalStack>
          </Box>
        </HorizontalStack>
      </HorizontalStack>
    </div>
  );
}

export default TabsSkeleton;
