import React, { useEffect, useState, useMemo } from 'react';
import {
  Card,
  IndexFilters,
  useSetIndexFiltersMode,
  Pagination,
  HorizontalStack,
  Box,
  IndexFiltersMode,
  SkeletonDisplayText,
} from '@shopify/polaris';
import CustomIndexTable from './CustomIndexTable';
import EditColumns from './EditColumns';
import { isEqual } from 'lodash';
import TableSkeleton from 'components/Skeletons/TableSkeleton';
import './styles.scss';
import WithoutChecksTableSkeleton from 'components/Skeletons/WithoutChecksTableSkeleton';
function FilterTable(props) {
  const {
    isLoading,
    filterProps = {},
    tableProps = {},
    tabProps = {},
    paginationProps = {},
  } = props;

  const { onSort, queryValue, appliedFilters, onClearAll } = filterProps;
  const {
    columns = [],
    onColumnsChange,
    allowColumnsEdit,
    data = [],
  } = tableProps;
  const { tabs, onTabChange, selectedTab } = tabProps;
  const { mode, setMode } = useSetIndexFiltersMode();

  useEffect(() => {
    if (queryValue || appliedFilters?.length) {
      setMode(IndexFiltersMode.Filtering);
    }
  }, [queryValue, appliedFilters]);
  const [editedColumns, setEditedColumns] = useState([]);

  useEffect(() => {
    setEditedColumns(columns);
  }, [columns]);

  const tabItems = tabs.map((item, index) => ({
    content: item,
    index,
    onAction: () => {},
    id: `${item}-${index}`,
    isLocked: index === 0,
    actions: allowColumnsEdit
      ? [
          {
            type: 'edit-columns',
            onAction: () => {
              setMode(IndexFiltersMode.EditingColumns);
              return true;
            },
          },
        ]
      : [],
  }));

  const handleSort = (value) => {
    onSort(value);
  };

  const saveEditedColumns = () => {
    onColumnsChange(editedColumns);
    setMode(IndexFiltersMode.Default);
  };

  const renderSkeleton = useMemo(() => {
    return tableProps.selectable ? (
      <TableSkeleton />
    ) : (
      <WithoutChecksTableSkeleton />
    );
  }, [tableProps.selectable]);

  const renderTableSection = () => {
    return mode === IndexFiltersMode.EditingColumns ? (
      <EditColumns
        data={data}
        columns={editedColumns}
        onColumnsChange={setEditedColumns}
      />
    ) : (
      <CustomIndexTable
        tableProps={{
          ...tableProps,
          columns: tableProps.columns.filter((c) => !!c.selected),
        }}
      />
    );
  };
  return (
    <div className="common-filter-table">
      <Card roundedAbove="sm" padding={0}>
        <IndexFilters
          {...filterProps}
          loading={isLoading}
          tabs={tabItems}
          selected={selectedTab}
          onSelect={onTabChange}
          canCreateNewView={false}
          mode={mode}
          setMode={setMode}
          cancelAction={{
            onAction: () => {
              if (mode === IndexFiltersMode.EditingColumns) {
                setEditedColumns(columns);
              } else if (mode === IndexFiltersMode.Filtering) {
                onClearAll();
              }
            },
            disabled: false,
            loading: false,
          }}
          primaryAction={
            mode === IndexFiltersMode.EditingColumns
              ? {
                  onAction: saveEditedColumns,
                  disabled: isEqual(columns, editedColumns),
                  loading: false,
                  type: 'save',
                }
              : undefined
          }
          onSort={handleSort}
        />
        <div id="filter-table">
          {isLoading ? renderSkeleton : renderTableSection()}
        </div>
        {data.length === 0 ? (
          <br />
        ) : (
          <Box padding={'5'}>
            <HorizontalStack align="center">
              {isLoading ? (
                <Box width="150px">
                  <SkeletonDisplayText size="small" />
                </Box>
              ) : (
                <Pagination {...paginationProps} />
              )}
            </HorizontalStack>
          </Box>
        )}
      </Card>
    </div>
  );
}

export default FilterTable;
