import {
  Card,
  SkeletonBodyText,
  HorizontalStack,
  Text,
  Box,
  VerticalStack,
} from '@shopify/polaris';
import React from 'react';
import { useTranslation } from 'react-i18next';
import PageItems from './PageItems';
import RefreshPagesButton from './common/RefreshPagesButton';

function PagesLibraryCard({ trackingPages = [], isLoading }) {
  const [t] = useTranslation();
  const libraryCardTitle = t('tracking_page_new.library_card_title');
  const libraryCardDescription = t(
    'tracking_page_new.library_card_description'
  );

  return (
    <Card roundedAbove="sm" padding={0}>
      <VerticalStack gap={'5'}>
        <Box padding={'5'} width="100%" paddingBlockEnd={'0'}>
          <HorizontalStack align="space-between" blockAlign="start" gap={'5'}>
            <VerticalStack gap="2">
              <Text variant="headingMd" as="span">
                {libraryCardTitle}
              </Text>
              <Text color="subdued">{libraryCardDescription}</Text>
            </VerticalStack>
            <RefreshPagesButton />
          </HorizontalStack>
        </Box>
        {isLoading && (
          <Box padding={'5'}>
            <SkeletonBodyText size="medium" lines={6} />
          </Box>
        )}
        {!isLoading && trackingPages.length === 0 ? (
          <Box padding={'5'}>
            <VerticalStack blockAlign="center" align="center">
              <Text variant="bodyMd" as="span" color="subdued">
                {t('tracking_page_new.no_tracking_page_message')}
              </Text>
            </VerticalStack>
          </Box>
        ) : (
          !isLoading && <PageItems pages={trackingPages}></PageItems>
        )}
      </VerticalStack>
    </Card>
  );
}

export default PagesLibraryCard;
