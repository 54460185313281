import React from 'react';
import { Layout } from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import TranslateLink from 'components/TranslateLink/TranslateLink';
import { useMemo } from 'react';
import { STAGE } from 'config';
import { ENV_PRODUCTION } from 'Constants';
import RushPage from 'components/custom/RushPage';
import RushFooter from 'components/custom/RushFooter';
import { STORE_URLS } from 'config/urls';
import { useParams } from 'react-router-dom';
import useIsEmbededApp from 'hooks/useIsEmbededApp';
import RushBanner from 'components/custom/RushBanner';
function MembersAndPermissions() {
  const [t] = useTranslation();
  const params = useParams();
  const isEmbededApp = useIsEmbededApp();
  const appLoginLink = useMemo(() => {
    return STAGE !== ENV_PRODUCTION
      ? 'https://app-staging.rush.app/v/login'
      : 'https://app.rush.app/v/login';
  }, []);

  const appLogin = useMemo(() => {
    return STAGE !== ENV_PRODUCTION
      ? 'app-staging.rush.app/v/login'
      : 'app.rush.app/v/login';
  }, []);

  return (
    <RushPage
      title={t('members_and_permissions_title')}
      backAction={
        isEmbededApp
          ? {
              url: `/s/${params.uuid}${STORE_URLS.MEMBERS_AND_PERMISSIONS}`,
            }
          : undefined
      }
      primaryAction={{
        content: t('members_and_permissions_header_invite_member'),
        disabled: true,
      }}
    >
      <RushBanner
        bannerKey={'members_and_permissions_banner'}
        title={t('members_and_permissions_banner_title')}
        status="info"
      >
        <TranslateLink
          text={t('members_and_permissions_banner_description', {
            'app-login': appLogin,
            'app-login-link': appLoginLink,
          })}
        ></TranslateLink>
      </RushBanner>

      <Layout.Section>
        <RushFooter>
          <TranslateLink text={t('footer_help.dashboard')} />
        </RushFooter>
      </Layout.Section>
    </RushPage>
  );
}
export default MembersAndPermissions;
