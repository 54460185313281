import React, { useState, useCallback, useEffect } from 'react';

import {
  Card,
  VerticalStack,
  RadioButton,
  ChoiceList,
  Text,
} from '@shopify/polaris';
import { useTranslation } from 'react-i18next';

export default function ShipmentSection({
  orderStatusMeta,
  orderPageConfigObj,
  setOrderPageConfigObj,
}) {
  const [t] = useTranslation();
  const [sectionVisibilityData, setSectionVisibilityData] = useState(false);
  const [mapVisible, setMapVisible] = useState(false);
  const [trackButtonValue, setTrackButtonValue] = useState([]);

  useEffect(() => {
    setSectionVisibilityData(
      orderPageConfigObj.configuration.shipment_details_section.visible
    );
    setMapVisible(
      orderPageConfigObj.configuration.shipment_details_section.map_visible
    );
    setTrackButtonValue(
      orderPageConfigObj.configuration.shipment_details_section.track_button
    );
  }, [orderPageConfigObj]);

  const handleSelectSectionVisibilityChange = useCallback(() => {
    setOrderPageConfigObj({
      configuration: {
        ...orderPageConfigObj.configuration,
        shipment_details_section: {
          ...orderPageConfigObj.configuration.shipment_details_section,
          visible: !sectionVisibilityData,
        },
      },
      isUpdated: true,
    });
    setSectionVisibilityData((visible) => !visible);
  }, [orderPageConfigObj, setOrderPageConfigObj, sectionVisibilityData]);

  const handleSelectMapChange = useCallback(() => {
    setOrderPageConfigObj({
      configuration: {
        ...orderPageConfigObj.configuration,
        shipment_details_section: {
          ...orderPageConfigObj.configuration.shipment_details_section,
          map_visible: !mapVisible,
        },
      },
      isUpdated: true,
    });
    setMapVisible((visible) => !visible);
  }, [orderPageConfigObj, setOrderPageConfigObj, mapVisible]);

  const handleSelectTrackOrderChange = useCallback(
    (value) => {
      setOrderPageConfigObj({
        configuration: {
          ...orderPageConfigObj.configuration,
          shipment_details_section: {
            ...orderPageConfigObj.configuration.shipment_details_section,
            track_button: value[0],
          },
        },
        isUpdated: true,
      });
      setTrackButtonValue(value);
    },
    [orderPageConfigObj, setOrderPageConfigObj]
  );

  return (
    <Card roundedAbove="sm">
      <Text variant="headingMd" as="h2">
        {t('settings.thankyou_page.shipment.shipment_card.title')}
      </Text>
      <VerticalStack gap="3">
        <Text variant="bodyMd" as="span" color="subdued">
          {t('settings.thankyou_page.shipment.shipment_card.description')}
        </Text>
        <img
          style={{ width: '100%', height: 'auto' }}
          src="https://assets.rush.app/shopifyapp/shopify-order-status-page-feature/shipment_details_section.png"
          alt=""
        />

        <VerticalStack>
          <Text variant="bodyMd" as="span">
            {t('settings.thankyou_page.shipment.section_visibility.title')}
          </Text>
          <RadioButton
            label={t('settings.thankyou_page.shipment.shown_option')}
            checked={sectionVisibilityData}
            name="shipment_details_section_visible"
            onChange={handleSelectSectionVisibilityChange}
            value={true}
          />
          <RadioButton
            label={t('settings.thankyou_page.shipment.hidden_option')}
            name="shipment_details_section_visible"
            checked={!sectionVisibilityData}
            onChange={handleSelectSectionVisibilityChange}
            value={false}
          />
        </VerticalStack>
        <VerticalStack gap="1">
          <Text variant="bodyMd" as="span">
            {t('settings.thankyou_page.shipment.map_visibility.title')}
          </Text>
          <VerticalStack>
            {' '}
            <RadioButton
              label={t('settings.thankyou_page.shipment.show_option')}
              checked={mapVisible}
              name="mapVisible"
              onChange={handleSelectMapChange}
              value={true}
            />
            <RadioButton
              label={t('settings.thankyou_page.shipment.hide_option')}
              name="mapVisible"
              checked={!mapVisible}
              onChange={handleSelectMapChange}
              value={false}
            />
          </VerticalStack>
        </VerticalStack>
        <div className="small-gap-list">
          <ChoiceList
            title={t(
              'settings.thankyou_page.shipment.track_order_button.title'
            )}
            choices={orderStatusMeta.shipment_details_section.track_button.map(
              (type) => ({
                label: type.label,
                value: type.slug,
              })
            )}
            selected={trackButtonValue}
            onChange={handleSelectTrackOrderChange}
          />
        </div>
      </VerticalStack>
    </Card>
  );
}
