import React, { useEffect, useMemo } from 'react';
import SmallTopMessage from 'components/SmallTopMessage';
import {
  Layout,
  Card,
  Badge,
  Link,
  Text,
  HorizontalStack,
  Box,
  VerticalStack,
  Button,
} from '@shopify/polaris';
import stringHelper from 'helpers/string';
import { useTranslation } from 'react-i18next';
import Subtitle from 'components/Subtitle';
import TranslateLink from 'components/TranslateLink';
import { useGetChargeLimitQuery } from '../billingApis';
import SkeletonBilling, { LoadingCard } from './SkeletonBilling';
import MerchentSuccessManagerCard from './MerchentSuccessManagerCard';
import BookTeamTimeCard from './BookTeamTimeCard';
import moment from 'moment';
import {
  FEATURE_SUBSCRIPTION_PRICE,
  REDIRECT_ACTION_BILLING,
  FEATURE_SHIPMENTS,
  FEATURE_FREE_TRACKINGS,
} from 'Constants';
import { useDispatch } from 'react-redux';
import { billingActions } from '../billingSlice';
import UpdatePlanModal from './UpdatePlanModal';
import RedeemPromoModal from './RedeemPromoModal';
import IncreaseLimitModal from './IncreaseLimitModal';
import { useNavigate, useLocation } from 'react-router-dom';
import { baseActions } from 'redux/store/baseSlice';
import { useGetCurrentBillingPlanQuery } from 'redux/store/commonStoreApis';
import { transformToNumStr } from './utils';
import RushPage from 'components/custom/RushPage';
import RushFooter from 'components/custom/RushFooter';

function Billing() {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const { isLoading: isPlanLoading, data: currentPlan = {} } =
    useGetCurrentBillingPlanQuery();

  const isMonthlyPlan = useMemo(() => {
    return currentPlan?.billing_plan?.subscription_plan
      ?.is_charge_limit_supported;
  }, [currentPlan]);

  const { isLoading: isChargeLoading, data: chargeLimit = {} } =
    useGetChargeLimitQuery(undefined, {
      skip: !isMonthlyPlan || isPlanLoading,
    });

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.get('action') === REDIRECT_ACTION_BILLING) {
      dispatch(
        baseActions.setToastMessage({
          message: t(searchParams.get('message_id')),
          type: 'success',
        })
      );
      navigate({ search: '', replace: true });
    }
  }, [location]);

  /**
   * Actions
   */
  const toggleUpdatePlanModal = () => {
    dispatch(billingActions.toggleUpdateModal());
  };

  const toggleRedeemModal = () => {
    dispatch(billingActions.toggleRedeemModal());
  };

  const toggleChargeLimitModal = () => {
    dispatch(billingActions.toggleChargeLimitModal());
  };

  let dateFormat = useMemo(() => {
    let format = 'MMMM D';
    if (
      !currentPlan?.billing_plan?.subscription_plan?.is_charge_limit_supported
    ) {
      format = 'MMMM D, YYYY';
    }
    return format;
  }, [currentPlan]);

  const perPlanPeriodLabel = useMemo(() => {
    return isMonthlyPlan
      ? 'billing.subscription_plans_modal.billing_intervals.30_days'
      : 'billing.subscription_plans_modal.billing_intervals.annual';
  }, [currentPlan]);

  const billingDetailsCard = () => {
    const pricePlanFeature = currentPlan?.billing_plan?.features?.find(
      ({ feature_id }) => feature_id === FEATURE_SUBSCRIPTION_PRICE
    );
    return (
      <Layout.Section>
        <Card roundedAbove="sm" padding={0}>
          <Box padding={'5'} paddingBlockEnd={'1'}>
            <HorizontalStack
              align="space-between"
              blockAlign="center"
              gap={'2'}
            >
              <HorizontalStack gap={{ sm: 2, md: 4 }}>
                <Text variant="headingMd" as="h2" fontWeight="semibold">
                  {currentPlan?.billing_plan?.subscription_plan.name}
                </Text>
                {currentPlan?.billing_plan?.trial_expired_at ? (
                  <Badge>{t('billing.trail')}</Badge>
                ) : (
                  ''
                )}
              </HorizontalStack>

              <Text variant="bodyMd" as="span" fontWeight="semibold">
                {t(perPlanPeriodLabel, {
                  price: stringHelper.transformToCurrency(
                    parseFloat(pricePlanFeature?.price).toFixed(2)
                  ),
                })}
              </Text>
            </HorizontalStack>
          </Box>

          <Box
            as="div"
            padding={'3'}
            paddingInlineStart={'5'}
            borderBlockEndWidth="1"
            borderColor="border-subdued"
          >
            {currentPlan?.billing_plan?.trial_expired_at && (
              <div className="Row">
                {t('billing.trail_expire_at', {
                  date: moment(
                    currentPlan?.billing_plan?.trial_expired_at
                  ).format(dateFormat),
                })}
              </div>
            )}
            <div className="Row">
              {t('billing.plan_renew_at', {
                date: moment(
                  currentPlan?.billing_plan?.next_billing_cycle_date
                ).format(dateFormat),
              })}
            </div>
          </Box>
          <Box padding="5" paddingBlockEnd={'0'}>
            <VerticalStack gap={'4'}>
              <Text variant="bodyMd" as="span" fontWeight="semibold">
                {t('billing.payment_method')}
              </Text>
              <div>{t('billing.shopify_billing_api')}</div>
            </VerticalStack>
          </Box>
          <Box padding="5" paddingBlockStart={'3'}>
            <HorizontalStack align="start" gap={'2'}>
              <Button onClick={toggleUpdatePlanModal}>
                {t('billing.current_plan_section.card.pick_a_plab_btn_label')}
              </Button>
            </HorizontalStack>
          </Box>
        </Card>
      </Layout.Section>
    );
  };
  const usageChargesCard = () => {
    return (
      <Layout.Section>
        {isChargeLoading ? (
          <LoadingCard />
        ) : (
          <Card roundedAbove="sm">
            <VerticalStack gap={'4'}>
              <HorizontalStack align="space-between" gap={'2'}>
                <HorizontalStack gap={{ sm: 2, md: 4 }}>
                  <Text variant="headingMd" as="h2" fontWeight="semibold">
                    {t('billing.charge_limit_section.info.title')}
                  </Text>

                  {chargeLimit?.charge_status ? (
                    <Badge
                      status={chargeLimit?.charge_status?.badge_prop.status}
                    >
                      <p>{chargeLimit?.charge_status?.label}</p>
                    </Badge>
                  ) : (
                    ''
                  )}
                </HorizontalStack>

                {chargeLimit?.charge_limit?.charge_limit ? (
                  <Box paddingInlineStart={'3'}>
                    up to{' '}
                    <Text variant="bodyMd" as="span" fontWeight="semibold">
                      {t(perPlanPeriodLabel, {
                        price: stringHelper.transformToCurrency(
                          chargeLimit?.charge_limit?.charge_limit.toFixed(2)
                        ),
                      })}
                    </Text>
                  </Box>
                ) : (
                  ''
                )}
              </HorizontalStack>

              {chargeLimit?.charge_limit?.charge_limit ? (
                <VerticalStack gap="4">
                  <HorizontalStack align="space-between">
                    <div>
                      {t('billing.usage_reset_at', {
                        date: moment(
                          currentPlan?.billing_plan?.next_billing_cycle_date
                        ).format(dateFormat),
                      })}
                    </div>

                    <div>
                      {t('billing.charges_used', {
                        used: stringHelper.transformToCurrency(
                          chargeLimit?.charge_limit?.current_usage.toFixed(2)
                        ),
                        total: stringHelper.transformToCurrency(
                          chargeLimit?.charge_limit?.charge_limit.toFixed(2)
                        ),
                      })}
                    </div>
                  </HorizontalStack>
                </VerticalStack>
              ) : (
                <Box paddingBlockStart="3">
                  <HorizontalStack gap={'2'}>
                    <Text variant="bodyMd" as="span" color="subdued">
                      {t('billing.no_charge_limit_set')}
                    </Text>
                    <TranslateLink text={t('billing.learn_charge_limit')} />
                  </HorizontalStack>
                </Box>
              )}
            </VerticalStack>

            <Box paddingBlockStart="3">
              <HorizontalStack align="start" gap={'2'}>
                <Button onClick={toggleChargeLimitModal}>
                  {chargeLimit?.charge_limit?.charge_limit
                    ? t('billing.update_charge_limit')
                    : t('billing.set_charge_limit')}
                </Button>
              </HorizontalStack>
            </Box>
          </Card>
        )}
      </Layout.Section>
    );
  };
  const currentUsageCard = () => {
    const featureShipmentsFeature =
      currentPlan?.billing_plan?.features?.find(
        ({ feature_id }) => feature_id === FEATURE_SHIPMENTS
      ) || {};
    const freeTrackingFeature = currentPlan?.billing_plan?.features?.find(
      ({ feature_id }) => feature_id === FEATURE_FREE_TRACKINGS
    );

    let totalQuota = featureShipmentsFeature.quota;
    let remainingQuota = featureShipmentsFeature.remaining_quota;

    if (freeTrackingFeature) {
      remainingQuota = remainingQuota + freeTrackingFeature?.remaining_quota;
      totalQuota = totalQuota + freeTrackingFeature?.quota;
    }
    const used = totalQuota - remainingQuota;
    return (
      <Layout.Section>
        <Card roundedAbove="sm">
          <Text variant="headingMd" as="h2">
            {t('billing.current_usage')}
          </Text>
          <br />
          <HorizontalStack align="space-between">
            <Box>{t('billing.shipments_in_quota')}</Box>
            <Box>
              {t('billing.shipments_used', {
                used: transformToNumStr(used),
                total: transformToNumStr(totalQuota),
              })}
            </Box>
          </HorizontalStack>
        </Card>
      </Layout.Section>
    );
  };
  const supportCard = () => {
    return (
      <Layout.Section>
        <Card roundedAbove="sm">
          <Text variant="headingMd" as="h2">
            {t('billing.support')}
          </Text>
          <br />
          <VerticalStack gap="2">
            <Text variant="bodyMd" as="span" fontWeight="semibold">
              {t('billing.email_or_chat')}
            </Text>
            <Link
              external
              target="_blank"
              url={`mailto:${t('billing.support_address')}`}
            >
              {t('billing.support_address')}
            </Link>
          </VerticalStack>
        </Card>
      </Layout.Section>
    );
  };
  return (
    <>
      <SmallTopMessage />

      <RushPage
        title={t('billing.page.title')}
        subtitle={
          <Subtitle
            actions={[
              {
                text: t('billing.faq'),
                source: t('billing.faq_link'),
              },
              {
                text: t('billing.terms'),
                source: t('billing.terms_link'),
              },
              {
                text: t('billing.see_price'),
                source: t('billing.see_price_link'),
              },
            ]}
          />
        }
        primaryAction={{
          content: t('billing.redeem'),
          onAction: toggleRedeemModal,
          plain: true,
          primary: false,
          ellipsis: false,
        }}
      >
        {isPlanLoading ? (
          <SkeletonBilling />
        ) : (
          <Layout>
            {billingDetailsCard()}
            {isMonthlyPlan ? usageChargesCard() : ''}
            {currentUsageCard()}

            {supportCard()}
            <Layout.Section>
              <RushFooter>
                <TranslateLink text={t('footer_help.dashboard')} />
              </RushFooter>
            </Layout.Section>
          </Layout>
        )}
      </RushPage>
      <UpdatePlanModal />
      <RedeemPromoModal />
      <IncreaseLimitModal />
    </>
  );
}

export default Billing;
