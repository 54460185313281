import React, { useState, useEffect } from 'react';
import { Layout } from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import SmallTopMessage from 'components/SmallTopMessage';
import FeatureSection from './FeatureSection';
import LoadingCard from '../SettingsLoadingPage/LoadingCard';
import { FEATURE_TRACKING_PAGE, FEATURE_EMAIL } from 'Constants';
import { useNavigate } from 'react-router-dom';
import { STORE_URLS } from 'config/urls';
import TranslateLink from 'components/TranslateLink';
import RushPage from 'components/custom/RushPage';
import RushFooter from 'components/custom/RushFooter';
export default function MerchantNote({
  getMerchantNote,
  putMerchantNote,
  merchantNoteList,
  merchantNoteError,
  shop,
}) {
  const [t] = useTranslation();
  const navigate = useNavigate();

  const [merchantNoteObject, setMerchantNoteObject] = useState(null);
  const [emailObject, setEmailObject] = useState(null);

  useEffect(() => {
    if (_.isEmpty(merchantNoteList)) {
      getMerchantNote();
    } else {
      const tempMerchantNoteObject = merchantNoteList.list
        .map((item) => {
          if (item.feature_id === FEATURE_TRACKING_PAGE) {
            return item;
          } else {
            return undefined;
          }
        })
        .filter((item) => !_.isUndefined(item));
      const tempEmailObject = merchantNoteList.list
        .map((item) => {
          if (item.feature_id === FEATURE_EMAIL) {
            return item;
          } else {
            return undefined;
          }
        })
        .filter((item) => !_.isUndefined(item));
      setMerchantNoteObject(tempMerchantNoteObject[0]);
      setEmailObject(tempEmailObject[0]);
    }
  }, [getMerchantNote, merchantNoteList]);

  if (merchantNoteError) {
    navigate(`/s/${shop.storeUUID}${STORE_URLS.ADDONS}`);
  }

  return (
    <>
      <SmallTopMessage />
      <RushPage
        backAction={{
          url: `/s/${shop.storeUUID}${STORE_URLS.ADDONS}`,
        }}
        title={t('settings.merchant_note.header.title')}
      >
        <Layout>
          {merchantNoteObject ? (
            <FeatureSection
              translationField="merchant_note.tracking_page"
              putMerchantNote={putMerchantNote}
              sectionInfo={merchantNoteObject}
              merchantNoteListMeta={merchantNoteList.meta}
            />
          ) : (
            <Layout.AnnotatedSection>
              <LoadingCard />
            </Layout.AnnotatedSection>
          )}
          {emailObject ? (
            <FeatureSection
              translationField="merchant_note.email_section"
              putMerchantNote={putMerchantNote}
              sectionInfo={emailObject}
              merchantNoteListMeta={merchantNoteList.meta}
            />
          ) : (
            <Layout.AnnotatedSection>
              <LoadingCard />
            </Layout.AnnotatedSection>
          )}
          <Layout.Section>
            <RushFooter>
              <TranslateLink text={t('footer_help.dashboard')} />
            </RushFooter>
          </Layout.Section>
        </Layout>
      </RushPage>
    </>
  );
}
