import {
  Card,
  Layout,
 
  SkeletonBodyText,
  SkeletonDisplayText,
} from '@shopify/polaris';
import Subtitle from 'components/Subtitle';
import RushPage from 'components/custom/RushPage';
import { STORE_URLS } from 'config/urls';
import LoadingCard from 'pages/Settings/SettingsLoadingPage/LoadingCard';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

function ShipbobSkeleton() {
  const params = useParams();
  const [t] = useTranslation();
  return (
    <RushPage
      backAction={{
        url: `/s/${params.uuid}${STORE_URLS.ADDONS}`,
      }}
      title={t('settings.shipbob_page.title')}
      subtitle={
        <Subtitle
          text={t('settings.shipbob_pages.description')}
          actions={[
            {
              text: t('settings.shipbob_pages.subtitle.read_more'),
              source: 'https://support.rush.app/integrating-shipbob-and-rush',
            },
          ]}
        />
      }
    >
      <Layout>
        <Layout.AnnotatedSection
          title={t('shipbob.connect.title')}
          description={t('shipbob.connect.caption')}
        >
          <Card roundedAbove="sm">
            <div className="SettingCard ">
              <div className="SettingCard-Heading">
                <SkeletonBodyText lines={1} />
              </div>
              <div className="SettingCard-Status"></div>
              <div className="SettingCard-Thumbnail">
                <SkeletonDisplayText />
              </div>
            </div>
          </Card>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection
          title={t('settings.shipbob_page.connected_accounts_layout.title')}
          description={t(
            'settings.shipbob_page.connected_accounts_layout.description'
          )}
        >
          <LoadingCard />
        </Layout.AnnotatedSection>
      </Layout>
    </RushPage>
  );
}

export default ShipbobSkeleton;
