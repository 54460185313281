import React, { useState, useEffect, useMemo } from 'react';
import { Layout, CalloutCard, VerticalStack } from '@shopify/polaris';
import SmallTopMessage from 'components/SmallTopMessage';
import Subtitle from 'components/Subtitle';
import TranslateLink from 'components/TranslateLink/TranslateLink';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import './styles.scss';
import { useSelector } from 'react-redux';
import { useUpdateFullfilmentNotificationMutation } from './fullfilmentEventsApis';
import { useGetSettingsQuery } from 'pages/Settings/settingsApis';
import { FEATURE_FULLFILMENT_NOTIFICATION } from 'Constants';
import CustomToggleSettings from 'components/CustomToggleSettings';
import useAdminLink from 'hooks/useAdminLink';
import RushLoading from 'components/custom/RushLoader';
import RushPage from 'components/custom/RushPage';
import RushFooter from 'components/custom/RushFooter';
import RushBanner from 'components/custom/RushBanner';

function FullfilmentEvents() {
  const [t] = useTranslation();
  const pageTitle = t('fullfilment_events.title');
  const pageSubTitle = t('fullfilment_events.subtitle');
  const getStartedLink = t('fullfilment_events.get_started_link');
  const toggleFeatureContent = t('fullfilment_events.toogle_card.title');
  const enhancementTitle = t('fullfilment_events.enhancement_card.title');
  const enhancementDescription = t(
    'fullfilment_events.enhancement_card.description'
  );

  const nativeCardTitle = t('fullfilment_events.native_card.title');
  const nativeCardButton = t('fullfilment_events.native_card.button');

  const reviewappCardTitle = t('fullfilment_events.reviewapp_card.title');
  const reviewappCardButton = t('fullfilment_events.reviewapp_card.button');

  const navigate = useNavigate();
  const adminLink = useAdminLink();

  const [isActive, setIsActive] = useState(true);
  const storeUUID = useSelector((state) => state.shop?.storeUUID);
  const {
    data: { feature_group: featureList = [] } = {},
    isLoading: isStatusLoading,
    isSuccess: isStatusLoaded,
    refetch,
  } = useGetSettingsQuery({ type: 'notification' });

  const notificationFeature = useMemo(() => {
    if (!isStatusLoading && isStatusLoaded) {
      return featureList
        .find((g) => g.title === 'Free')
        ?.features?.find(
          (f) => f.feature_id === FEATURE_FULLFILMENT_NOTIFICATION
        );
    } else {
      return {};
    }
  }, [isStatusLoaded, isStatusLoading]);

  const [
    updateFullfilmentNotification,
    { isLoading: isNotificationsUpdating, isSuccess: isNotificationsUpdated },
  ] = useUpdateFullfilmentNotificationMutation();

  useEffect(() => {
    if (!isNotificationsUpdating && isNotificationsUpdated) {
      setIsActive((active) => !active);
      refetch();
    }
  }, [isNotificationsUpdating, isNotificationsUpdated]);

  useEffect(() => {
    if (!isStatusLoading && isStatusLoaded) {
      setIsActive(notificationFeature?.is_configured);
    }
  }, [notificationFeature]);

  const toggleFeatureToggleLabel = !isActive
    ? t('settings_page.addon_card.button.label_activate')
    : t('settings_page.addon_card.button.label_deactivate');

  const toggleEvents = () => {
    const activation_type = isActive ? 'deactivate' : 'activate';
    updateFullfilmentNotification({
      type: activation_type,
      storeId: storeUUID,
    });
  };

  // eslint-disable-next-line no-unused-vars
  const enhancementCard = () => {
    return (
      <RushBanner
        bannerKey={'enhancement-card'}
        title={enhancementTitle}
        status="info"
      >
        <TranslateLink text={enhancementDescription} />
      </RushBanner>
    );
  };
  const nativeNotificationsCard = () => {
    const notificationsLnk = `${adminLink}/settings/notifications`;
    const nativeCardDescription = t(
      'fullfilment_events.native_card.description',
      {
        settings_notifications_link: notificationsLnk,
      }
    );
    return (
      <CalloutCard
        title={nativeCardTitle}
        primaryAction={{
          content: nativeCardButton,
          url: notificationsLnk,
          disabled: !isActive,
          external: true,
          target: '_blank',
        }}
      >
        <TranslateLink text={nativeCardDescription} external={false} />
      </CalloutCard>
    );
  };
  const reviewAppsCard = () => {
    const appsLnk = `/s/${storeUUID}/addons/product-reviews`;
    const reviewappCardDescription = t(
      'fullfilment_events.reviewapp_card.description1',
      { review_apps: appsLnk }
    );
    return (
      <CalloutCard
        title={reviewappCardTitle}
        primaryAction={{
          content: reviewappCardButton,
          url: appsLnk,
          disabled: !isActive,
        }}
      >
        <VerticalStack gap={'4'}>
          <TranslateLink
            text={t('fullfilment_events.reviewapp_card.description')}
          />
          <TranslateLink text={reviewappCardDescription} pathType={'app'} />
        </VerticalStack>
      </CalloutCard>
    );
  };

  return (
    <div className={`fullfilment-main`}>
      {isStatusLoading ? <RushLoading /> : ''}
      <SmallTopMessage />
      <RushPage
        backAction={{
          onAction: () => navigate(-1),
        }}
        title={pageTitle}
        subtitle={
          <Subtitle
            text={pageSubTitle}
            actions={[
              {
                text: t('common.get-started'),
                source: getStartedLink,
              },
            ]}
          />
        }
      >
        <Layout>
          {/* <Layout.Section>{enhancementCard()}</Layout.Section> */}
          <Layout.Section>
            <CustomToggleSettings
              buttonLabel={toggleFeatureToggleLabel}
              onToggle={toggleEvents}
              isActive={isActive}
              content={toggleFeatureContent}
              loading={isNotificationsUpdating || isStatusLoading}
            ></CustomToggleSettings>
          </Layout.Section>
          <div className={`${isActive ? '' : 'disabled'}`}>
            <Layout.Section>{nativeNotificationsCard()}</Layout.Section>
            <Layout.Section>{reviewAppsCard()}</Layout.Section>
          </div>
          <Layout.Section>
            <RushFooter>
              <TranslateLink text={t('footer_help.dashboard')} />
            </RushFooter>
          </Layout.Section>
        </Layout>
        <br />
      </RushPage>
    </div>
  );
}

export default FullfilmentEvents;
