import React, { useMemo } from 'react';
import { Box, SkeletonBodyText, Text, VerticalStack } from '@shopify/polaris';
import { DB_DATE_FORMAT_STRING } from 'Constants';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useGetDeliveryTimesQuery } from 'pages/Dashboard/DashboardApi';
import InfoTable from '../InfoTable';
export default function DeliveryTimes({ selectedDates = {} }) {
  const [t] = useTranslation();

  const from = useMemo(() => {
    return moment(selectedDates.start).format(DB_DATE_FORMAT_STRING);
  }, [selectedDates.start]);
  const to = useMemo(() => {
    return moment(selectedDates.end).format(DB_DATE_FORMAT_STRING);
  }, [selectedDates.end]);

  const deliveryTimesResponse = useGetDeliveryTimesQuery(
    { from, to },
    { skip: !(from || to) }
  );
  const {
    data: deliveryTimes = [],
    isFetching,
    isSuccess,
  } = deliveryTimesResponse || {};

  const deliveryTimesData = useMemo(() => {
    return deliveryTimes.map((time) => {
      return [
        time.title,
        <Text key={time.title} variant="bodyMd" as="span" color="subdued">
          {' '}
          {time.days} days
        </Text>,
      ];
    });
  }, [deliveryTimes]);

  return (
    <Box padding={'5'}>
      <VerticalStack gap="3">
        <Text variant="headingSm" as="span">
          {t('dashboard.analytics_sheet.delivery_times_title')}
        </Text>

        {isFetching || !isSuccess ? (
          <Box>
            <SkeletonBodyText />
          </Box>
        ) : (
          deliveryTimes && <InfoTable data={deliveryTimesData} />
        )}
      </VerticalStack>
      {!isFetching && deliveryTimes?.length === 0 && (
        <div className="CustomEmptyState">
          <Text variant="bodyMd" as="span" color="subdued">
            {t('dashboard.analytics_sheet.no_row_message')}
          </Text>
        </div>
      )}
    </Box>
  );
}
