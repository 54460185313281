import {
  Avatar,
  Button,
  Card,
  Layout,
  Link,
  ResourceList,
  Box,
  Text,
  HorizontalStack,
  VerticalStack,
} from '@shopify/polaris';
import { CancelSmallMinor } from '@shopify/polaris-icons';
import { ClientStorage } from 'ClientStorage';
import { LOCAL_STORAGE } from 'Constants';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ContactCardsHOC from './ContactCardsHOC';
import './styles.scss';

function BookTeamTimeCard({
  showCloseButton = false,
  isConditionFulfilled = false,
}) {
  const [t] = useTranslation();
  const [hide, setHide] = useState(false);

  useEffect(() => {
    setHide(!isConditionFulfilled);
  }, [isConditionFulfilled]);

  const handleRemove = () => {
    setHide(true);
    ClientStorage.set(LOCAL_STORAGE.SHOW_FOUNDER_CARD, false);
  };
  return hide ? (
    ''
  ) : (
    <Layout.Section>
      <div className="bookingCards">
        <Card roundedAbove="sm">
          <HorizontalStack align="space-between">
            <Box>
              <Text variant="headingMd" as="h2">
                {t('billing.book_team_title')}
              </Text>
            </Box>
            {showCloseButton ? (
              <Box>
                <Button
                  plain
                  icon={CancelSmallMinor}
                  onClick={handleRemove}
                ></Button>
              </Box>
            ) : (
              ''
            )}
          </HorizontalStack>
          <br />
          <VerticalStack gap={'5'}>
            <Text variant="bodyMd" as="span" color="subdued">
              {t('billing.book_team_description')}
            </Text>
            <ResourceList
              items={[]}
              renderItem={(item) => {
                const { id, name, email, img, meetingLink } = item;
                const media = (
                  <Avatar customer size="medium" name={name} source={img} />
                );

                return (
                  <ResourceList.Item id={id} media={media}>
                    <VerticalStack gap="1">
                      <Text variant="bodyMd" as="span" fontWeight="semibold">
                        {name}
                      </Text>
                      <Link target="_blank" url={`mailto:${email}`}>
                        {email}
                      </Link>
                      <Link target="_blank" url={meetingLink}>
                        {t('common.schedule_meeting')}
                      </Link>
                    </VerticalStack>
                  </ResourceList.Item>
                );
              }}
            />
          </VerticalStack>
        </Card>
      </div>
    </Layout.Section>
  );
}

export default ContactCardsHOC(BookTeamTimeCard);
