export const getShipmentBadge = (slug) => {
  switch (slug) {
    case 'pending':
      return {
        progress: 'incomplete',
      };
    case 'pre_transit':
      return {
        progress: 'partiallyComplete',
      };
    case 'pickup':
      return {
        progress: 'partiallyComplete',
        status: 'success',
      };
    case 'in_transit':
      return {
        progress: 'partiallyComplete',
        status: 'info',
      };
    case 'waiting_for_delivery':
      return {
        progress: 'partiallyComplete',
        status: 'warning',
      };
    case 'out_for_delivery':
      return {
        progress: 'partiallyComplete',
        status: 'attention',
      };
    case 'failed_attempt':
    case 'failure':
      return {
        progress: 'partiallyComplete',
        status: 'critical',
      };
    case 'delivered':
      return {
        progress: 'complete',
        status: 'success',
      };
    case 'exception':
      return {
        status: 'critical',
      };
    case 'returned':
    case 'expired':
      return {
        progress: 'complete',
        status: 'critical',
      };
    default:
      return {
        progress: undefined,
        status: undefined,
      };
  }
};

export const getEventBadge = (slug) => {
  switch (slug) {
    case 'order_created':
    case 'shipment_created':
      return {
        progress: 'incomplete',
        status: 'info',
      };
    case 'failed_delivery_attempt':
      return {
        progress: 'partiallyComplete',
        status: 'critical',
      };
    case 'out_for_delivery':
      return {
        progress: 'partiallyComplete',
        status: 'attention',
      };
    case 'order_pickup_ready':
      return {
        progress: 'partiallyComplete',
        status: 'success',
      };
    case 'in_transit':
      return {
        progress: 'partiallyComplete',
        status: 'info',
      };
    case 'pre_transit':
      return {
        progress: 'partiallyComplete',
      };

    case 'exception':
      return {
        status: 'critical',
      };
    case 'return':
      return {
        progress: 'complete',
        status: 'critical',
      };
    case 'delivered':
      return {
        progress: 'complete',
        status: 'success',
      };

    case 'shipment_delay':
    case 'new_estimated_time_of_arrival':
      return {
        progress: 'partiallyComplete',
        status: 'info',
      };

    case 'waiting_for_delivery':
      return {
        progress: 'partiallyComplete',
        status: 'warning',
      };
    default:
      return {
        progress: undefined,
        status: undefined,
      };
  }
};

export const getActivityStatusBadge = (slug) => {
  switch (slug) {
    case 'Queued':
      return {
        progress: 'incomplete',
      };
    case 'Processing':
      return {
        progress: 'partiallyComplete',
        status: 'warning',
      };
    case 'Sent':
      return {
        status: 'success',
      };
    case 'Failed':
      return {
        status: 'critical',
      };
    default:
      return {
        progress: undefined,
        status: undefined,
      };
  }
};
