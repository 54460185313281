import {
  Button,
  ButtonGroup,
  Form,
  FormLayout,
  HorizontalStack,
  TextField,
  Modal,

  VerticalStack,
} from '@shopify/polaris';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
  useLazyApplyPromoCodeQuery,
  usePostPromoChargesMutation,
} from '../billingApis';
import { billingActions } from '../billingSlice';
import { PROMO_TYPE_SUBSCRIPTION } from 'Constants';
import RushLoading from 'components/custom/RushLoader';
import RushModal from 'components/custom/RushModal';
function RedeemPromoModal() {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const [code, setCode] = useState('');
  const [error, setError] = useState('');
  const { showRedeemModal } = useSelector((state) => state.billing);
  useEffect(() => {
    if (showRedeemModal) {
      setCode('');
    }
  }, [showRedeemModal]);

  const handleClose = () => {
    setError('');
    dispatch(billingActions.toggleRedeemModal());
  };

  const [applyPromoCode, resp] = useLazyApplyPromoCodeQuery();

  const {
    isFetching: isApplyingCode,
    error: { data: { errors: promoError } = {} } = {},
    isError,
    data: promoResponse = {},
    isSuccess,
  } = resp;

  useEffect(() => {
    if (isError && promoError) {
      setError(promoError?.display?.description);
    }
  }, [isError, promoError]);

  const [
    postPromoCharges,
    { isLoading: isPosting, isSuccess: isPosted, data: postedData },
  ] = usePostPromoChargesMutation();

  useEffect(() => {
    if (!isApplyingCode && isSuccess && promoResponse) {
      if (promoResponse.promo_type === PROMO_TYPE_SUBSCRIPTION) {
        postPromoCharges(code);
      }
    }
  }, [isApplyingCode, isSuccess, promoResponse]);

  useEffect(() => {
    if (!isPosting && isPosted) {
      window.top.location.replace(postedData.charge_url);
    }
  }, [isPosting, isPosted, postedData]);

  return (
    <>
      {isPosting || isApplyingCode ? <RushLoading /> : ''}
      <RushModal
        open={showRedeemModal}
        onClose={handleClose}
        title={t(`billing.promo_modal.title`)}
      >
        <Modal.Section>
          <VerticalStack gap="5">
            <p>{t(`billing.promo_modal.message`)}</p>

            <Form onSubmit={() => applyPromoCode(code)}>
              <FormLayout>
                <FormLayout.Group>
                  <TextField
                    autoComplete="off"
                    placeholder={t(`billing.promo_modal.input.default`)}
                    value={code}
                    onChange={(v) => {
                      setCode(v);
                      setError('');
                    }}
                    error={error}
                    disabled={isApplyingCode}
                    id="input-promo-code-modal"
                  />
                </FormLayout.Group>
              </FormLayout>
            </Form>

            <HorizontalStack align="start">
              <ButtonGroup>
                <Button
                  primary
                  onClick={() => applyPromoCode(code)}
                  plain
                  loading={isApplyingCode}
                  disabled={!code}
                >
                  {t(`billing.promo_modal.redeem_button`)}
                </Button>
                <Button onClick={handleClose} loading={isApplyingCode}>
                  {t(`common.cancel`)}
                </Button>
              </ButtonGroup>
            </HorizontalStack>
          </VerticalStack>
        </Modal.Section>
      </RushModal>
    </>
  );
}

export default RedeemPromoModal;
