import React from 'react';
import {
  SkeletonPage,
  Layout,
  Card,
  SkeletonBodyText,
  VerticalStack,
  SkeletonDisplayText,
  Box,
} from '@shopify/polaris';

function SkeletonMessageTimelinePage() {
  return (
    <SkeletonPage primaryAction>
      <Layout>
        <Layout.Section>
          <Card roundedAbove="sm">
            <VerticalStack gap={'4'}>
              <SkeletonDisplayText size="small" />
              <Box>
                <SkeletonBodyText lines={5} />
              </Box>
            </VerticalStack>
            <br />
            <VerticalStack gap={'4'}>
              <SkeletonDisplayText size="small" />
              <Box>
                <SkeletonBodyText />
              </Box>
            </VerticalStack>
          </Card>
        </Layout.Section>
        <Layout.Section>
          <Card roundedAbove="sm">
            <Box>
              <VerticalStack gap={'4'}>
                <SkeletonDisplayText size="small" />
                <Box>
                  <SkeletonBodyText lines={4} />
                </Box>
              </VerticalStack>
            </Box>
          </Card>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );
}
export default SkeletonMessageTimelinePage;
