import React from 'react';
import {
  Text,
  Card,
  Button,
  Layout,
  Divider,
  VerticalStack,
  HorizontalStack,
  Box,
} from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import './styles.scss';

function AddNewPageCard({ onAdd }) {
  const { t } = useTranslation();

  const cardTitle = t('tracking_page_new.card_title');
  const cardDescription = t('tracking_page_new.card_description');
  const card1Label = t('tracking_page_new.card1_label');
  const card1Description = t('tracking_page_new.card1_description');
  const card2Label = t('tracking_page_new.card2label');
  const card2Description = t('tracking_page_new.card2description');
  const card3Label = t('tracking_page_new.card3_label');
  const card3Description = t('tracking_page_new.card3_description');
  const card4Label = t('tracking_page_new.card4_label');
  const card4Description = t('tracking_page_new.card4_description');

  const cards = [
    {
      slug: 'os2',
      title: card1Label,
      summary: card1Description,
      image:
        'https://assets.rush.app/app/tracking-page-themes/theme-online-store.jpg',
    },
    {
      slug: 'modern',
      title: card2Label,
      summary: card2Description,
      image:
        'https://assets.rush.app/app/tracking-page-themes/theme-modern.jpg',
    },
    {
      slug: 'source',
      title: card3Label,
      summary: card3Description,
      image: 'https://assets.rush.app/app/tracking-page-themes/theme-code.jpg',
    },
    {
      slug: 'api',
      title: card4Label,
      summary: card4Description,
      image: 'https://assets.rush.app/app/tracking-page-themes/theme-code.jpg',
    },
  ];

  return (
    <Layout.Section>
      <VerticalStack gap={'2'}>
        <Divider />
        <Text variant="headingMd" as="span">
          {cardTitle}
        </Text>
        <Text color={'subdued'}>{cardDescription}</Text>
      </VerticalStack>
      <br />
      <Card roundedAbove="sm">
        <ul className="page-list">
          {cards.map((card) => (
            <div className="page-card" key={card.slug}>
              <div className="page-img-container">
                <img className="page-img" src={card.image} alt="" />
              </div>
              <Box padding={'4'}>
                <HorizontalStack blockAlign="center" wrap={false}>
                  <Box width="100%">
                    <VerticalStack gap="1">
                      <Text as="span" variant="headingSm">
                        {card.title}
                      </Text>
                      <Text as="span" color="subdued">
                        {card.summary}
                      </Text>
                    </VerticalStack>
                  </Box>
                  <Box>
                    <Button onClick={() => onAdd(card.slug)}>Add</Button>
                  </Box>
                </HorizontalStack>
              </Box>
            </div>
          ))}
        </ul>
      </Card>
    </Layout.Section>
  );
}

export default AddNewPageCard;
