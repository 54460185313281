import {
  Card,
  Layout,
  SkeletonBodyText,
  TextField,
  VerticalStack,
} from '@shopify/polaris';
import React from 'react';
import { useTranslation } from 'react-i18next';
import RushPage from 'components/custom/RushPage';
function AccountSkeleton() {
  const [t] = useTranslation();
  return (
    <RushPage title={t('account.title')}>
      <Layout>
        <Layout.AnnotatedSection title={t('account.card.title')}>
          <Card roundedAbove="sm">
            <VerticalStack gap={'5'}>
              <VerticalStack gap={'5'}>
                <SkeletonBodyText lines={1} />
                <TextField disabled autoComplete="off" />
              </VerticalStack>
              <VerticalStack gap={'5'}>
                <SkeletonBodyText lines={1} />
                <TextField disabled autoComplete="off" />
              </VerticalStack>
            </VerticalStack>
            <br />
            <VerticalStack gap={'5'}>
              <SkeletonBodyText lines={1} />
              <TextField disabled autoComplete="off" />
            </VerticalStack>
            <br />
            <VerticalStack gap={'5'}>
              <SkeletonBodyText lines={1} />
              <TextField disabled autoComplete="off" />
            </VerticalStack>
          </Card>
        </Layout.AnnotatedSection>
      </Layout>
    </RushPage>
  );
}

export default AccountSkeleton;
