import {
  Card,
  HorizontalStack,
  Text,
  VerticalStack,
  Button,
  Box,
  Divider,
} from '@shopify/polaris';
import React from 'react';
import '../styles.scss';
import RushLogo from 'images/tryrush-icon.svg';
import ArrowRightMinor from 'images/ArrowRightMinor.svg';
import { useTranslation } from 'react-i18next';
import TranslateLink from 'components/TranslateLink';

function Step1(props) {
  const { icon, name, onCancel, onConnect, storeName } = props;
  const [t] = useTranslation();

  return (
    <Card padding={0}>
      <Box width="600px" height="400px" padding={8}>
        <VerticalStack gap={8} align="center" inlineAlign="center">
          <Text variant="heading2xl" as="h2" alignment="center">
            {t('install_modal.step2.title', { integration_name: name })}
          </Text>
          <HorizontalStack align="center" gap={6}>
            <img src={RushLogo} alt={'logo'} width={'40'} height={'40'} />
            <img src={ArrowRightMinor} />
            <img src={icon} alt={'logo'} width={'40'} height={'40'} />
          </HorizontalStack>
          <Box width="70%">
            <HorizontalStack align="center">
              <Text>
                <TranslateLink
                  text={t('install_modal.step2.description', {
                    store_name: storeName,
                  })}
                ></TranslateLink>
              </Text>
            </HorizontalStack>
          </Box>
        </VerticalStack>
      </Box>
      <Divider />
      <Box padding={4}>
        <HorizontalStack gap={2} align="end" wi>
          <Button size="medium" onClick={onCancel}>
            {t('common.cancel')}
          </Button>
          <Button primary onClick={onConnect}>
            {t('common.connect')}
          </Button>
        </HorizontalStack>
      </Box>
    </Card>
  );
}

export default Step1;
