import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Card, Filters, Layout, Tabs } from '@shopify/polaris';
import Subtitle from 'components/Subtitle';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SmallTopMessage from 'components/SmallTopMessage';
import MaskingDataTable from './MaskingDataTable';
import { useGetCarrierMaskingQuery } from '../maskingApi';
import MaksingsEmptyState from './MaksingsEmptyState';
import { getTargetCarrierLabel } from './utils';
import './styles.scss';
import TranslateLink from 'components/TranslateLink';
import { useAllCarriersQuery } from 'redux/store/commonBaseApis';
import WithoutChecksTabsTableSkeleton from 'components/Skeletons/WithoutChecksTabsTableSkeleton';
import RushPage from 'components/custom/RushPage';
import RushFooter from 'components/custom/RushFooter';
import { STORE_URLS } from 'config/urls';
import useIsEmbededApp from 'hooks/useIsEmbededApp';
function CarrierMasking() {
  const navigate = useNavigate();
  const location = useLocation();
  const [t] = useTranslation();
  const params = useParams();
  const isEmbededApp = useIsEmbededApp();
  const [queryValue, setQueryValue] = useState('');
  const [selectedTab, setSelectedTab] = useState(0);
  const [maskings, setMaskings] = useState([]);
  const carriersResponse = useAllCarriersQuery();

  const { data: allCarriers = [], isLoading: isCarriersLoading } =
    carriersResponse;
  const maskingsResponse = useGetCarrierMaskingQuery();

  const {
    data,
    isLoading: isMaskingsLoading,
    isFetching: isMaskingsFetching,
  } = maskingsResponse || {};

  useEffect(() => {
    if (!isMaskingsFetching && Array.isArray(data) && data.length > 0) {
      setMaskings([...data].sort((a, b) => a.index - b.index));
    }
  }, [data, isMaskingsFetching]);

  const filteredMaskings = useMemo(() => {
    if (queryValue) {
      const filterRegex = new RegExp(queryValue, 'i');
      return (maskings || []).filter((option) => {
        const name =
          option.override_name_option === 1 || option.completely_hide_carrier
            ? 'Hidden'
            : option.override_name_option === 0
            ? 'Keep carrier name'
            : option.override_name_value;
        const targetCarrierLabel = option.target_carriers_all
          ? 'All'
          : getTargetCarrierLabel(option.target_carriers_uuid, allCarriers);
        return (
          name?.match(filterRegex) || targetCarrierLabel?.match(filterRegex)
        );
      });
    } else {
      return maskings;
    }
  }, [queryValue, maskings]);

  const tabMaskings = useMemo(() => {
    switch (selectedTab) {
      case 0:
        return filteredMaskings;
      case 1:
        return (filteredMaskings || []).filter((masking) => masking.is_active);
      case 2:
        return (filteredMaskings || []).filter((masking) => !masking.is_active);
    }
  }, [selectedTab, filteredMaskings]);

  const handleFiltersQueryChange = useCallback(
    (value) => setQueryValue(value),
    []
  );
  const handleQueryValueRemove = useCallback(() => setQueryValue(''), []);

  const handleTabChange = useCallback(
    (selectedTabIndex) => setSelectedTab(selectedTabIndex),
    []
  );

  const tabs = [
    {
      id: 'all-maskings',
      content: t('carrier-maskings.tab-all'),
      panelID: 'all-maskings',
    },
    {
      id: 'activated-maskings',
      content: t('carrier-maskings.tab-activated'),
      panelID: 'activated-maskings',
    },
    {
      id: 'deactivated-maskings',
      content: t('carrier-maskings.tab-deactivated'),
      panelID: 'deactivated-maskings',
    },
  ];

  const handleAddClick = () => {
    navigate(location.pathname + '/add');
  };
  return (
    <div className="carrier-masking-main">
      <SmallTopMessage />
      <RushPage
        title={t('carrier-maskings.title')}
        primaryAction={
          !isMaskingsLoading && maskings.length
            ? {
                content: t('carrier-maskings.add'),
                onAction: handleAddClick,
              }
            : undefined
        }
        backAction={
          isEmbededApp
            ? {
                url: `/s/${params.uuid}${STORE_URLS.SHIPMENTS}`,
              }
            : undefined
        }
        subtitle={
          <Subtitle
            text={t('carrier-maskings.sub-title')}
            actions={[
              {
                text: t('carrier-maskings.get-started'),
                source: t('carrier-maskings.get-started-link'),
              },
              {
                text: t('carrier-maskings.for-dropshippers'),
                source: t('carrier-maskings.for-dropshippers-link'),
              },
            ]}
          />
        }
      >
        <Card roundedAbove="sm" padding={0}>
          {(isMaskingsLoading || isCarriersLoading) && (
            <WithoutChecksTabsTableSkeleton />
          )}
          {!isMaskingsLoading && !isCarriersLoading && !maskings.length && (
            <MaksingsEmptyState onAdd={handleAddClick} />
          )}
          {!isMaskingsLoading && !isCarriersLoading && maskings.length ? (
            <Tabs tabs={tabs} selected={selectedTab} onSelect={handleTabChange}>
              <Box width="100%">
                <Filters
                  queryPlaceholder="Filter items"
                  filters={[]}
                  queryValue={queryValue}
                  onQueryChange={handleFiltersQueryChange}
                  onQueryClear={handleQueryValueRemove}
                />
              </Box>

              <MaskingDataTable
                maskings={maskings}
                tabMaskings={tabMaskings}
                allCarriers={allCarriers}
                setMaskings={setMaskings}
              />
            </Tabs>
          ) : (
            ''
          )}
        </Card>
        <Layout.Section>
          <RushFooter>
            <TranslateLink text={t('footer_help.dashboard')} />
          </RushFooter>
        </Layout.Section>
      </RushPage>
    </div>
  );
}

export default CarrierMasking;
