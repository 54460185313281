import React, { useState } from 'react';
import {
  ChoiceList,
  Form,
  FormLayout,
  Checkbox,
  SkeletonBodyText,
  Text,
  Card,
  VerticalStack,
  Box,
  Divider,
  HorizontalStack,
} from '@shopify/polaris';
import TranslateLink from 'components/TranslateLink';
import { useTranslation } from 'react-i18next';
import ReprocessModal from './ReprocessModal';
import ButtonWithJob from 'components/ButtonWithJob';
import { useDispatch } from 'react-redux';
import { baseActions } from 'redux/store/baseSlice';
export default function FulfillmentSettings(props) {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const {
    isEnabled,
    selectedOption,
    onChangeSetting,
    fulfillmentSettings = {},
    isLoading,
    togglePreferences,
  } = props;
  const [showReprocessModal, setShowReProcessModal] = useState(false);

  const [jobLink, setJobLink] = useState('');
  const toggleModal = () => {
    setShowReProcessModal(!showReprocessModal);
  };

  const handleJobDone = () => {
    setJobLink(null);
    dispatch(
      baseActions.setToastMessage({
        message: t('shipment_settings_new.fulfillment.reprocessed_toast'),
        type: 'success',
      })
    );
  };
  return (
    <>
      <Card roundedAbove="sm" padding={0}>
        <Box padding="5">
          <VerticalStack gap={'5'}>
            <VerticalStack gap={'2'}>
              <Text as="h4" variant="headingMd">
                {t('shipment_settings_new.fulfillment.card_title')}
              </Text>
              <Text variant="bodyMd" as="span" color="subdued">
                <TranslateLink
                  text={t('shipment_settings_new.fulfillment.card_description')}
                />
              </Text>
            </VerticalStack>
            {isLoading ? (
              <Box>
                <SkeletonBodyText />
              </Box>
            ) : (
              <Form>
                <FormLayout>
                  <Checkbox
                    label={t(
                      'shipment_settings_new.fulfillment.shopify_shipping_notification_label'
                    )}
                    checked={isEnabled}
                    onChange={(value) => {
                      togglePreferences(value);
                    }}
                  />
                  {isEnabled ? (
                    <>
                      <Text as="bodyMd">
                        {t(
                          'shipment_settings_new.fulfillment.shopify_list_title'
                        )}
                      </Text>
                      <div className="small-gap-list">
                        <ChoiceList
                          title=""
                          choices={(fulfillmentSettings?.triggers || []).map(
                            (type) => ({
                              label: type.title,
                              value: type.id,
                              disabled: [
                                'set_to_last_mile_carrier_tracking_number_and_url',
                                'update_to_last_mile_carrier_number_and_tracking_page_url',
                              ].includes(type.slug),
                            })
                          )}
                          selected={[selectedOption]}
                          onChange={onChangeSetting}
                        />
                      </div>
                    </>
                  ) : (
                    ''
                  )}
                </FormLayout>
              </Form>
            )}
          </VerticalStack>
        </Box>

        <Divider borderColor="border" />

        <Box padding="5">
          <VerticalStack gap={'2'}>
            <Text as="h4" variant="headingSm">
              {t('shipment_settings_new.fulfillment.reprocess_title')}
            </Text>
            <VerticalStack gap={'5'}>
              <Text variant="bodyMd" as="span" color="subdued">
                <TranslateLink
                  text={t(
                    'shipment_settings_new.fulfillment.reprocess_description'
                  )}
                />
              </Text>
              <HorizontalStack wrap={false}>
                <ButtonWithJob
                  skipCall={!showReprocessModal}
                  skipLoader={true}
                  key={jobLink}
                  job_link={jobLink}
                  primary
                  onClick={toggleModal}
                  label={t(
                    'shipment_settings_new.fulfillment.reprocess_button'
                  )}
                  onJobDone={handleJobDone}
                ></ButtonWithJob>
              </HorizontalStack>
            </VerticalStack>
          </VerticalStack>
        </Box>
      </Card>
      {showReprocessModal ? (
        <ReprocessModal
          onClose={toggleModal}
          isJobRunning={!!jobLink}
          onJobStart={(link) => setJobLink(link)}
        />
      ) : (
        ''
      )}
    </>
  );
}
