import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Card,
  Checkbox,
  ChoiceList,
  Layout,
  Box,
  TextField,
  Text,
  HorizontalStack,
  VerticalStack,
  Divider,
} from '@shopify/polaris';
import SmallTopMessage from 'components/SmallTopMessage';
import Subtitle from 'components/Subtitle';
import TranslateLink from 'components/TranslateLink';
import PreferencesCard from './PreferencesCard';
import TransitTimesCard from './TransitTimesCard';
import ProductList from 'pages/PreShipmentTimeline/components/MessageTimelinePage/ProductList';
import ProductsPicker from 'components/ProductsPicker/ProductsPicker';
import CountriesAutoComplete from 'components/CountriesAutoComplete';
import DateRangesCard from './DateRangesCard';
import { useGetEDDConfigQuery, useSetEDDConfigMutation } from '../smartEDDApis';
import isEqual from 'lodash/isEqual';
import { useDispatch } from 'react-redux';
import '../styles.scss';
import TransitTimePage from '../TransitTimePage';
import SkeletonSmartEDD from './SkeletonSmartEDD';
import ClearCacheButton from 'components/ClearCacheButton';
import { orderBy, sortBy } from 'lodash';
import { useLazyGetProductsByIdsQuery } from 'redux/store/commonStoreApis';
import FeaturePageWrapper from 'components/FeaturePageWrapper';
import { FEATURES_SLUG } from 'Constants';
import { STORE_URLS } from 'config/urls';
import { useParams } from 'react-router-dom';
import UpgradePlanBanner from 'components/UpgradePlanBanner';
import EDDTimesModal from './EDDTimesModal';
import { getVisibilityOpts } from '../utils';
import { baseActions } from 'redux/store/baseSlice';
import CustomToggleSettings from 'components/CustomToggleSettings';
import RushLoading from 'components/custom/RushLoader';
import RushSaveBar from 'components/custom/RushSaveBar';
import RushPage from 'components/custom/RushPage';
import RushFooter from 'components/custom/RushFooter';

function SmartEDD({ isFeatureAvailable }) {
  const [t] = useTranslation();
  const params = useParams();
  const dispatch = useDispatch();
  const [productsFilterQuery, setProductsFilterQuery] = useState('');
  const [showProductsPicker, setShowProductsPicker] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [selectedDateRanges, setSelectedDateRanges] = useState([]);
  const [selectedDateFormat, setSelectedDateFormat] = useState(undefined);
  const [orderFulfillmentDays, setOrderFulfillmentDays] = useState(3);
  const [transitTimes, setTransitTimes] = useState([]);
  const [isActive, setIsActive] = useState(false);
  const [preferences, setPreferences] = useState([]);
  const [outOfStock, setOutOfStock] = useState(false);
  const [virtualProducts, setVirtualProducts] = useState(false);
  const [transitEditIndex, setTransitEditIndex] = useState(-1);
  const [showTransitPage, setShowTransitPage] = useState(false);
  const [showTimesModal, setShowTimesModal] = useState(false);
  const [showContryError, setShowContryError] = useState(false);

  const [visibilityOptions, setVisibilityOptions] = useState([
    'show_on_app_block',
    'show_on_tracking_page',
    'show_on_notification',
  ]);

  const [
    getProducts,
    {
      data: productsResponse = {},
      isFetching: isProductsLoading,
      isSuccess: isProductsLoaded,
    },
  ] = useLazyGetProductsByIdsQuery();

  useEffect(() => {
    if (isProductsLoaded && !isProductsLoading) {
      setSelectedProducts(sortBy(productsResponse.products, 'id'));
    }
  }, [isProductsLoaded, productsResponse, isProductsLoading]);

  const eddConfigResponse = useGetEDDConfigQuery();
  const {
    data: originalEDDData = {},
    isLoading: isEDDLoading,
    isFetching: isEDDFetching,
    isSuccess: isEDDLoaded,
  } = eddConfigResponse || {};

  const [updateEDD, updateEDDConfigResponse] = useSetEDDConfigMutation();
  const {
    data: updateRes = {},
    isLoading: isEDDUpdating,
    isSuccess: isEDDUpdated,
  } = updateEDDConfigResponse;

  const initState = (discardCase) => {
    setIsActive(originalEDDData.is_active);
    setPreferences(originalEDDData.source_priority);
    setSelectedDateFormat(originalEDDData.estimated_delivery_date_mode);
    setOrderFulfillmentDays(originalEDDData.order_fulfilment_days);
    setOrderedTransitTimes(originalEDDData.transit_rules?.transit_times);
    setSelectedCountries(originalEDDData.excluded_countries_code_iso3);
    setSelectedDateRanges(originalEDDData.excluded_date_ranges);
    setOutOfStock(originalEDDData.exclude_out_of_stock_products);
    setVirtualProducts(originalEDDData.exclude_virtual_products);
    setVisibilityOptions(getVisibilityOpts(originalEDDData));
    getProductsIfRequired(discardCase);
  };

  const getProductsIfRequired = (discardCase) => {
    if (discardCase) {
      setSelectedProducts(sortBy(productsResponse.products, 'id'));
    } else {
      const alreadyHaveProds = selectedProducts.map(({ id }) => Number(id));
      const requiredProd = (originalEDDData.excluded_products || []).map(
        ({ id }) => id
      );

      if (!requiredProd.every((reqId) => alreadyHaveProds.includes(reqId))) {
        getProducts(
          (originalEDDData.excluded_products || [])
            .map(({ id }) => id)
            .join(',')
        );
      }
    }
  };

  const setOrderedTransitTimes = (times, recalculateOrders) => {
    if (Array.isArray(times)) {
      let orderedTimes = [...times];
      if (recalculateOrders) {
        orderedTimes = times.map((t, index) => {
          return {
            ...t,
            order: index + 1,
          };
        });
      }
      setTransitTimes(orderBy(orderedTimes, 'order'));
    }
  };

  useEffect(() => {
    if (!isEDDLoading && isEDDLoaded && originalEDDData) {
      initState();
    }
  }, [originalEDDData, isEDDLoading, isEDDLoaded]);

  useEffect(() => {
    if (isEDDUpdated) {
      dispatch(
        baseActions.setToastMessage({
          message: updateRes.message,
          type: 'success',
        })
      );
    }
  }, [isEDDUpdated, updateRes]);

  const getTobeSavedObj = () => {
    return {
      source_priority: preferences,
      estimated_delivery_date_mode: selectedDateFormat,
      order_fulfilment_days: orderFulfillmentDays,
      transit_rules:
        orderFulfillmentDays || transitTimes
          ? {
              transit_times: transitTimes,
            }
          : undefined,
      excluded_countries_code_iso3: selectedCountries?.length
        ? selectedCountries
        : null,
      excluded_products: selectedProducts.map(({ id }) => ({
        id: Number(id),
      })),
      excluded_date_ranges: selectedDateRanges,
      exclude_out_of_stock_products: outOfStock,
      exclude_virtual_products: virtualProducts,
      show_on_app_block: visibilityOptions.includes('show_on_app_block'),
      show_on_notification: visibilityOptions.includes('show_on_notification'),
      show_on_tracking_page: visibilityOptions.includes(
        'show_on_tracking_page'
      ),
    };
  };
  const isDirtyState = useMemo(() => {
    const currentObject = getTobeSavedObj();
    const orgObject = Object.keys(currentObject).reduce((prev, next) => {
      prev[next] = originalEDDData[next];
      return prev;
    }, {});
    return !isEqual(orgObject, currentObject) && !isProductsLoading;
  });

  const toggleProductsPicker = (query = '') => {
    setShowProductsPicker((show) => {
      if (show) {
        setProductsFilterQuery('');
      } else {
        setProductsFilterQuery(query);
      }
      return !show;
    });
  };

  const handlePickProducts = (products = []) => {
    handleProductsChange(products);
    toggleProductsPicker();
  };

  const handleOnBrowse = (query = '') => {
    toggleProductsPicker(query);
  };

  const handleProductsChange = (products) => {
    setSelectedProducts(sortBy(products, 'id'));
  };

  const toggleActive = () => {
    setIsActive((active) => !active);
    updateEDD({
      ...originalEDDData,
      is_active: !isActive,
    });
  };

  const validateData = () => {
    /**
     * if user is on trasnit time page and try to save without country, don't allow
     */
    try {
      if (showTransitPage) {
        const geoLoc = transitTimes[transitEditIndex].geo_location_targeted;
        if (Array.isArray(geoLoc) && geoLoc.length === 0) {
          setShowContryError(true);
          return false;
        }
      }
      setShowContryError(false);

      return true;
    } catch {
      setShowContryError(false);
      return true;
    }
  };

  const toggleTransitPage = () => {
    setShowTransitPage((show) => !show);
    window.scrollTo(0, 0);
  };

  const editTransitTime = (time, index) => {
    let updated = [...transitTimes];
    updated.splice(index, 1, time);
    setOrderedTransitTimes(updated, true);
  };

  const deleteTransitTime = (index) => {
    let updated = [...transitTimes];
    updated.splice(index, 1);
    setOrderedTransitTimes(updated, true);
    toggleTransitPage();
  };

  const onAddTransit = () => {
    const newItem = {
      date_range: null,
      geo_location_targeted: null,
      override_order_fulfillment_days: false,
      override_order_fulfillment_days_value: null,
      order: transitTimes.length + 1,
      single_day: 1,
    };
    let updated = [...transitTimes];
    updated.push(newItem);
    setOrderedTransitTimes(updated, true);
    setTransitEditIndex(transitTimes.length);
    toggleTransitPage();
  };

  const onEditTransit = (index) => {
    setTransitEditIndex(index);
    toggleTransitPage();
  };

  const handleTransitTimeChange = (time, index) => {
    editTransitTime(time, index);
  };

  const handleSave = () => {
    if (validateData()) {
      const toBeSaved = getTobeSavedObj();
      // eslint-disable-next-line no-unused-vars
      const { created_at, updated_at, uuid, ...orgEDDData } = originalEDDData;
      updateEDD({
        ...orgEDDData,
        ...toBeSaved,
      });
    }
  };

  const handleDiscard = () => {
    initState(true);
    setShowTransitPage(false);
  };

  const toggleTimesModal = () => {
    setShowTimesModal(!showTimesModal);
  };

  const handleVisibilityOptChange = useCallback(
    (value) => setVisibilityOptions(value),
    []
  );

  const eddVisibilityCard = () => {
    return (
      <Card roundedAbove="sm">
        <Text variant="headingMd" as="h2">
          {t('smart-edd.visibility.card.title1')}
        </Text>
        <VerticalStack gap="4">
          <Text variant="bodyMd" as="span" color="subdued">
            {t('smart-edd.visibility.card.description')}
          </Text>
          <div className="small-gap-list">
            <ChoiceList
              allowMultiple
              choices={[
                {
                  label: t('smart-edd.visibility.card.option1'),
                  value: 'show_on_app_block',
                  disabled: true,
                },
                {
                  label: t('smart-edd.visibility.card.option2'),
                  value: 'show_on_tracking_page',
                },
                {
                  label: t('smart-edd.visibility.card.option3'),
                  value: 'show_on_notification',
                },
              ]}
              selected={visibilityOptions}
              onChange={handleVisibilityOptChange}
            />
          </div>
        </VerticalStack>
      </Card>
    );
  };

  const eddPreferenceCard = () => {
    return (
      <Layout.AnnotatedSection
        title={t('smart-edd.preference.title')}
        description={t('smart-edd.preference.description')}
      >
        <Card roundedAbove="sm" padding={0}>
          <Box padding={'5'}>
            <HorizontalStack align="space-between" blockAlign="center">
              <Text variant="headingMd" as="span">
                {t('smart-edd.preference.card.title1')}
              </Text>
              <Button plain primary onClick={toggleTimesModal}>
                {t('smart-edd.preference.see-edd-times')}
              </Button>
            </HorizontalStack>
          </Box>
          <Box
            padding={'5'}
            paddingBlockStart={'0'}
            borderBlockEndWidth="1"
            borderColor="border-subdued"
          >
            <VerticalStack gap="2">
              <VerticalStack gap="2">
                <Text variant="bodyMd" as="span" color="subdued">
                  {t('smart-edd.preference.card.description')}
                </Text>
                <Card roundedAbove="sm" padding={0}>
                  <PreferencesCard
                    preferences={preferences}
                    onChange={setPreferences}
                  />
                </Card>
              </VerticalStack>
              <TranslateLink text={t('smart-edd.preference.card.help')} />
            </VerticalStack>
          </Box>
          <Box
            padding={'5'}
            borderBlockEndWidth="1"
            borderColor="border-subdued"
          >
            <div className="small-gap-list">
              <ChoiceList
                title={
                  <Text variant="bodyMd" as="span" fontWeight="semibold">
                    {t('smart-edd.preference.card.title2')}{' '}
                  </Text>
                }
                choices={[
                  {
                    label: t('smart-edd.preference.card.single-date'),
                    value: 'single',
                  },
                  {
                    label: t('smart-edd.preference.card.date-range'),
                    value: 'range',
                  },
                ]}
                selected={
                  selectedDateFormat ? [selectedDateFormat] : ['single']
                }
                onChange={([val]) => setSelectedDateFormat(val)}
              />
            </div>
          </Box>
          <Box
            padding={'5'}
            borderBlockEndWidth="1"
            borderColor="border-subdued"
          >
            <VerticalStack gap="2">
              <Text variant="bodyMd" as="span" fontWeight="semibold">
                {t('smart-edd.transit-time-rules.card.sub-title')}
              </Text>
              <Text variant="bodyMd" as="span" color="subdued">
                {t('smart-edd.transit-time-rules.card.description')}
              </Text>

              <HorizontalStack>
                <TextField
                  autoComplete={'off'}
                  min={0}
                  max={90}
                  prefix={t('smart-edd.transit-time-rules.business_days')}
                  label=""
                  value={orderFulfillmentDays}
                  onChange={(val) => {
                    if (val >= 0 && val <= 90) {
                      setOrderFulfillmentDays(Number(val));
                    }
                  }}
                  type="number"
                />
              </HorizontalStack>
              <Text variant="bodyMd" as="span" color="subdued">
                {t('smart-edd.transit-time-rules.card.footer', {
                  days: originalEDDData.stat_order_fulfilment_days,
                })}
              </Text>
            </VerticalStack>
          </Box>
        </Card>
        <br />
        {eddVisibilityCard()}
      </Layout.AnnotatedSection>
    );
  };

  const eddRulesCard = () => {
    return (
      <Layout.AnnotatedSection
        title={t('smart-edd.transit-time-rules.title')}
        description={t('smart-edd.transit-time-rules.description')}
      >
        <TransitTimesCard
          orderFulfillmentDays={orderFulfillmentDays}
          times={transitTimes}
          onAdd={onAddTransit}
          onEdit={onEditTransit}
          onChange={setOrderedTransitTimes}
        />
      </Layout.AnnotatedSection>
    );
  };

  const eddExcludeProductsCard = () => {
    return (
      <Layout.AnnotatedSection
        title={t('smart-edd.exclude-products.title')}
        description={t('smart-edd.exclude-products.description')}
      >
        <Card roundedAbove="sm" padding={0}>
          <Box padding={'5'} paddingBlockEnd={'0'}>
            <Text variant="headingMd" as="h2">
              {t('smart-edd.exclude-products.card.title')}
            </Text>
          </Box>
          <Box padding={'5'}>
            <VerticalStack gap="1">
              <Checkbox
                label={t('smart-edd.exclude-products.card.checkbox1')}
                checked={outOfStock}
                onChange={setOutOfStock}
              />
              <Checkbox
                label={t('smart-edd.exclude-products.card.checkbox2')}
                checked={virtualProducts}
                onChange={setVirtualProducts}
              />
            </VerticalStack>
          </Box>
          <Divider />
          <ProductList
            header={
              <HorizontalStack align="space-between">
                <Text variant="headingSm" as="h3">
                  {t('pre-shipment.selected-products')}
                </Text>
                <ClearCacheButton name="products" />
              </HorizontalStack>
            }
            keyString={'id'}
            queryString={productsFilterQuery}
            onBrowse={handleOnBrowse}
            products={selectedProducts || []}
            onChange={handleProductsChange}
            loading={isProductsLoading}
          />
        </Card>
      </Layout.AnnotatedSection>
    );
  };

  const eddExcludeRegionCard = () => {
    return (
      <Layout.AnnotatedSection
        title={t('smart-edd.exclude-geo-region.title')}
        description={t('smart-edd.exclude-geo-region.description')}
      >
        <Card roundedAbove="sm">
          <Text variant="headingMd" as="h2">
            {t('smart-edd.exclude-geo-region.card.title')}
          </Text>
          <br />
          <CountriesAutoComplete
            allowMultiple={true}
            label={''}
            placeholder="Select countries"
            onChange={setSelectedCountries}
            value={selectedCountries || []}
          />
        </Card>
      </Layout.AnnotatedSection>
    );
  };

  return (
    <>
      <SmallTopMessage />
      {isDirtyState && !isEDDFetching && isFeatureAvailable && (
        <RushSaveBar
          message={t('common.unsaved-changes')}
          saveAction={{
            content: t('common.save'),
            onAction: handleSave,
            loading: isEDDUpdating,
          }}
          discardAction={{
            onAction: handleDiscard,
            loading: isEDDUpdating,
            content: t('common.discard'),
          }}
        />
      )}
      {showTransitPage ? (
        <TransitTimePage
          times={transitTimes}
          onChange={handleTransitTimeChange}
          onBack={toggleTransitPage}
          onDelete={deleteTransitTime}
          timeIndex={transitEditIndex}
          showContryError={showContryError}
          setShowContryError={setShowContryError}
        />
      ) : (
        <RushPage
          title={t('smart-edd.page-title')}
          backAction={{
            url: `/s/${params.uuid}${STORE_URLS.ADDONS}`,
          }}
          subtitle={
            <Subtitle
              text={t('smart-edd.sub-title')}
              actions={[
                {
                  text: t('smart-edd.get-started'),
                  source: t('smart-edd.get-started-link'),
                },
                {
                  text: t('smart-edd.watch-video'),
                  source: t('smart-edd.for-dropshippers-link'),
                },
              ]}
            />
          }
        >
          {!isEDDFetching && !isFeatureAvailable ? (
            <>
              <UpgradePlanBanner isAdvancedPlanFeature={true} />
              <br />
            </>
          ) : (
            ''
          )}

          {isEDDLoading ? (
            <SkeletonSmartEDD />
          ) : (
            <div
              className={
                !isEDDFetching && !isFeatureAvailable
                  ? 'disabled-feature-page'
                  : ''
              }
            >
              <Layout>
                <Layout.AnnotatedSection
                  title={t('smart-edd.delivery-date.title')}
                  description={t('smart-edd.delivery-date.description')}
                >
                  <CustomToggleSettings
                    buttonLabel={
                      !isActive
                        ? t('settings_page.addon_card.button.label_activate')
                        : t('settings_page.addon_card.button.label_deactivate')
                    }
                    onToggle={toggleActive}
                    disabled={!isEDDFetching && !isFeatureAvailable}
                    isActive={isActive}
                    content={t('smart-edd.delivery-date.card.title')}
                  ></CustomToggleSettings>
                </Layout.AnnotatedSection>
                <div
                  className={`tobedisabled-container ${
                    !isActive ? 'disabled-feature-page' : ''
                  }`}
                >
                  {eddPreferenceCard()}
                  {eddRulesCard()}
                  {eddExcludeProductsCard()}
                  {eddExcludeRegionCard()}
                  <DateRangesCard
                    dateItems={sortBy(selectedDateRanges, 'from').reverse()}
                    onChange={(values = []) =>
                      setSelectedDateRanges(sortBy(values, 'from').reverse())
                    }
                  />
                </div>
              </Layout>
            </div>
          )}
        </RushPage>
      )}
      {showProductsPicker ? (
        <ProductsPicker
          onAdd={handlePickProducts}
          onCancel={toggleProductsPicker}
          selectedProducts={selectedProducts || []}
          queryString={productsFilterQuery}
        />
      ) : (
        ''
      )}
      {isEDDFetching ? <RushLoading /> : ''}
      {showTimesModal ? <EDDTimesModal onClose={toggleTimesModal} /> : ''}
      <Layout.Section>
        <RushFooter>
          <TranslateLink text={t('footer_help.dashboard')} />
        </RushFooter>
      </Layout.Section>
    </>
  );
}

export default FeaturePageWrapper(SmartEDD, FEATURES_SLUG.SMART_EDD);
