import React, { useEffect } from 'react';
import TranslateLink from 'components/TranslateLink';
import {
  Card,
  VerticalStack,
  ChoiceList,
  FormLayout,
  Text,
  TextField,
  Select,
} from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull';

export default function KlaviyoDetails({
  loading = true,
  klaviyoMeta,
  localKlaviyoObj,
  setPublicApiKey,
  setSelectedLanguage,
  setSelectedTrigger,
  publicApiKey,
  publicApiKeyError,
  selectedLanguage,
  selectedTrigger,
  updateKlaviyoPayload,
  privateApiKey,
  privateApiKeyError,
  setPrivateApiKey,
}) {
  const [t] = useTranslation();

  const handlePrivateApiKeyChange = (value) => {
    setPrivateApiKey(value);
    updateKlaviyoPayload();
  };

  const handlePublicApiKeyChange = (value) => {
    setPublicApiKey(value);
    updateKlaviyoPayload();
  };

  const handleSelectedLanguageChange = (value) => {
    setSelectedLanguage(value);
    updateKlaviyoPayload();
  };

  const handleSelectedTriggerChange = (value) => {
    setSelectedTrigger(value);
    updateKlaviyoPayload();
  };

  useEffect(() => {
    if (!isEmpty(localKlaviyoObj.config) && localKlaviyoObj.config.triggers) {
      const { config } = localKlaviyoObj;
      const temp = config.triggers
        .map((trigger) => (trigger.is_active ? trigger.trigger_slug : null))
        .filter((item) => !isNull(item));
      setPublicApiKey(config.public_api_key);
      setPrivateApiKey(config.private_api_key);
      setSelectedLanguage(config.language);
      setSelectedTrigger(temp);
    }
  }, [
    localKlaviyoObj,
    setPrivateApiKey,
    setPublicApiKey,
    setSelectedLanguage,
    setSelectedTrigger,
  ]);

  const text = t('settings.klaviyo.details.section.description');

  return (
    <Card roundedAbove="sm">
      <VerticalStack gap="4">
        <Text variant="headingMd" as="h2">
          {t('settings.klaviyo.details.section.title')}
        </Text>
        <VerticalStack gap="2">
          <TranslateLink text={text} />
          <FormLayout>
            <TextField
              autoComplete={'off'}
              label={t('klaviyo.modal.public_api_key_label')}
              onChange={handlePublicApiKeyChange}
              value={publicApiKey}
              error={publicApiKeyError}
              id={`input-klaviyo-public-key`}
            />
            <TextField
              autoComplete={'off'}
              label={t('klaviyo.modal.private_api_key_label')}
              onChange={handlePrivateApiKeyChange}
              value={privateApiKey}
              error={privateApiKeyError}
              id={`input-klaviyo-private-key`}
            />
            <Select
              label={t('klaviyo.modal.language_label')}
              options={klaviyoMeta.languages.map((lang) => ({
                label: lang.label,
                value: lang.slug,
              }))}
              onChange={handleSelectedLanguageChange}
              value={selectedLanguage}
              disabled={loading}
              id={`select-klaviyo-selected-language`}
            />

            {klaviyoMeta && klaviyoMeta.triggers && (
              <div className="small-gap-list">
                <ChoiceList
                  allowMultiple
                  title={t('klaviyo.modal.trigger_label')}
                  choices={klaviyoMeta.triggers.map((trigger) => ({
                    label: trigger.label,
                    value: trigger.slug,
                  }))}
                  selected={selectedTrigger}
                  onChange={handleSelectedTriggerChange}
                  id={`select-klaviyo-triggers`}
                />
              </div>
            )}
          </FormLayout>
        </VerticalStack>
      </VerticalStack>
    </Card>
  );
}
