import { Box, HorizontalStack, Text, VerticalStack } from '@shopify/polaris';
import React from 'react';
import { useTranslation } from 'react-i18next';

function DeliveryTimes({ statistics }) {
  const [t] = useTranslation();
  const {
    stat_days_order_created_to_fulfillment,
    stat_days_fulfillment_to_in_transit,
    stat_days_order_created_to_delivery,
    stat_days_fulfillment_created_to_delivery,
    stat_days_in_transit_to_delivery,
  } = statistics;

  const showCard =
    stat_days_order_created_to_fulfillment ||
    stat_days_fulfillment_to_in_transit ||
    stat_days_order_created_to_delivery ||
    stat_days_fulfillment_created_to_delivery ||
    stat_days_in_transit_to_delivery;

  return !showCard ? (
    ''
  ) : (
    <Box padding={4}>
      <VerticalStack gap={4}>
        <Text variant="headingMd">{t('shipment_details.delivery_time')}</Text>
        <VerticalStack gap={1}>
          {stat_days_order_created_to_delivery ? (
            <HorizontalStack align="space-between">
              <Text>{t('shipment_details.order_to_delivery')}</Text>
              <Text>{stat_days_order_created_to_delivery}</Text>
            </HorizontalStack>
          ) : (
            ''
          )}
          {stat_days_order_created_to_fulfillment ? (
            <HorizontalStack align="space-between">
              <Text>{t('shipment_details.order_to_fulfillment')}</Text>
              <Text>{stat_days_order_created_to_fulfillment}</Text>
            </HorizontalStack>
          ) : (
            ''
          )}
          {stat_days_fulfillment_created_to_delivery ? (
            <HorizontalStack align="space-between">
              <Text>{t('shipment_details.fulfillment_to_delivery')}</Text>
              <Text>{stat_days_fulfillment_created_to_delivery}</Text>
            </HorizontalStack>
          ) : (
            ''
          )}
          {stat_days_fulfillment_to_in_transit ? (
            <HorizontalStack align="space-between">
              <Text>{t('shipment_details.fulfillment_to_in_transit')}</Text>
              <Text>{stat_days_fulfillment_to_in_transit}</Text>
            </HorizontalStack>
          ) : (
            ''
          )}
          {stat_days_in_transit_to_delivery ? (
            <HorizontalStack align="space-between">
              <Text>{t('shipment_details.in_transit_to_delivery')}</Text>
              <Text>{stat_days_in_transit_to_delivery}</Text>
            </HorizontalStack>
          ) : (
            ''
          )}
        </VerticalStack>
      </VerticalStack>
    </Box>
  );
}
export default DeliveryTimes;
