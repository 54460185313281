import React from 'react';

import { Card, VerticalStack, Button, Text, Box } from '@shopify/polaris';
import TranslateLink from 'components/TranslateLink';
import { useTranslation } from 'react-i18next';
import './style.scss';

export default function KlaviyoFlows() {
  const [t] = useTranslation();

  const text = t('settings.klaviyo.actions.section.part2.description');
  return (
    <div className="klaviyoFlows">
      <Card roundedAbove="sm">
        <VerticalStack gap="4">
          <Text variant="headingMd" as="h2">
            {t('settings.klaviyo.actions.section.part2.title')}
          </Text>
          <VerticalStack gap="3">
            <TranslateLink text={text} />
            <Box>
              <Button class="alignRight" outline disabled>
                {t('settings.klaviyo.actions.section.part2.button')}
              </Button>
            </Box>
          </VerticalStack>
        </VerticalStack>
      </Card>
    </div>
  );
}
