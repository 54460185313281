import React, { useMemo } from 'react';
import {
  Button,
  Tooltip,
  Text,
  VerticalStack,
  HorizontalStack,
} from '@shopify/polaris';
import './style.css';
import { useTranslation } from 'react-i18next';
import SubscriptionBoxSkeleton from './SubscriptionBoxSkeleton';
import { billingActions } from 'pages/Billing/billingSlice';
import { useDispatch, useSelector } from 'react-redux';
import UpdatePlanModal from 'pages/Billing/components/UpdatePlanModal';
import { useGetCurrentBillingPlanQuery } from 'redux/store/commonStoreApis';

export default function SubscriptionBox() {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const storeUUID = useSelector((state) => state.shop?.storeUUID);

  const {
    isFetching: isLoading,
    isSuccess,
    data: { billing_plan: billingPlan = {} } = {},
  } = useGetCurrentBillingPlanQuery(undefined, {
    skip: !window.location?.pathname?.includes(storeUUID) || !storeUUID,
  });

  const planInfo = useMemo(() => {
    if (!isLoading && billingPlan.features) {
      const feature2 =
        billingPlan?.features?.find((feature) => feature.feature_id === 2) ||
        {};
      const used = feature2.quota - feature2.remaining_quota;
      return {
        feature2,
        used,
        isEightyPercentUsed: used / feature2.quota >= 0.8,
      };
    } else {
      return {};
    }
  }, [billingPlan, isLoading]);

  return isLoading || !isSuccess ? (
    <SubscriptionBoxSkeleton />
  ) : (
    <React.Fragment>
      <div className={'left-bar-header'}>
        <HorizontalStack align="space-between" blockAlign={'center'}>
          <VerticalStack gap={'1'}>
            <Text variant="headingMd" as="h2">
              {billingPlan.subscription_plan?.name}
            </Text>
            <Tooltip
              content={t('dashboard.analytics_sheet.shipment_quota_title')}
            >
              <Text
                variant="bodyMd"
                as="span"
                color={planInfo.isEightyPercentUsed ? 'critical' : ''}
              >
                <div className={'usage'}>{`${Number(
                  planInfo.used || 0
                )?.toLocaleString('en-US')} / ${Number(
                  planInfo.feature2?.quota || 0
                )?.toLocaleString('en-US')}`}</div>
              </Text>
            </Tooltip>
          </VerticalStack>
          <Button
            onClick={() => {
              dispatch(billingActions.toggleUpdateModal());
            }}
            primary
          >
            {t('dashboard.analytics_sheet.shipment_quota_upgrade_btn')}
          </Button>
        </HorizontalStack>
      </div>
      <UpdatePlanModal />
    </React.Fragment>
  );
}
