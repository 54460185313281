import {
  Box,
  Button,
  ButtonGroup,
  Card,
  Icon,
  Spinner,
  Text,
  VerticalStack,
  HorizontalStack,
} from '@shopify/polaris';
import {
  ArrowDownMinor,
  ArrowUpMinor,
  EditMinor,
} from '@shopify/polaris-icons';
import { orderBy } from 'lodash';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetCountriesQuery } from 'redux/store/commonStoreApis';
import '../styles.scss';

function TransitTimesCard({
  times,
  onAdd,
  onEdit,
  onChange,
  orderFulfillmentDays,
}) {
  const [t] = useTranslation();

  const { data: countriesList = [], isLoading } = useGetCountriesQuery();

  const orderedTimes = useMemo(() => {
    return orderBy(times, 'order');
  }, [times]);

  const reorderArray = (arr, old_index, new_index) => {
    if (new_index >= arr.length || new_index < 0) {
      return arr;
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);

    return arr;
  };
  const handleMoveUp = (index) => {
    let updated = reorderArray([...orderedTimes], index, index - 1);
    syncUpdates(updated);
  };

  const handleMoveDown = (index) => {
    let updated = reorderArray([...orderedTimes], index, index + 1);
    syncUpdates(updated);
  };

  const syncUpdates = (updated) => {
    onChange(
      updated.map((t, i) => {
        return {
          ...t,
          order: i + 1,
        };
      })
    );
  };

  const emptyStatemarkup = (
    <div className="empty_markup">
      <Text variant="bodyMd" as="span" color="subdued">
        {t('smart-edd.transit-time-rules.no-transit-time-selected')}
      </Text>
    </div>
  );

  const getCountryNames = (selected = []) => {
    return selected
      .map((sel) => {
        return (
          (countriesList.find(({ code_iso3 }) => sel === code_iso3) || {})
            .label || ''
        );
      })
      .filter((c) => !!c)
      .join(', ');
  };

  return (
    <Card roundedAbove="sm">
      <VerticalStack gap={'3'}>
        <HorizontalStack gap={'5'} align="space-between">
          <Text variant="headingMd" as="h2">
            {t('smart-edd.transit-time-rules.card2.title')}
          </Text>
          <Button plain onClick={onAdd}>
            {t('common.add')}
          </Button>
        </HorizontalStack>
        <VerticalStack gap="3">
          <Text variant="bodyMd" as="span" color="subdued">
            {t('smart-edd.transit-time-rules.card2.description')}
          </Text>
          {orderedTimes.length ? (
            <Card roundedAbove="sm">
              {isLoading ? (
                <HorizontalStack align="center">
                  <Spinner size="small" />{' '}
                </HorizontalStack>
              ) : (
                orderedTimes.map((time, index) => {
                  const overriderNum = time.override_order_fulfillment_days
                    ? time.override_order_fulfillment_days_value
                    : orderFulfillmentDays;
                  return (
                    <div key={time.order + index} className="transit-time-item">
                      <Box paddingBlockEnd={'3'}>
                        <HorizontalStack align="space-between">
                          <Box>
                            {time.geo_location_targeted &&
                            time.geo_location_targeted.length ? (
                              getCountryNames(time.geo_location_targeted)
                            ) : (
                              <i>
                                <Text
                                  variant="bodyMd"
                                  as="span"
                                  color="subdued"
                                >
                                  {' '}
                                  {t(
                                    'smart-edd.transit-time-rules.all-locations'
                                  )}
                                </Text>
                              </i>
                            )}
                          </Box>

                          <HorizontalStack align="trailing">
                            <HorizontalStack blockAlign="center">
                              <Text variant="bodyMd" as="span">
                                <b>
                                  {' '}
                                  {time.date_range ? (
                                    <span>
                                      <i>{overriderNum}</i>
                                      {` + ${time.date_range.from}`}
                                      <span>&nbsp; - &nbsp;</span>
                                      <i>{overriderNum}</i>
                                      {` + ${time.date_range.to}`}
                                    </span>
                                  ) : (
                                    <span>
                                      <i>{overriderNum}</i>
                                      {` + ${time.single_day}`}
                                    </span>
                                  )}{' '}
                                </b>
                                <i>
                                  {' '}
                                  <Text
                                    variant="bodyMd"
                                    as="span"
                                    color="subdued"
                                  >
                                    {t(
                                      'smart-edd.transit-time-rules.business_days'
                                    )}
                                  </Text>
                                </i>
                              </Text>
                              <div className="transit-times-btns">
                                <ButtonGroup segmented>
                                  <Button
                                    size="slim"
                                    onClick={() => handleMoveUp(index)}
                                    icon={<Icon source={ArrowUpMinor} />}
                                    disabled={index === 0}
                                  />
                                  <Button
                                    size="slim"
                                    onClick={() => handleMoveDown(index)}
                                    icon={<Icon source={ArrowDownMinor} />}
                                    disabled={
                                      index === orderedTimes?.length - 1
                                    }
                                  />
                                  <Button
                                    size="slim"
                                    onClick={() => onEdit(index)}
                                    icon={<Icon source={EditMinor} />}
                                  />
                                </ButtonGroup>
                              </div>
                            </HorizontalStack>
                          </HorizontalStack>
                        </HorizontalStack>
                      </Box>
                    </div>
                  );
                })
              )}
            </Card>
          ) : (
            <Card roundedAbove="sm">{emptyStatemarkup}</Card>
          )}
        </VerticalStack>
      </VerticalStack>
    </Card>
  );
}

export default TransitTimesCard;
