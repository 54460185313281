import React from 'react';
import { Card, Layout, SkeletonBodyText, Box } from '@shopify/polaris';
import { useTranslation } from 'react-i18next';

function SkeletonSmartEDD() {
  const [t] = useTranslation();
  return (
    <Layout>
      <Layout.AnnotatedSection
        title={t('smart-edd.delivery-date.title')}
        description={t('smart-edd.delivery-date.description')}
      >
        <Card roundedAbove="sm">
          <Box
            padding={'5'}
            borderBlockEndWidth="1"
            borderColor="border-subdued"
          >
            <SkeletonBodyText />
          </Box>
        </Card>
      </Layout.AnnotatedSection>

      <Layout.AnnotatedSection
        title={t('smart-edd.preference.title')}
        description={t('smart-edd.preference.description')}
      >
        <Card roundedAbove="sm">
          <Box
            padding={'5'}
            borderBlockEndWidth="1"
            borderColor="border-subdued"
          >
            <SkeletonBodyText />
            <SkeletonBodyText />
            <SkeletonBodyText lines={4} />
          </Box>
        </Card>
      </Layout.AnnotatedSection>
      <Layout.AnnotatedSection
        title={t('smart-edd.transit-time-rules.title')}
        description={t('smart-edd.transit-time-rules.description')}
      >
        <Card roundedAbove="sm">
          <Box
            padding={'5'}
            borderBlockEndWidth="1"
            borderColor="border-subdued"
          >
            <SkeletonBodyText />
            <SkeletonBodyText />
            <SkeletonBodyText lines={4} />
          </Box>
        </Card>
      </Layout.AnnotatedSection>
      <Layout.AnnotatedSection
        title={t('smart-edd.exclude-products.title')}
        description={t('smart-edd.exclude-products.description')}
      >
        <Card roundedAbove="sm">
          <Box
            padding={'5'}
            borderBlockEndWidth="1"
            borderColor="border-subdued"
          >
            <SkeletonBodyText />
            <SkeletonBodyText />
            <SkeletonBodyText lines={4} />
          </Box>
        </Card>
      </Layout.AnnotatedSection>
      <Layout.AnnotatedSection
        title={t('smart-edd.exclude-geo-region.title')}
        description={t('smart-edd.exclude-geo-region.description')}
      >
        {' '}
        <Card roundedAbove="sm">
          <Box
            padding={'5'}
            borderBlockEndWidth="1"
            borderColor="border-subdued"
          >
            <SkeletonBodyText />
            <SkeletonBodyText />
            <SkeletonBodyText lines={4} />
          </Box>
        </Card>
      </Layout.AnnotatedSection>
    </Layout>
  );
}

export default SkeletonSmartEDD;
