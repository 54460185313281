import React from 'react';
import {
  Card,
  FormLayout,
  Text,
  TextField,
  VerticalStack,
} from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import '../styles.scss';
import TranslateLink from 'components/TranslateLink/TranslateLink';
const SourceCodeCard = ({ page_code, onChange, error }) => {
  const { t } = useTranslation();

  const title = t('tracking_page_new.source_code_card.title');
  const description = t('tracking_page_new.source_code_card.description');

  return (
    <Card roundedAbove="sm">
      <VerticalStack gap="5">
        <VerticalStack gap="2">
          <Text as="h4" variant="headingMd">
            {title}
          </Text>
          <Text as="span" color="subdued">
            <TranslateLink text={description} />
          </Text>
        </VerticalStack>
        <FormLayout>
          <div className={`editor-container ${error ? 'error-case' : ''}`}>
            <TextField
              autoComplete={'off'}
              error={
                error ? t('common.field-required', { field_name: title }) : ''
              }
              label={''}
              value={page_code}
              onChange={(value) => onChange('page_code', value)}
              multiline={10}
            />
          </div>
        </FormLayout>
      </VerticalStack>
    </Card>
  );
};

export default SourceCodeCard;
