import React, { useMemo, useState } from 'react';
import moment from 'moment';
import { Popover, Button, Box } from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import { DATE_RANGE_VALUE_CUSTOM, DATE_FORMAT_STRING } from 'Constants';
import { getOptionLabel } from './helpers';
import DateRangeList from './DateRangeList';

export default function DatePickerButton({
  selectedOption,
  start,
  end,
  onChange = () => {},
}) {
  const [t] = useTranslation();

  const [isActive, setIsActive] = useState(false);

  const activatorText = useMemo(() => {
    if (selectedOption === DATE_RANGE_VALUE_CUSTOM) {
      return `${moment(start).format(DATE_FORMAT_STRING)} - ${moment(
        end
      ).format(DATE_FORMAT_STRING)}`;
    } else {
      return getOptionLabel(selectedOption, t);
    }
  }, [selectedOption, start, end, t]);

  const togglePopoverActive = () => {
    setIsActive(!isActive);
  };
  const activator = (
    <Button onClick={togglePopoverActive} disclosure>
      {activatorText}
    </Button>
  );

  const handleChange = (selectedOption, start, end) => {
    if (selectedOption !== DATE_RANGE_VALUE_CUSTOM) {
      togglePopoverActive();
    }
    onChange(selectedOption, start, end);
  };

  return (
    <Popover
      active={isActive}
      activator={activator}
      autofocusTarget="first-node"
      onClose={togglePopoverActive}
    >
      <Box padding={'5'}>
        <DateRangeList
          selectedOption={selectedOption}
          start={start}
          end={end}
          onChange={handleChange}
        />
      </Box>
    </Popover>
  );
}
