import {
  Checkbox,
  FormLayout,
  Modal,

  Select,
  SkeletonBodyText,
  VerticalStack,
  TextField,
} from '@shopify/polaris';
import CountriesAutoComplete from 'components/CountriesAutoComplete';
import TranslateLink from 'components/TranslateLink';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetShipmentStatusesQuery } from 'redux/store/commonBaseApis';
import RushModal from 'components/custom/RushModal';
function AddEditMessage({ message = {}, onSave, onClose }) {
  const [t] = useTranslation();
  const requiredKeys = useMemo(
    () => ['time_offset_label', 'message', 'shipment_status_uuid'],
    []
  );

  const { data: shipmentStatuses = [], isLoading } =
    useGetShipmentStatusesQuery();
  const [formErrors, setFormErrors] = useState({
    time_offset_label: false,
    message: false,
    shipment_status_uuid: false,
  });
  const [currentMessage, setCurrentMessage] = useState({});

  useEffect(() => {
    setCurrentMessage(message || {});
  }, [message]);

  const handleChange = (key, value) => {
    if (requiredKeys.includes(key)) {
      setFormErrors((err) => {
        let isError = !value;
        if (key === 'time_offset_label') {
          isError =
            value.search(
              /^((\d+M)+\s*)*((\d+w)+\s*)*((\d+d)+\s*)*((\d+h)+\s*)*((\d+m)+\s*)*$/i
            ) < 0;
        }
        return {
          ...err,
          [key]: isError,
        };
      });
    }
    if (key === 'shipment_status_uuid') {
      setCurrentMessage({
        ...currentMessage,
        [key]: value,
        shipment_sub_status_uuid: '',
      });
    } else {
      setCurrentMessage({
        ...currentMessage,
        [key]: value,
      });
    }
  };

  const validateForm = () => {
    const err = {};
    requiredKeys.forEach((rk) => {
      err[rk] = !currentMessage[rk] || formErrors[rk];
    });
    setFormErrors(err);
    return Object.values(err).every((v) => v === false);
  };

  const handleSave = () => {
    if (validateForm()) {
      const toBeSaved = { ...currentMessage };
      if (toBeSaved.is_locations) {
        toBeSaved.location_country_iso3 =
          toBeSaved.location_country_iso3 || null;
        toBeSaved.location_state = toBeSaved.location_state || null;
        toBeSaved.location_city = toBeSaved.location_city || null;
      } else {
        toBeSaved.is_locations = false;
        toBeSaved.location_country_iso3 = null;
        toBeSaved.location_state = null;
        toBeSaved.location_city = null;
      }
      toBeSaved.shipment_sub_status_uuid =
        toBeSaved.shipment_sub_status_uuid || null;
      onSave(toBeSaved);
    }
  };

  const statusOptions = useMemo(() => {
    return shipmentStatuses.map((s) => ({ value: s.uuid, label: s.label }));
  }, [shipmentStatuses]);

  const subStatusOptions = useMemo(() => {
    const selStatus = shipmentStatuses.find(
      (s) => s.uuid === currentMessage.shipment_status_uuid
    );
    return selStatus?.substatuses?.map((s) => ({
      value: s.uuid,
      label: s.label,
    }));
  }, [currentMessage.shipment_status_uuid]);

  return (
    <RushModal
      open={true}
      onClose={onClose}
      title={t('pre-shipment.timeline-message')}
      primaryAction={{ content: t('common.save'), onAction: handleSave }}
      secondaryActions={[
        {
          content: t('common.cancel'),
          onAction: onClose,
        },
      ]}
    >
      <Modal.Section>
        <FormLayout>
          <TextField
            autoComplete="off"
            error={
              formErrors['time_offset_label']
                ? t('pre-shipment.timeline-is-required')
                : ''
            }
            label={t('pre-shipment.show-message-after')}
            placeholder="2d 5h"
            onChange={(value) => handleChange('time_offset_label', value)}
            helpText={t('pre-shipment.show-message-after-help')}
            value={currentMessage.time_offset_label}
          ></TextField>
          <TextField
            autoComplete="off"
            error={
              formErrors['message'] ? t('pre-shipment.message-is-required') : ''
            }
            label={t('pre-shipment.message')}
            placeholder={t('pre-shipment.order-is-processing')}
            onChange={(value) => handleChange('message', value)}
            helpText={t('pre-shipment.message-help')}
            value={currentMessage.message}
            multiline={5}
          ></TextField>
          {isLoading ? (
            <SkeletonBodyText />
          ) : (
            <FormLayout.Group
              helpText={<TranslateLink text={t('pre-shipment.status-help')} />}
            >
              <Select
                label={t('pre-shipment.status-label')}
                error={
                  formErrors['shipment_status_uuid']
                    ? t('pre-shipment.status-required')
                    : ''
                }
                placeholder={t('pre-shipment.status-placeholder')}
                options={statusOptions}
                onChange={(v) => handleChange('shipment_status_uuid', v)}
                value={currentMessage.shipment_status_uuid}
              ></Select>

              <Select
                label={t('pre-shipment.sub-status')}
                placeholder={t('pre-shipment.sub-status-placeholder')}
                options={subStatusOptions}
                value={currentMessage.shipment_sub_status_uuid}
                onChange={(v) => handleChange('shipment_sub_status_uuid', v)}
                disabled={!subStatusOptions?.length}
              ></Select>
            </FormLayout.Group>
          )}
          <Checkbox
            label={t('pre-shipment.include-location')}
            checked={currentMessage.is_locations}
            onChange={(v) => handleChange('is_locations', v)}
          />
          {currentMessage.is_locations ? (
            <VerticalStack gap="1">
              <CountriesAutoComplete
                label={t('common.country')}
                placeholder="United States"
                onChange={([value]) =>
                  handleChange('location_country_iso3', value)
                }
                value={
                  currentMessage.location_country_iso3
                    ? [currentMessage.location_country_iso3]
                    : []
                }
              />
              <TextField
                autoComplete="off"
                label={t('common.state')}
                placeholder="New York"
                onChange={(value) => handleChange('location_state', value)}
                value={currentMessage.location_state}
              ></TextField>
              <TextField
                autoComplete="off"
                label={t('common.city')}
                placeholder="New York"
                onChange={(value) => handleChange('location_city', value)}
                value={currentMessage.location_city}
              ></TextField>
            </VerticalStack>
          ) : (
            ''
          )}
        </FormLayout>
      </Modal.Section>
    </RushModal>
  );
}

export default AddEditMessage;
