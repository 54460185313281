import { Box, Card, Text, Tooltip, VerticalStack } from '@shopify/polaris';
import { isEmpty } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getFlagsOptions } from '../utils';

function Flags({ statistics = {} }) {
  const [t] = useTranslation();
  const flags = getFlagsOptions(t)
    ?.map((flag = {}) => {
      return {
        ...flag,
        isSelected: statistics[flag.value],
      };
    })
    .filter((f) => f.isSelected);
  return isEmpty(flags) ? (
    ''
  ) : (
    <Card>
      <VerticalStack gap={4}>
        <Text variant="headingMd">{t('shipment_details.flags')}</Text>
        <Box>
          {flags.map((f) => {
            if (f.tooltip) {
              return (
                <Tooltip key={f.label} content={f.tooltip}>
                  <Text>
                    <div className={'usage'}>{f.label}</div>
                  </Text>
                </Tooltip>
              );
            } else {
              return <Text key={f.label}>{f.label}</Text>;
            }
          })}
        </Box>
      </VerticalStack>
    </Card>
  );
}

export default Flags;
