import React, { Suspense } from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { createRoot } from 'react-dom/client';

import { BrowserRouter } from 'react-router-dom';
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook';
import { GOOGLE_TAG_ID } from 'config';
import store from './redux/store';
import App from './App';
import '@shopify/polaris/build/esm/styles.css';

const gtmParams = { id: GOOGLE_TAG_ID, dataLayerName: 'customDataLayerName' };

createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <Suspense fallback="">
      <BrowserRouter>
        <GTMProvider state={gtmParams}>
          <ReduxProvider store={store}>
            <App />
          </ReduxProvider>
        </GTMProvider>
      </BrowserRouter>
    </Suspense>
  </React.StrictMode>
);
