import React from 'react';
import { DataTable, Card, Icon, Modal } from '@shopify/polaris';
import { TickMinor, CancelSmallMinor } from '@shopify/polaris-icons';
import RushModal from 'components/custom/RushModal';
import RushBanner from 'components/custom/RushBanner';
export default function VerifyDomainModal(props) {
  const rows = [
    [
      'TXT',
      '20200521195117pm._domainkey',
      'k=rsa;p=MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCje8Mv7mpqxDtFzVN7JA3jZbMIQVB2dtobsy0OYO/d5Ib/zZxxzmso6zUUXKSjxd6U2weLmpcpTvLwy3WS40V2HtID4LHVXCreEP/SH3FGrOMXeLiLenv622q5V0n/zwX7Q6rY1GmTt9XzgObk+6DO',
      <Icon color="greenDark" source={TickMinor} key={'TXT'} />,
    ],
    [
      'CNAME',
      'pm-bounces',
      'pm.mtasv.net',
      <Icon color="redDark" source={CancelSmallMinor} key={'CNAME'} />,
    ],
    // [
    //   'Navy Merino Wool Blazer with khaki chinos and yellow belt',
    //   '$445.00',
    //   124518,
    //   32,
    //   '$14,240.00',
    // ],
  ];
  return (
    <RushModal
      open={props.showVerifyDomainModal}
      onClose={props.handleVerifyDomainModal}
      title="Verify domain ownership"
      primaryAction={{ content: 'Verify' }}
      // secondaryActions={{ content: "Cancel" }}
    >
      <Modal.Section>
        <RushBanner bannerKey={'verify-domain-banner'} title="" status="info">
          <p>
            Verify domain domain.com ownership by creating new CNAMEs records.
            This will increase the like hood shipment notifications to be
            received.
          </p>
        </RushBanner>
        <br />
        <Card roundedAbove="sm">
          <DataTable
            columnContentTypes={['text', 'text', 'text', 'text']}
            headings={['Type', 'Name', 'Value', 'Status']}
            rows={rows}
            totals={[]}
          />
        </Card>
      </Modal.Section>
    </RushModal>
  );
}
