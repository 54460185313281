import React, { useEffect, useState } from 'react';
import {
  TopBar,
  TextField,
  Icon,
  Text,
  HorizontalStack,
} from '@shopify/polaris';
import {
  MobileBackArrowMajor,
  LogOutMinor,
  SearchMinor,
  ProfileMajor,
  StoreMajor,
  TeamMajor,
} from '@shopify/polaris-icons';
import { useTranslation } from 'react-i18next';
import { LanguageSelector, StoresList } from '@rush-app-admin/storybooks';
import { useNavigate, useLocation } from 'react-router-dom';
import { STORE_URLS, USER_URLS } from 'config/urls';
import ShopifyIcon from 'images/shopify-icon.svg';
import RushLogo from 'images/logo-complete.svg';
import { ClientStorage } from 'ClientStorage';
import { LOCAL_STORAGE } from '../../Constants';
import i18next from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { GlobalSearch, shipmentsActions } from 'pages/Shipments3/shipmentSlice';
import {
  useGetUserDetailsQuery,
  useGetOrganizationsQuery,
  useGetUserLanguagesQuery,
  useUpdateUserLocaleMutation,
} from 'redux/store/commonBaseApis';
import { visitorActions } from 'pages/Visitor/visitorSlice';
import { useLogoutMutation } from 'pages/Visitor/visitorAPIs';
import useAdminLink from 'hooks/useAdminLink';
import RushLoading from 'components/custom/RushLoader';
import { baseApi } from 'redux/store/baseApi';
import { VISITOR_URLS } from 'config/urls';
import { installRoutesMeta } from 'Utils/utils';
export default function TopBarMarkup({
  accessToken,
  shop,
  setStoreUUID,
  toggleMobileNav,
}) {
  const [t] = useTranslation();
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const search = useSelector(GlobalSearch);
  const adminLink = useAdminLink();

  const installURLs = (installRoutesMeta() || []).map(
    ({ install_url }) => install_url
  );

  const [logout, { isLoading: isLogoutLoading, isSuccess: isLogoutSuccess }] =
    useLogoutMutation();

  const { isLoading: isUserLoading, data: user = {} } =
    useGetUserDetailsQuery();
  const [updateUserLocale, { isLoading: isUpdatingLanguage }] =
    useUpdateUserLocaleMutation();
  const { data: languages = [], isLoading: isLoadingLanguages } =
    useGetUserLanguagesQuery(undefined, { skip: !accessToken });
  const { data: organizations } = useGetOrganizationsQuery(undefined, {
    skip: !accessToken,
  });

  useEffect(() => {
    if (!isLogoutLoading && isLogoutSuccess) {
      dispatch(visitorActions.setAccessToken(null));
      ClientStorage.remove(LOCAL_STORAGE.ACCESS_TOKEN);
      ClientStorage.remove(LOCAL_STORAGE.LAST_STORE_UUID);
      dispatch(baseApi.util.resetApiState());
      window.FS && window.FS.anonymize();
      navigate(VISITOR_URLS.LOGIN);
    }
  }, [isLogoutSuccess, isLogoutLoading]);

  useEffect(() => {
    setInputValue(search.text);
  }, [search.text]);

  const setSearch = (obj) => {
    dispatch(shipmentsActions.setGlobalSearch(obj));
  };

  useEffect(() => {
    if (!organizations) {
      return;
    }

    let store;
    organizations &&
      organizations.find((org) => {
        store = org.stores.find((store) => store.uuid === shop.storeUUID);
        return !!store;
      });

    if (shop.storeUUID && !store) {
      setStoreUUID(null);
      ClientStorage.set(LOCAL_STORAGE.LAST_STORE_UUID, null);
      navigate('/s');
    }
  }, [organizations]);

  // Context Control
  const handleStoreChange = (uuid) => {
    setStoreUUID(uuid);
    ClientStorage.set(LOCAL_STORAGE.LAST_STORE_UUID, uuid);
    navigate(`${location.pathname?.replace(shop.storeUUID, uuid)}`);
    const specificToStoreRoutes = [
      `${STORE_URLS.SHIPMENTS}/`,
      `${STORE_URLS.NOTIFICATIONS_ACTIVITY_FEED}/`,
      `${STORE_URLS.SHIPMENTS_PRE_SHIPMENT_TIMELINE}/`,
      `${STORE_URLS.TRACKING_PAGES}/`,
      `${STORE_URLS.TRACKING_PAGE}/`,
      `${STORE_URLS.EMAIL_NOTIFICATION_RULE}/`,
      `${STORE_URLS.CARRIER_MASKING}/`,
    ];
    let specRoute = specificToStoreRoutes.find((r) =>
      location.pathname.includes(r)
    );
    if (specRoute === `${STORE_URLS.TRACKING_PAGE}/`) {
      specRoute = `${STORE_URLS.TRACKING_PAGES}/`;
    }
    if (specRoute) {
      navigate(`/s/${uuid}${specRoute}`);
    }
  };

  const navigateToShipments = (value) => {
    setSearch({ text: value });
    const path = location.pathname.split('/');
    const isUserOnShipmentsPage =
      path.length === 4 && '/' + path[3] === STORE_URLS.SHIPMENTS;

    if (!isUserOnShipmentsPage) {
      navigate(`/s/${shop.storeUUID}${STORE_URLS.SHIPMENTS}?page=1`);
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    if (inputValue === '') {
      return;
    }
    navigateToShipments(inputValue);
  };

  let storeName = '';
  if (accessToken) {
    storeName = shop.shopTitle;
  }

  const searchFieldMarkup =
    location.pathname.split('/').length > 3 &&
    !installURLs.includes(location.pathname) ? (
      <form onSubmit={handleSearch}>
        <div style={{ maxWidth: '40rem' }}>
          <TextField
            label={''}
            autoComplete={'off'}
            prefix={<Icon source={SearchMinor} />}
            placeholder={t('shipments.search.input.placeholder')}
            value={inputValue}
            onChange={setInputValue}
            onClearButtonClick={() => {
              setInputValue('');
              navigateToShipments('');
            }}
            clearButton
          />
        </div>
      </form>
    ) : undefined;

  const handleTopLinkClick = (url) => {
    if (window.event?.ctrlKey || window.event?.metaKey) {
      window.open(url, '_blank');
    } else {
      navigate(url);
    }
  };

  const getUserMenuMarkup = () => {
    let store;
    organizations &&
      organizations.find((org) => {
        store = org.stores.find((store) => store.uuid === shop.storeUUID);
        return !!store;
      });

    const primaryActions = [
      {
        content: t('user_menu.item.manage_account'),
        icon: ProfileMajor,
        onAction: () => {
          handleTopLinkClick(USER_URLS.DETAILS);
        },
      },
      {
        content: t('navigation.item.members_and_permissions'),
        icon: TeamMajor,
        onAction: () => {
          handleTopLinkClick(USER_URLS.MEMBERS_AND_PERMISSIONS);
        },
      },
      {
        content: t('user_menu.item.stores'),
        icon: StoreMajor,
        onAction: () => {
          handleTopLinkClick('/s');
        },
      },
      {
        content: t('user_menu.item.logout'),
        icon: LogOutMinor,
        onAction: () => {
          logout(i18next.language);
        },
      },
    ];

    adminLink &&
      primaryActions.push({
        content: t('user_menu.item.back_to_shopify'),
        icon: MobileBackArrowMajor,
        onAction: () => {
          const url = `${adminLink}?utm_source=app.rush.app&utm_medium=app.rush.app`;
          if (window.event?.ctrlKey || window.event?.metaKey) {
            window.open(url, '_blank');
          } else {
            window.open(url, '_self');
          }
        },
      });

    return (
      <TopBar.UserMenu
        name={user.first_name}
        detail={storeName}
        initials={(user.first_name || '')[0]}
        open={isUserMenuOpen}
        onToggle={() => {
          if (!(window.event?.ctrlKey || window.event?.metaKey))
            setIsUserMenuOpen(!isUserMenuOpen);
        }}
        actions={[
          {
            items: primaryActions,
          },
          {
            items: [
              {
                content: t('user_menu.item.rush_help_center'),
                onAction: () =>
                  window.open(
                    'https://support.rush.app/?utm_source=app.rush.app&utm_medium=app.rush.app',
                    '_blank'
                  ),
              },
              {
                content: t('user_menu.item.community_group'),
                onAction: () =>
                  window.open(
                    'https://www.facebook.com/groups/tryrush?utm_source=app.rush.app&utm_medium=app.rush.app',
                    '_blank'
                  ),
              },
              {
                content: t('user_menu.item.hire_expert'),
                onAction: () =>
                  window.open(
                    'https://a.rush.app/heycarson-hire-an-expert',
                    '_blank'
                  ),
              },
            ],
          },
        ]}
      />
    );
  };

  const getContextControlMarkup = () => {
    const storesMarkup = [];
    organizations &&
      organizations.forEach((org) => {
        org.stores.forEach((store) => {
          storesMarkup.push({
            id: store.id?.toString(),
            uuid: store.uuid,
            name: store.store_name,
            domain: store.website,
            org: org.name,
          });
        });
      });
    storesMarkup.sort((a, b) =>
      a.name > b.name ? 1 : b.name > a.name ? -1 : 0
    );

    return (
      <div
        style={{
          display: 'flex',
          height: '100%',
          alignItems: 'center',
          marginLeft: '1rem',
        }}
      >
        {[
          '/s',
          '/s/',
          '/u/details',
          '/u/add-store',
          USER_URLS.MEMBERS_AND_PERMISSIONS,
          ...installURLs,
        ].includes(location.pathname) ? (
          <img src={RushLogo} alt={'logo'} width={'70'} />
        ) : isUserLoading || !storesMarkup.length ? (
          <HorizontalStack
            gap={'1'}
            blockAlign={'center'}
            key={location.pathname}
          >
            <img src={ShopifyIcon} alt={'logo'} width={'30'} />
            <Text variant="bodyMd" as="span">
              <Text variant="headingMd" as="h2">
                {t('app.loading')}
              </Text>
            </Text>
          </HorizontalStack>
        ) : (
          <StoresList
            stores={storesMarkup}
            selectedUuid={shop.storeUUID}
            onSelectStore={handleStoreChange}
            placeholder={t('shipments.settings_modal.location_default_text')}
            icon={ShopifyIcon}
          />
        )}
      </div>
    );
  };

  const getSecondaryMenuMarkup = () => {
    const lang =
      (languages || []).find(
        ({ language_iso_639 }) => language_iso_639 === i18next.language
      ) || languages.find(({ language_iso_639 }) => language_iso_639 === 'en');

    return lang ? (
      <LanguageSelector
        languages={
          languages?.map((lang) => ({
            uuid: lang.uuid,
            label: lang.label_local,
            flagUrl: lang.svg_flag_url,
          })) || []
        }
        selectedUuid={lang?.uuid}
        onChange={(uuid) => {
          const newLang = languages.find((lang) => lang.uuid === uuid);
          ClientStorage.set(LOCAL_STORAGE.LANGUAGE, newLang?.language_iso_639);
          i18next.changeLanguage(newLang?.language_iso_639);
          updateUserLocale({ language: newLang?.language_iso_639 });
        }}
      />
    ) : null;
  };

  return (
    <>
      <TopBar
        showNavigationToggle
        contextControl={getContextControlMarkup()}
        searchField={searchFieldMarkup}
        userMenu={getUserMenuMarkup()}
        secondaryMenu={getSecondaryMenuMarkup()}
        onNavigationToggle={toggleMobileNav}
      />
      {isLoadingLanguages || isUpdatingLanguage || isLogoutLoading ? (
        <RushLoading />
      ) : (
        ''
      )}
    </>
  );
}
