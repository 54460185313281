import React, { useMemo } from 'react';
import {
  Box,
  SkeletonBodyText,
  Text,
  VerticalStack,
  Link,
} from '@shopify/polaris';
import { DB_DATE_FORMAT_STRING } from 'Constants';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useGetCarrierUsageQuery } from 'pages/Dashboard/DashboardApi';
import { STORE_URLS } from 'config/urls';
import InfoTable from '../InfoTable';
import { useParams } from 'react-router-dom';

export default function CarrierUsage({ selectedDates = {} }) {
  const [t] = useTranslation();
  const params = useParams();

  const from = useMemo(() => {
    return moment(selectedDates.start).format(DB_DATE_FORMAT_STRING);
  }, [selectedDates.start]);
  const to = useMemo(() => {
    return moment(selectedDates.end).format(DB_DATE_FORMAT_STRING);
  }, [selectedDates.end]);

  const shipmentCarriersResponse = useGetCarrierUsageQuery(
    { from, to },
    { skip: !(from || to) }
  );
  const {
    data: shipmentCarriers = [],
    isFetching,
    isSuccess,
  } = shipmentCarriersResponse || {};

  const shipmentCarriersData = useMemo(() => {
    return shipmentCarriers.map((carrier) => {
      return [
        carrier.title,
        <Link
          key={`/s/${params.uuid}${STORE_URLS.SHIPMENTS}?${carrier.shipment_get_query}`}
          url={`/s/${params.uuid}${STORE_URLS.SHIPMENTS}?${carrier.shipment_get_query}
        `}
        >
          {carrier.total}
        </Link>,
        <Text
          key={`/s/${params.uuid}${STORE_URLS.SHIPMENTS}?${carrier.shipment_get_query}`}
          variant="bodyMd"
          as="span"
          color="subdued"
        >
          {' '}
          {carrier.percentage}
        </Text>,
      ];
    });
  }, [shipmentCarriers]);

  return (
    <Box padding={'5'}>
      <VerticalStack gap="3">
        <Text variant="headingSm" as="span">
          {t('dashboard.analytics_sheet.shipments_per_carrier')}
        </Text>
        {isFetching || !isSuccess ? (
          <VerticalStack gap="0">
            <SkeletonBodyText />
          </VerticalStack>
        ) : (
          <InfoTable data={shipmentCarriersData} />
        )}
      </VerticalStack>
      {shipmentCarriers && !isFetching && shipmentCarriers.length === 0 && (
        <div className="CustomEmptyState">
          <Text variant="bodyMd" as="span" color="subdued">
            {t('dashboard.analytics_sheet.no_row_message')}
          </Text>
        </div>
      )}
    </Box>
  );
}
